var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalBase',{class:'setting-modal',attrs:{"title":_vm.$t('settings'),"open":_vm.open},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"section",class:!_vm._isThemeGame ? '' : 'game-header'},[_c('div',{staticClass:"section-header"},[_vm._v(" "+_vm._s(_vm.$t('slippageTolerance'))+" "),_c('span',{staticClass:"tooltipped tooltipped-n",class:_vm._isThemeGame ? 'game-tooltip' : '',attrs:{"aria-label":_vm.$t('revertWarning')}},[(!_vm._isThemeGame)?_c('Icon',{staticClass:"help-icon",attrs:{"name":"help","size":"14"}}):_c('img',{attrs:{"src":require("@/assets/icon/game/help.png")}})],1)]),_c('div',{staticClass:"section-body"},[_c('div',{staticClass:"slippage-options"},[_vm._l((_vm.slippageOptions),function(option){return _c('div',{key:option,staticClass:"slippage-option",class:[
              { selected: (_vm.slippage === option) & !_vm.isCustomSlippage },
              _vm._isThemeGame ? 'game-border-slippage-option' : ''
            ],on:{"click":function($event){return _vm.setSlippage(option)}}},[_vm._v(" "+_vm._s(_vm.formatSlippage(option))+" ")])}),_c('div',{staticClass:"input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.slippageInput),expression:"slippageInput"}],staticClass:"cards__input-number slippage-option",class:[
                { selected: _vm.isCustomSlippage },
                !_vm._isThemeGame
                  ? ''
                  : 'game-placeholder-setting game-btn-input-setting'
              ],attrs:{"placeholder":"0.5","type":"number"},domProps:{"value":(_vm.slippageInput)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.slippageInput=$event.target.value},_vm.handleSlippageInput]}}),_c('span',{class:!_vm._isThemeGame ? 'unit' : 'game-unit'},[_vm._v("%")])])],2)]),_c('div',{staticClass:"d-flex flex-items-center p-2 warning-box message slippage-warning",class:_vm._isThemeGame && 'game-border-message-warning'},[_c('Icon',{staticClass:"mr-2 icon-format icon-warning",attrs:{"name":"info","size":"22"}}),_c('div',{staticStyle:{"margin-top":"2px"}},[_vm._v(" "+_vm._s(("" + (_vm.$t('slippageWarning', { min: 0.1, max: 50 }))))+" ")])],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }