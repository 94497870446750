<template>
  <Page :loading="loading">
    <div class="py-3" style="display: flex; flex-direction: column">
      <div class="flex-auto header" style="border-bottom: none">
        <PoolHeader class="" :pool="pairHeader" :for-kurve="true" />
        <PoolBoxes :pool="pairBoxesData" :for-kurve="true" />
        <div class="d-block text-center text-md-left d-md-flex mb-3 mb-md-0">
          <div class="pb-3  action">
            <UiButton
              class="ml-2"
              :class="_isThemeGame ? 'game-border-btn-add' : 'content-button'"
              @click="openAddLiquidity"
            >
              <div :class="_isThemeGame ? 'd-flex' : ''">
                <Icon v-if="!_isThemeGame" name="add" />
                <img
                  v-else
                  src="~/@/assets/icon/game/plus.png"
                  style="width: 12px"
                />
              </div>
              <div class="text-button">{{ $t('add') }}</div>
            </UiButton>
            <UiButton
              class="ml-2"
              :class="_isThemeGame ? 'game-border-btn-add' : 'content-button'"
              @click="openRemoveLiquidity"
            >
              <div :class="_isThemeGame ? 'd-flex' : ''">
                <Icon v-if="!_isThemeGame" name="remove" />
                <img
                  v-else
                  src="~/@/assets/icon/game/minus.png"
                  style="width: 12px"
                />
              </div>
              <div class="text-button">{{ $t('remove') }}</div>
            </UiButton>
          </div>
        </div>
      </div>
      <KurvePoolSwap :pair-token-metadata="pairTokenMetadata" />
    </div>

    <Chart :pool="pairChart" :for-kurve="true" class="chart" />
    <div class="router-table">
      <Tabs :pool="pairTabs" :for-kurve="true" />
      <router-view
        :key="$route.path"
        :pool-id="pairId"
        :pool-tokens="pairTokens"
        :pool-about="pairAbout"
        @reload="loadPair"
      />
    </div>
    <portal to="modal">
      <ModalAddLiquidityKurve
        :open="modalAddLiquidityOpen"
        @close="handleAddLiquidityModalClose"
        :pool="pool"
        :tokenAProps="this.tokens().tokenA"
        :tokenBProps="this.tokens().tokenB"
        :default-pool-share="this.myPoolShareInfo().percentage"
      />
      <ModalRemoveLiquidityKurve
        :open="modalRemoveLisquidityOpen"
        @close="handleRemoveLiquidityModalClose"
        :pool="pool"
        :poolPair="pairHeader"
        :tokenAProps="this.tokens().tokenA"
        :tokenBProps="this.tokens().tokenB"
        :default-pool-share="this.myPoolShareInfo().percentage"
      />
    </portal>
  </Page>
</template>

<script>
import BigNumber from 'bignumber.js';
import { formattedNum, formattedPercent } from '@/utils';
import { mapActions } from 'vuex';
import {
  getKurvePoolBalanceByAddress,
  // getKurvePoolTotalSupply,
  getPairPrice
} from '@/helpers/kurve';

const DEFAULT_MY_LIQUIDITY_PERCENT = 0;

export default {
  name: 'PoolDetailKurve',
  data() {
    return {
      loading: true,
      provider: null,
      pairId: this.$route.params.pairId,
      pairShortenAddress: this.$route.params.shortAddress,
      pair: null,
      pairTokenMetadata: null,
      pairChart: [],
      userPoolBalance: null,
      // transformedUserLiquidityPositions: {},
      modalAddLiquidityOpen: !!this.$route.query.addLiquidity,
      modalRemoveLisquidityOpen: false,
      pairPrice: null,
    };
  },
  computed: {
    pool() {
      return {
        id: this.pairShortenAddress,
        tokens: this.pairTokens.tokens,
        price: {}
      };
    },
    account() {
      const { connector, address } = this.$store.state.account;
      if (!connector || !connector.id || !address) {
        return '';
      }
      return address;
    },
    liquidity() {
      if (!this.pair) {
        return '-';
      }
      if (this.pair.trackedReserveUSD) {
        return formattedNum(this.pair.trackedReserveUSD, true);
      }
      if (this.pair.reserveUSD) {
        return formattedNum(this.pair.reserveUSD, true);
      }
      return '-';
    },
    liquidityChange() {
      if (!this.pair) {
        return '0%';
      }
      return formattedPercent(this.pair.liquidityChangeUSD);
    },
    usingTracked() {
      return this.pair && this.pair.trackedReserveUSD ? true : false;
    },
    volume() {
      if (!this.pair) {
        return '-';
      }
      if (this.pair.oneDayVolumeUSD || this.pair.oneDayVolumeUSD === 0) {
        return formattedNum(
          this.pair.oneDayVolumeUSD === 0
            ? this.pair.oneDayVolumeUntracked
            : this.pair.oneDayVolumeUSD,
          true
        );
      }
      if (this.pair.oneDayVolumeUSD === 0) {
        return '$0';
      }
      return '-';
    },
    volumeChange() {
      if (!this.pair) {
        return '0%';
      }
      return formattedPercent(
        this.usingUtVolume
          ? this.pair.volumeChangeUSD
          : this.pair.volumeChangeUntracked
      );
    },
    usingUtVolume() {
      return this.pair && this.pair.oneDayVolumeUSD === 0 ? true : false;
    },
    fees() {
      if (!this.pair) {
        return '-';
      }
      if (this.pair.oneDayFeeUSD || this.pair.oneDayFeeUSD === 0) {
        if (this.usingUtVolume) {
          return formattedNum(this.pair.oneDayFeeUntracked, true);
        }
        return formattedNum(this.pair.oneDayFeeUSD, true);
      }
      return '-';
    },
    raito() {
      const percentToken0 =
        this.pair && this.pair
          ? new BigNumber(this.pair.reserve0)
              .dividedBy(this.pair.vReserve0)
              .multipliedBy('100')
              .dividedBy(
                new BigNumber(this.pair.reserve0)
                  .dividedBy(this.pair.vReserve0)
                  .plus(
                    new BigNumber(this.pair.reserve1).dividedBy(
                      this.pair.vReserve1
                    )
                  )
              )
              .decimalPlaces(2)
              .toString()
          : '50';
      const percentToken1 = this.pair
        ? new BigNumber(100)
            .minus(percentToken0)
            .decimalPlaces(2)
            .toString()
        : '50';
      return {
        reserve0: this.pair.reserve0,
        reserve1: this.pair.reserve1,
        percentToken0,
        percentToken1
      };
    },
    pairTokens() {
      if (!this.pair) {
        return [];
      }
      const assets = this.$store.getters['assets/metadata'];
      const tokens = [this.pair.token0, this.pair.token1].map(token => {
        const assetToken = Object.keys(assets).find(
          address => assets[address].symbol === token.symbol
        );
        return {
          address: assets[assetToken].address,
          checksum: assets[assetToken].address,
          symbol: assets[assetToken].symbol,
          denormWeight:
            token.id === this.pair.token0.id
              ? this.raito.reserve0
              : this.raito.reserve1,
          color: token.id === this.pair.token0.id ? '#C39888' : '#A1411D',
          weightPercent:
            token.id === this.pair.token0.id
              ? this.raito.percentToken0
              : this.raito.percentToken1
        };
      });
      return {
        tokens,
        poolTokenPercentage: this.myPoolShareInfo().percentage
      };
    },
    pairBoxesData() {
      if (!this.pair) {
        return null;
      }
      return {
        liquidity: this.liquidity,
        liquidityChange: this.liquidityChange,
        usingTracked: this.usingTracked,
        volume: this.volume,
        volumeChange: this.volumeChange,
        usingUtVolume: this.usingUtVolume,
        fees: this.fees,
        poolTokenPercentage: this.myPoolShareInfo().percentage,
        userPoolBalance: this.myPoolShareInfo().poolBalance
      };
    },
    pairHeader() {
      if (!this.pair) {
        return null;
      }
      let liquidity = 0;
      if (this.pair.trackedReserveUSD) {
        liquidity = this.pair.trackedReserveUSD;
      }
      if (this.pair.reserveUSD) {
        liquidity = this.pair.reserveUSD;
      }
      return {
        shortenAddress: this.pairShortenAddress,
        name: 'Bunicorn Pool Token',
        symbol: 'BPT',
        tokens: this.pairTokens.tokens,
        balance: this.pairPrice,
        liquidity: liquidity,
        totalSupply: this.pair.totalSupply,
        lpPrice: new BigNumber(liquidity).dividedBy(this.pair.totalSupply).toString()
      };
    },
    pairTabs() {
      return {
        txSwapCount: this.pair ? this.pair.txSwapCount : 0
      };
    },
    pairAbout() {
      if (!this.pair) {
        return null;
      }
      return {
        pairAddress: this.pairShortenAddress,
        token0Address: this.pair.token0.id,
        token0Symbol: this.pair.token0.symbol,
        token1Address: this.pair.token1.id,
        token1Symbol: this.pair.token1.symbol,
        totalSupply: new BigNumber(this.pair.totalSupply)
          .decimalPlaces(6)
          .toString(),
        totalSwapVolume: new BigNumber(this.pair.volumeUSD)
          .decimalPlaces(6)
          .toString(),
        totalSwapFee: new BigNumber(this.pair.feeUSD)
          .decimalPlaces(6)
          .toString()
      };
    }
  },
  watch: {
    $route() {
      const pairId = this.$route.params.pairId;
      if (pairId !== this.pairId) {
        this.pairId = pairId;
        this.loadPair();
      }
    },
    'account.address': async function(val, prev) {
      if (val && val.toLowerCase() !== prev) await this.loadPair();
    }
  },
  async mounted() {
    this.provider = await this.$store.getters['account/provider'];
    await this.loadPair();
  },
  methods: {
    ...mapActions([
      'getEthPriceForKurve',
      // 'getKurveUserLiquidityPositionsInPool',
      'getKurvePairChart',
      'getKurveBulkPoolsData'
    ]),
    async loadPair(shouldLoading = true) {
      if (shouldLoading) {
        this.loading = true;
        this.pair = null;
        this.pairChart = [];
        this.pairTokenMetadata = {};
      }
      // this.transformedUserLiquidityPositions = {};

      try {
        const [ethPrice] = await this.getEthPriceForKurve();
        const [
          pairInfo,
          userPoolBalanceInfo,
          // poolTotalSupplyInfo,
          // liquidityPositionSnapshots,
          pairChartInfo
        ] = await Promise.all([
          this.getKurveBulkPoolsData({
            provider: this.provider,
            poolAddress: this.pairShortenAddress,
            ethPrice
          }),
          getKurvePoolBalanceByAddress(
            this.provider,
            this.pairShortenAddress,
            this.account
          ),
          // getKurvePoolTotalSupply(this.provider, this.pairShortenAddress),
          // this.getKurveUserLiquidityPositionsInPool({
          //   account: this.account,
          //   poolId: this.pairShortenAddress
          // }),
          this.getKurvePairChart({
            pairAddress: this.pairId
          })
        ]);

        if (userPoolBalanceInfo) {
          this.userPoolBalance = new BigNumber(
            userPoolBalanceInfo.toString()
          ).dividedBy(new BigNumber(10).pow(18));
        }

        // if (liquidityPositionSnapshots) {
        //   liquidityPositionSnapshots.forEach(position => {
        //     if (
        //       !this.transformedUserLiquidityPositions[position.pool.id] ||
        //       position.timestamp >
        //         this.transformedUserLiquidityPositions[position.pool.id]
        //           .timestamp
        //     ) {
        //       this.transformedUserLiquidityPositions[
        //         position.pool.id
        //       ] = position;
        //     }
        //   });
        // }

        if (pairInfo && pairInfo.length !== 0) {
          const assets = this.$store.getters['assets/metadata'];
          const { tokenA, tokenB } = this.tokens(pairInfo[0]);
          const pairPriceInfo = await getPairPrice(
            tokenA,
            tokenB,
            assets,
            this.provider
          );
          this.pairPrice = new BigNumber(pairPriceInfo || 0).toNumber();

          this.pair = pairInfo[0];

          const token0USD =
            this.pair.token0.derivedETH && ethPrice
              ? formattedNum(
                  parseFloat(this.pair.token0.derivedETH) *
                    parseFloat(ethPrice),
                  true
                )
              : '';
          const token1USD =
            this.pair.token1.derivedETH && ethPrice
              ? formattedNum(
                  parseFloat(this.pair.token1.derivedETH) *
                    parseFloat(ethPrice),
                  true
                )
              : '';

          // rates
          const token0Rate =
            Number(this.pair.reserve0) &&
            Number(this.pair.reserve1) &&
            Number(this.raito.percentToken1) &&
            Number(this.raito.percentToken0)
              ? formattedNum(
                  this.pair.reserve1 /
                    this.raito.percentToken1 /
                    (this.pair.reserve0 / this.raito.percentToken0)
                )
              : '-';
          const token1Rate =
            Number(this.pair.reserve0) &&
            Number(this.pair.reserve1) &&
            Number(this.raito.percentToken1) &&
            Number(this.raito.percentToken0)
              ? formattedNum(
                  this.pair.reserve0 /
                    this.raito.percentToken0 /
                    (this.pair.reserve1 / this.raito.percentToken1)
                )
              : '-';

          this.pair = pairInfo[0];
          this.pairTokenMetadata = {
            id: this.pairShortenAddress,
            tokens: this.pairTokens.tokens,
            price: {
              token0Rate,
              token1Rate,
              token0USD,
              token1USD
            }
          };
        }
        if (pairChartInfo) {
          this.pairChart = pairChartInfo;
        }
        if (shouldLoading) {
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
        this.$router.push({ name: 'liquidityKurve' });
      }
    },
    tokens(pairInfo) {
      if (!pairInfo && !this.pair) {
        return { tokenA: null, tokenB: null };
      }
      const assets = this.$store.getters['assets/metadata'];
      const tokenA = Object.keys(assets).find(
        address =>
          assets[address].symbol === (pairInfo || this.pair).token0.symbol
      );
      const tokenB = Object.keys(assets).find(
        address =>
          assets[address].symbol === (pairInfo || this.pair).token1.symbol
      );

      return { tokenA, tokenB };
    },
    myPoolShareInfo() {
      if (!this.userPoolBalance || this.userPoolBalance.isEqualTo(0)) {
        return {
          percentage: DEFAULT_MY_LIQUIDITY_PERCENT
        };
      }
      if (!this.pair || new BigNumber(this.pair.totalSupply).isEqualTo(0)) {
        return {
          percentage: DEFAULT_MY_LIQUIDITY_PERCENT
        };
      }

      return {
        percentage: new BigNumber(this.userPoolBalance)
          .dividedBy(this.pair.totalSupply)
          .toNumber(),
        poolBalance: this.userPoolBalance
      };
    },
    openAddLiquidity() {
      this.modalAddLiquidityOpen = true;
      // const { tokenA, tokenB } = this.tokens;
      // this.$router.push(
      //   `/liquidity/kurve/add/${this.pairShortenAddress}/${tokenA}/${tokenB}`
      // );
    },
    openRemoveLiquidity() {
      this.modalRemoveLisquidityOpen = true;
      // const { tokenA, tokenB } = this.tokens;
      // this.$router.push(
      //   `/liquidity/kurve/remove/${this.pairShortenAddress}/${tokenA}/${tokenB}`
      // );
    },
    handleAddLiquidityModalClose(reload) {
      if (reload) {
        this.reloadPairInfo();
      }
      this.modalAddLiquidityOpen = false;
    },
    handleRemoveLiquidityModalClose(reload) {
      if (reload) {
        this.reloadPairInfo();
      }
      this.modalRemoveLisquidityOpen = false;
    },

    reloadPairInfo() {
      setTimeout(() => {
        this.loadPair(false);
      }, 5000);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../vars.scss';
.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid var(--border-input);
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
}
.action {
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
    button {
      margin: 10px 0;
    }
  }
  .game-border-btn-add {
    display: flex;
    padding: 8px 15px !important;
    align-items: center;
    color: $text-white;
    background: $color-primary;
    border-radius: 0;
    border: none !important;
    margin-left: 1.5rem !important;
    height: 26px !important;
    .text-button {
      font-family: $font-forward !important;
      font-size: 11px;
    }
    &:hover {
      background: $game-btn-hover-color !important;
    }
    img {
      margin-right: 9px;
    }
  }

  .content-button {
    display: flex;
    padding: 8px 15px !important;
    align-items: center;
    border: 2px solid $color-primary;
    color: $color-primary;
    background: none;
    border-radius: 100px;
    &:hover {
      background: $btn-bg-hover-color !important;
      color: $text-white !important;
      border: 2px solid $btn-bg-hover-color;
    }
    .icon {
      width: 12px;
      height: 12px;
      object-fit: contain;
      margin-right: 9px;
      display: flex;
      align-items: center;
      color: $color-primary;
    }
  }
}
.text-button {
  font-family: $font-bold;
  font-size: 13px;
  line-height: 20px;
}
@media only screen and (max-width: 1200px) {
  .text-md-left {
    width: 100%;
  }
  .action {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    .content-button {
      width: 40vw;
      justify-content: center;
      margin: 20px 8px 0 8px;
    }
  }

  .game-border-btn-add {
    margin-top: 40px !important;
  }
}
</style>
