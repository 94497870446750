<template>
  <Page>
    <Container>
      <template v-if="fetchingData">
        <UiLoadingPage />
      </template>
      <template v-else>
        <Title :title="$t('Trainer Staking Pools - T1')" class="header" />
        <div class="bounty-nft" :class="_isThemeGame ? 'game' : ''">
          <div class="banner">
            <div class="total-nft">
              <div class="nft-wraper">
                <div class="total-buni-reward">
                  <img
                    class="buni-token-icon"
                    src="../../assets/icon/buni-token.svg"
                  />{{ totalRewardBuni }}
                </div>
                <div class="total-usd">
                  ≈ ${{ +totalRewardBuniUsd.toFixed(4) }}
                </div>
              </div>
            </div>
          </div>
          <div class="farm-info">
            <div class="info-block">
              <div class="buni-earned">
                <div class="buni-earned-label">BUNI Earned</div>
                <div class="earned-number">
                  <img
                    class="buni-token-color"
                    src="../../assets/icon/buni-token-color.svg"
                  />
                  <div>
                    <span class="buni-number"
                    >{{ +buniEarned.toFixed(4) }}
                    </span>
                    <span class="usd-number"
                    >≈ ${{ +buniEarnedUsd.toFixed(4) }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="harvest-btn"
                :class="
                  `${_isThemeGame ? 'game-border-btn-add' : ''} ${
                    isDisable ? 'disable' : ''
                  }`
                "
                @click="onHavest()"
              >
                <UiLoading v-if="isLoading" />
                <span v-else> {{ $t('harvest') }}</span>
              </div>
            </div>
            <div class="info-block">
              <div class="buni-earned">
                <div class="buni-earned-label">Total Staked NFT</div>
                <div class="earned-number">
                  <div class="buni-number">{{ totalStakedNFT }}</div>
                </div>
              </div>
            </div>
            <div class="info-block">
              <div class="buni-earned">
                <div class="buni-earned-label">My Staked NFT</div>
                <div class="earned-number">
                  <div class="buni-number">{{ userStakedNFTNumber }}</div>
                </div>
              </div>
            </div>
            <div class="info-block">
              <div class="buni-earned">
                <div class="buni-earned-label">Total Mining Hash Rate</div>
                <div class="earned-number">
                  <div class="buni-number">{{ +totalHashRate.toFixed(4) }}</div>
                </div>
              </div>
            </div>
            <div class="info-block">
              <div class="buni-earned">
                <div class="buni-earned-label">My Mining Hash Rate</div>
                <div class="earned-number">
                  <div class="buni-number">{{ +userHashRate.toFixed(4) }}</div>
                </div>
              </div>
            </div>
            <div class="info-block">
              <div class="buni-earned">
                <template v-if="isZero(rewardRate)">
                  <div class="buni-earned-label">
                    1000 Mining Hash Rate/24H to get (estimate)
                  </div>
                  <div class="earned-number">
                    <img
                      class="buni-token-color"
                      src="../../assets/icon/buni-token-color.svg"
                    />
                    <div>
                      <span class="buni-number">{{ +estimateRewardRatePer24h.toFixed(4) || 0 }} </span>
                      <span class="usd-number">≈ ${{ +estimateRewardRatePer24hUsd.toFixed(4) }}</span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="buni-earned-label">
                    1000 Mining Hash Rate/24H to get
                  </div>
                  <div class="earned-number">
                    <img
                      class="buni-token-color"
                      src="../../assets/icon/buni-token-color.svg"
                    />
                    <div>
                      <span class="buni-number">{{ +rewardRatePer24h.toFixed(4) || 0 }} </span>
                      <span class="usd-number">≈ ${{ +rewardRatePer24hUsd.toFixed(4) }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="nft-blocks">
          <div
            class="nft-block"
            v-for="(nftItem, index) in userStakedNFTInfos"
            :key="'stakedNFT-' + nftItem.tokenId"
          >
            <div class="nft-img">
              <img :src="nftItem.image" />
            </div>
            <div class="nft-name">
              {{ nftItem.name }}
              <template v-if="canUnstake">
                <div
                  class="unstake-btn"
                  :class="
                  `${_isThemeGame ? 'game-border-btn-add' : ''} ${
                    nftItem.isUnstaking ? 'disable' : ''
                  }`
                "
                  @click="onUnstake(nftItem, index)"
                >
                  <UiLoading v-if="nftItem.isUnstaking" />
                  <span v-else> {{ $t('Unstake NFT') }}</span>
                </div>
              </template>
            </div>
            <div class="retangle" />
            <div class="nft-info">
              <div class="label-wrapper">
                <div>Hash Rate</div>
                <div>ID</div>
              </div>
              <div class="number-wrapper">
                <div>{{ +nftItem.hashRate }}</div>
                <div>{{ nftItem.tokenId }}</div>
              </div>
            </div>
          </div>
          <div class="nft-block">
            <div class="nft-img plus" @click="openStakeNFTModal = true">
              <img class="add-nft" src="../../assets/plus.svg" />
            </div>
            <div class="nft-name">Add NFT</div>
            <div class="retangle" />
            <div class="nft-info">
              <div class="label-wrapper">
                <div>Hash Rate</div>
                <div>ID</div>
              </div>
              <div class="number-wrapper">
                <div>--</div>
                <div>--</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Container>
    <portal to="modal">
      <ModalStakeNFT
        :open="openStakeNFTModal"
        :listNFTs="availableListNFTs"
        :farm-contract-addr="config.addresses.trainersV2StakingFarm2"
        :nft-contract-addr="config.addresses.trainerContract"
        @closeStakeNFTModal="openStakeNFTModal = false"
        :myAddress="myAccount"
        @refresh="init"
      />
    </portal>
  </Page>
</template>
<script>
import {
  fetchUserBountyNFTCount,
  fetchListNFTOf,
  getUserStakedNFTNumber,
  fetchUserStakedNFT,
  getTrainerNftInfos,
  getCommonInfo,
  getUserCommonInfo
} from '@/helpers/gameStakingPool';
import ModalStakeNFT from '@/components/Ui/Modal/ModalStakeGameNFT.vue';
import { getBuniPrice } from '@/helpers/price';
import BigNumber from 'bignumber.js';
import BlockchainHelper from '@/helpers/BlockchainHelper';
import config from '@/config';

export default {
  name: 'TrainerStakingPool',
  metaInfo: {
    title: 'Trainer Staking Pool'
  },
  components: {
    ModalStakeNFT
  },
  data() {
    return {
      buniEarned: 0,
      totalHashRate: 0,
      openStakeNFTModal: false,
      listNFTs: [],
      listNFTCount: 0,
      userStakedNFTNumber: 0,
      totalStakedNFT: 0,
      userStakedNFTs: [],
      userStakedNFTInfos: [],
      userHashRate: 0,
      rewardRate: 0,
      buniPriceUsd: 0,
      isLoading: false,
      minimumHashRate: 0,
      intervalFetchData: 0,
      totalRewardBuni: 0,
      fetchingData: false,
      canUnstake: false,
      estimatedRewardRate: 0,
      periodFinish: 0,
    };
  },
  watch: {
    myAccount() {
      this.init();
    }
  },
  computed: {
    availableListNFTs() {
      const currentTime = Math.ceil(new Date().valueOf() / 1000);
      if (this.periodFinish !== 0 && Number(currentTime) > Number(this.periodFinish)) {
        return [];
      }
      return this.listNFTs
        .filter(nft => nft.level >= 11 && !nft.isLockTransfer)
        .sort((nftA, nftB) => nftA.level - nftB.level);
    },
    myAccount() {
      return this.$store.state.account.address;
    },
    rewardRatePer24h() {
      return (this.rewardRate * (24 * 60 * 60) * 1e3) / this.totalHashRate;
    },
    estimateRewardRatePer24h() {
      return (this.estimatedRewardRate * (24 * 60 * 60) * 1e3) / this.totalHashRate;
    },
    isDisable() {
      return this.buniEarned <= 0 || this.isLoading;
    },
    rewardRatePer24hUsd() {
      return this.rewardRatePer24h
        ? new BigNumber(this.rewardRatePer24h)
          .multipliedBy(this.buniPriceUsd)
          .toNumber()
        : 0;
    },
    estimateRewardRatePer24hUsd() {
      return this.estimateRewardRatePer24h
        ? new BigNumber(this.estimateRewardRatePer24h)
          .multipliedBy(this.buniPriceUsd)
          .toNumber()
        : 0;
    },
    buniEarnedUsd() {
      return this.buniEarned
        ? new BigNumber(this.buniEarned)
          .multipliedBy(new BigNumber(this.buniPriceUsd))
          .toNumber()
        : 0;
    },
    totalRewardBuniUsd() {
      return new BigNumber(this.totalRewardBuni)
        .multipliedBy(new BigNumber(this.buniPriceUsd))
        .toNumber();
    }
  },
  methods: {
    isZero(value) {
      return new BigNumber(value || 0).isZero();
    },
    clearUserData() {
      this.userStakedNFTNumber = 0;
      this.userHashRate = 0;
      this.buniEarned = 0;
      this.listNFTCount = 0;
      this.listNFTs = [];
      this.userStakedNFTs = [];
      this.userStakedNFTInfos = [];
      this.userStakedNFTNumber = 0;
    },
    async init() {
      this.buniPriceUsd = await getBuniPrice();
      const commonInfo = await getCommonInfo(
        config.addresses.trainersV2StakingFarm2
      );
      if (commonInfo) {
        this.minimumHashRate = commonInfo.minHashRate;
        this.rewardRate = commonInfo.rewardRate;
        this.totalHashRate = commonInfo.totalHashRate;
        this.totalStakedNFT = commonInfo.totalStakedNFT;
        this.canUnstake = commonInfo.canUnstake;
        this.periodFinish = commonInfo.periodFinish;
        this.estimatedRewardRate = this.totalRewardBuni / (30 * 24 * 3600);
      }

      if (!this.myAccount) {
        this.clearUserData();
        return;
      }
      // get user common info
      const userCommonInfo = await getUserCommonInfo(
        this.myAccount,
        config.addresses.trainersV2StakingFarm2
      );
      if (userCommonInfo) {
        this.userHashRate = userCommonInfo.userHashRate;
        this.buniEarned = userCommonInfo.buniEarned;
      }

      //get nft list to stake
      this.listNFTCount =
        (await fetchUserBountyNFTCount(
          this.myAccount,
          config.addresses.trainerContract
        )) || 0;

      this.listNFTs = await fetchListNFTOf(
        this.myAccount,
        this.listNFTCount,
        config.addresses.trainerContract
      );

      //get user staked NFT info
      this.userStakedNFTs = await fetchUserStakedNFT(
        this.myAccount,
        config.addresses.trainersV2StakingFarm2
      );
      this.userStakedNFTInfos = await getTrainerNftInfos(this.userStakedNFTs);
      this.userStakedNFTNumber = await getUserStakedNFTNumber(
        this.myAccount,
        config.addresses.trainersV2StakingFarm2
      );

      //get common info
      this.intervalFetchData = setInterval(async () => {
        this.buniPriceUsd = await getBuniPrice();
        const commonInfo = await getCommonInfo(
          config.addresses.trainersV2StakingFarm2
        );
        if (commonInfo) {
          this.minimumHashRate = commonInfo.minHashRate;
          this.rewardRate = commonInfo.rewardRate;
          this.totalHashRate = commonInfo.totalHashRate;
          this.totalStakedNFT = commonInfo.totalStakedNFT;
        }
        const userCommonInfo = await getUserCommonInfo(
          this.myAccount,
          config.addresses.trainersV2StakingFarm2
        );
        if (userCommonInfo) {
          this.userHashRate = userCommonInfo.userHashRate;
          this.buniEarned = userCommonInfo.buniEarned;
        }
      }, 30000);
    },
    async onHavest() {
      if (this.isDisable) return;
      const provider = await this.$store.getters['account/provider'];
      this.isLoading = true;
      try {
        await BlockchainHelper.harvestBuniBounty(
          provider,
          config.addresses.trainersV2StakingFarm2
        );
        this.init();
      } catch (e) {
        console.error(
          `Action havest BUNI has error ${e.message}`,
          JSON.stringify(e)
        );
      } finally {
        this.isLoading = false;
      }
    },
    async onUnstake(nftItem, tokenIndex) {
      if (nftItem.isUnstaking) {
        return;
      }
      const provider = await this.$store.getters['account/provider'];
      this.$set(nftItem, 'isUnstaking', true);
      try {
        console.log(config.addresses.trainersV2StakingFarm2,
          tokenIndex)
        await BlockchainHelper.unstakeNFT(
          provider,
          config.addresses.trainersV2StakingFarm2,
          tokenIndex
        );

        this.init();
      } catch (e) {
        console.error(
          `Action unstake NFT has error ${e.message}`,
          JSON.stringify(e)
        );
      } finally {
        this.$set(nftItem, 'isUnstaking', false);
      }
    }
  },
  async mounted() {
    this.fetchingData = true;
    await this.init();
    this.fetchingData = false;
  },
  beforeDestroy() {
    clearInterval(this.intervalFetchData);
  }
};
</script>
<style lang="scss" scoped>
.bounty-nft {
  background-color: var(--panel-background);
  width: 100%;
  padding: 30px;
  border-radius: 6px;
  font-family: Lato-Bold;
  .banner {
    position: relative;
    background-image: url('../../assets/farming-nft-banner.svg');
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    .total-nft {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      color: #fff;
      line-height: 1;
      font-size: 36px;
      padding: 40px;
      .buni-token-icon {
        margin-right: 12px;
      }
      .nft-wraper {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 35px;
        .total-buni-reward {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .total-usd {
          font-size: 24px;
          margin-top: 16px;
        }
      }
    }
  }
  .farm-info {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    margin: 6px -10px;
    .info-block {
      width: 30%;
      display: flex;
      border-radius: 8px;
      border: 1px solid var(--border-color);
      padding: 16px;
      justify-content: space-between;
      align-items: center;
      min-width: 296px;
      margin: 10px;
    }
    .buni-earned {
      display: flex;
      flex-direction: column;
      .buni-earned-label {
        color: var(--color-arrow-down);
        font-size: 16px;
      }
      .earned-number {
        display: flex;
        align-items: center;
        .buni-token-color {
          width: 18px;
          height: 18px;
          object-fit: contain;
          margin-right: 8px;
        }
        .buni-number {
          font-size: 18px;
          color: var(--text-color-liquidity);
          font-weight: 700;
        }
        .usd-number {
          font-size: 14px;
          color: var(--color-arrow-down);
        }
      }
    }
    .harvest-btn {
      background: linear-gradient(270deg, #ff8c06 0%, #f47820 100%);
      border-radius: 100px;
      font-size: 16px;
      color: #fff;
      min-width: 90px;
      height: 32px;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.disable {
        opacity: 0.3;
        cursor: not-allowed;
      }
      &:hover {
        background: $btn-bg-hover-color;
      }
    }
  }
}
.nft-blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 30px -15px 0 -15px;
  .nft-block {
    background-color: var(--panel-background);
    box-shadow: 1px 9px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    margin: 15px;
    .nft-img.plus {
      cursor: not-allowed;
    }
    .nft-img {
      width: 235px;
      height: 235px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f1f3f6;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      img.add-nft {
        width: 64px;
        height: 64px;
        object-fit: contain;
      }
    }
    .add-nft {
      cursor: pointer;
    }
    .nft-name {
      color: var(--text-color-liquidity);
      margin: 16px 0;
      text-align: left;
      width: 100%;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .retangle {
      border-top: 1px dashed rgba(0, 0, 0, 0.08);
    }
    .nft-info {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      .label-wrapper,
      .number-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .label-wrapper {
        color: var(--color-arrow-down);
        font-size: 14px;
        line-height: 17px;
      }
      .number-wrapper {
        color: var(--text-color-liquidity);
        font-size: 18;
        font-weight: 700;
        line-height: 22px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .bounty-nft {
    padding: 10px;
    .banner {
      background-image: linear-gradient(
          270deg,
          #f47820 0%,
          #ff8c06 100%
      ) !important;
      border-radius: 6px;
      .total-nft {
        font-size: 24px !important;
        .nft-wraper {
          line-height: 35px;
          .total-usd {
            font-size: 18px !important;
            margin-top: 4px !important;
          }
        }
      }
    }
    .farm-info {
      .info-block {
        width: 45%;
      }
    }
  }
  .nft-blocks {
    justify-content: space-around;
  }
}
@media only screen and (max-width: 576px) {
  .bounty-nft {
    .farm-info {
      margin: 6px auto;
      .info-block {
        width: 100%;
      }
    }
  }
}
.game-border-btn-add {
  border-radius: 0 !important;
  height: 20px !important;
  font-size: 10px !important;
}
.unstake-btn {
  border-radius: 100px;
  font-size: 16px;
  color: transparent;
  border: 1px solid #f47820;
  color: #f47820;
  min-width: 90px;
  height: 32px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.disable {
    opacity: 0.3;
    cursor: not-allowed;
  }
  &:hover {
    background: $btn-bg-hover-color;
    color: #fff;
  }
}
</style>
