import Vue from 'vue';
// import mixpanel from '@/mixpanel';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import Explore from '@/views/Explore.vue';
import Wallet from '@/views/Wallet.vue';
import Create from '@/views/Create.vue';
import Pool from '@/views/Pool.vue';
import Swap from '@/views/Swap/index.vue';
import PoolTokens from '@/views/Pool/Tokens.vue';
import PoolSwaps from '@/views/Pool/Swaps.vue';
import PoolShares from '@/views/Pool/Shares.vue';
import PoolAbout from '@/views/Pool/About.vue';
import PoolSettings from '@/views/Pool/Settings.vue';
import PoolActions from '@/views/Pool/Actions.vue';
import Live from '@/views/Farms/Live.vue';
import NftFarm from '@/views/Farms/KryptoMonsterFarm.vue';
import TokenPlay from '@/views/PartnerFarms/TokenPlay.vue';
import Vesting from '@/views/Vesting/Vesting.vue';
import BountyNFT from '@/views/BountyNFT/Home.vue';
import TokenListing from '@/views/TokenListing.vue';
import LiquidityKurve from '@/views/Liquidity/LiquidityKurve.vue';
import PoolDetailKurve from '@/views/PoolDetail/PoolDetailKurve.vue';
import PoolDetailTokensKurve from '@/views/PoolDetail/Tabs/Tokens.vue';
import PoolDetailSwapsKurve from '@/views/PoolDetail/Tabs/Swaps.vue';
import PoolDetailAboutKurve from '@/views/PoolDetail/Tabs/About.vue';
import AddLiquidityKurve from '@/views/AddLiquidity/AddLiquidityKurve.vue';
import RemoveLiquidityKurve from '@/views/RemoveLiquidity/RemoveLiquidityKurve.vue';
import PreStakingLive from '@/views/PreStaking/PreStakingLive.vue';
import BountyNFTPool from '@/views/NFTPools/BountyNFT.vue';
import TrainerNFT from '@/views/GameNFTPools/TrainerNFT.vue';
import TrainerNFTPhase2 from '@/views/GameNFTPools/TrainerNFTPhase2.vue';
import BunicornNFT from '@/views/GameNFTPools/BunicornNFT.vue';
import BunicornNFTPhase2 from '@/views/GameNFTPools/BunicornNFTPhase2.vue';
import FarmingNFTPool from '@/views/NFTPools/FarmingNFT.vue';
import FarmingNFTPoolNew from '@/views/NFTPools/FarmingNFTNew.vue';
import ProcessedProposalsVote from '@/views/Vote/ProcessedProposalsVote.vue';
import RelatedToMeVote from '@/views/Vote/RelatedToMeVote.vue';
import Vote from '@/views/Vote/Vote.vue';
import VoteDetail from '@/views/Vote/VoteDetail.vue';

import HeroArenaFarm from '@/views/Farms/HeroArenaFarm.vue';
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: '/', redirect: { name: 'tradeTokens' } },
  { path: '/home', redirect: { name: 'tradeTokens' } },
  // { path: '/home', name: 'home', component: Home },
  { path: '/trade/tokens', name: 'tradeTokens', component: Swap },
  { path: '/trade/stablecoins', name: 'tradeStableCoins', component: Swap },
  { path: '/liquidity/tokens', name: 'explore', component: Explore },
  { path: '/liquidity/tokens/new', name: 'create', component: Create },
  {
    path: '/liquidity/kurve/add/:poolId/:tokenA/:tokenB',
    name: 'addLiquidityKurve',
    component: AddLiquidityKurve
  },
  {
    path: '/liquidity/kurve/remove/:poolId/:tokenA/:tokenB',
    name: 'removeLiquidityKurve',
    component: RemoveLiquidityKurve
  },
  {
    path: '/liquidity/stablecoins',
    name: 'liquidityKurve',
    component: LiquidityKurve
  },
  {
    path: '/liquidity/stablecoins/detail/:pairId/:shortAddress',
    component: PoolDetailKurve,
    children: [
      { path: '', name: 'pool-kurve', component: PoolDetailTokensKurve },
      {
        path: 'swaps',
        name: 'pool-swaps-kurve',
        component: PoolDetailSwapsKurve
      },
      // { path: 'shares', name: 'pool-shares', component: PoolShares },
      {
        path: 'about',
        name: 'pool-about-kurve',
        component: PoolDetailAboutKurve
      }
      // { path: 'settings', name: 'pool-settings', component: PoolSettings },
      // { path: 'actions', name: 'pool-actions', component: PoolActions }
    ]
  },
  { path: '/wallet', name: 'wallet', component: Wallet },
  { path: '/farms', name: 'farms', component: Live },
  {
    path: '/krypto-monster-farms',
    name: 'krypto_monster_farms',
    component: NftFarm
  },
  {
    path: '/hero-arena-farms',
    name: 'hero_arena_farms',
    component: HeroArenaFarm
  },
  { path: '/token-play', name: 'token_play', component: TokenPlay },
  { path: '/pre-staking', name: 'pre-staking', component: PreStakingLive },
  {
    path: '/liquidity/tokens/detail/:id',
    component: Pool,
    children: [
      { path: '', name: 'pool', component: PoolTokens },
      { path: 'swaps', name: 'pool-swaps', component: PoolSwaps },
      { path: 'shares', name: 'pool-shares', component: PoolShares },
      { path: 'about', name: 'pool-about', component: PoolAbout },
      { path: 'settings', name: 'pool-settings', component: PoolSettings },
      { path: 'actions', name: 'pool-actions', component: PoolActions }
    ]
  },
  { path: '/vesting', name: 'vesting', component: Vesting },
  { path: '/bounty-nft', name: 'bounty_nft', component: BountyNFT },
  // { path: '/listing', name: 'listing', component: TokenListing },
  {
    path: '/nft-pools/bounty-nft',
    name: 'bounty_nft-pool',
    component: BountyNFTPool
  },
  {
    path: '/nft-pools/ancient-buni',
    name: 'farming_nft_new_v2',
    component: FarmingNFTPoolNew
  },
  {
    path: '/nft-pools/ancient-buni-old',
    name: 'farming_nft',
    component: FarmingNFTPool
  },
  {
    path: '/nft-pools/ancient-buni-new',
    name: 'farming_nft_new',
    component: FarmingNFTPoolNew
  },
  {
    path: '/game-nft/trainer-staking-pools',
    name: 'game_nft_trainer_farm_old',
    component: TrainerNFT
  },
  {
    path: '/game-nft/bunicorn-staking-pools',
    name: 'game_nft_bunicorn_farm_old',
    component: BunicornNFT
  },
  {
    path: '/game-nft/trainer-staking-pools-t1',
    name: 'game_nft_trainer_farm',
    component: TrainerNFTPhase2
  },
  {
    path: '/game-nft/bunicorn-staking-pools-t1',
    name: 'game_nft_bunicorn_farm',
    component: BunicornNFTPhase2
  },
  { path: '/vote', name: 'vote', component: Vote },
  { path: '/vote/detail/:id', name: 'vote-detail', component: VoteDetail },
  {
    path: '/vote/processed-proposals',
    name: 'processedProposals',
    component: ProcessedProposalsVote
  },
  {
    path: '/vote/related-to-me',
    name: 'relatedToMe',
    component: RelatedToMeVote
  },
  { path: '/*', name: 'error-404', beforeEnter: (to, from, next) => next('/') }
];

const router = new VueRouter({
  routes
});
export default router;
