<template>
  <UiTable class="table-details">
    <UiTableTh>
      <div
        v-text="$t('holder')"
        class="flex-auto text-left"
        style="min-width: 95px"
      />
      <div v-text="$t('balance')" class="column" />
      <div v-text="$t('value')" class="column hide-sm" />
      <div v-text="$t('shares')" class="column" />
    </UiTableTh>
    <div
      v-infinite-scroll="loadMore"
      ref="InfiniteLoading"
      infinite-scroll-distance="10"
      class="overflow-hidden"
    >
      <div v-if="shares.length > 0" class="table-body">
        <UiTableTr v-for="(share, i) in shares" :key="i">
          <div class="text-left flex-auto column-holder">
            <a
              :href="
                `${_etherscanLink(pool.id, 'token')}?a=${share.userAddress.id}`
              "
              target="_blank"
              class="text"
            >
              <Avatar :address="share.userAddress.id" class="mr-2" />
              {{ _shortenAddress(share.userAddress.id) }}
              <Icon
                v-if="!_isThemeGame"
                name="external-link"
                size="16"
                class="ml-1 link"
              />
              <img
                v-else
                src="~/@/assets/icon/game/link.png"
                class="icon-link-game"
              />
            </a>
          </div>
          <div class="column column-balances">
            <UiNum :value="share.balance" class="mr-1" />
            {{ $t('bpt') }}
          </div>
          <span
            v-text="`${_checkValueBalance(bptValue * share.balance)}`"
            class="column hide-sm"
          />
          <span
            v-text="
              `${_checkMyPoolSharePercent(
                ((100 / pool.totalShares) * share.balance) / 1e2
              )}`
            "
            class="column"
          />
        </UiTableTr>
      </div>
      <ListLoading
        v-if="loading"
        :classes="['flex-auto text-left', 'column', 'column hide-sm', 'column']"
        :height="25"
      />
    </div>
    <div
      v-if="shares.length === 0 && !loading"
      class="border-top d-flex flex-items-center p-4 text-white"
      v-text="$t('emptyState')"
    />
  </UiTable>
</template>

<script>
import { mapActions } from 'vuex';
import { ITEMS_PER_PAGE } from '@/helpers/utils';

export default {
  props: ['pool'],
  data() {
    return {
      loading: false,
      page: 0,
      shares: []
    };
  },
  computed: {
    bptValue() {
      return parseInt(this.pool.liquidity) / this.pool.totalShares;
    }
  },
  methods: {
    ...mapActions(['getPoolShares']),
    async loadMore() {
      if (this.shares.length < this.page * ITEMS_PER_PAGE) return;
      this.loading = true;
      this.page++;
      const page = this.page;
      let query = {
        where: {
          poolId: this.pool.id.toLowerCase()
        }
      };
      query = { ...query, page };
      const shares = await this.getPoolShares(query);
      this.shares = this.shares.concat(shares);
      this.loading = false;
    }
  },
  beforeDestroy() {
    this.$root.$off('reloadPoolData');
  },
  mounted() {
    this.$root.$on('reloadPoolData', () => {
      console.log('reloadPoolData');
      this.page = 0;
      this.shares = [];
      this.loadMore();
    });
  }
};
</script>
<style lang="scss" scoped>
.column-holder {
  @media only screen and (max-width: 768px) {
    width: 235px;
  }
}

.column.column-balances {
  @media only screen and (max-width: 768px) {
    width: 100px;
  }
}
</style>
