var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"label-input",class:_vm._isThemeGame ? 'game game-label-input' : ''},[_vm._v(" "+_vm._s(_vm.labelInput)+" ")]),_c('div',{staticClass:"unlock-wrapper",class:_vm._isThemeGame && 'game-unlock-wrap'},[(!_vm.myAccount)?[_c('button',{staticClass:"stake-btn",class:("" + (_vm._isThemeGame ? 'game-border-btn-enable' : '')),on:{"click":_vm.unlock}},[_vm._v(" "+_vm._s(_vm.$t('unlock'))+" ")])]:(_vm.rawStakedBalance && _vm.isApproved)?[_c('div',{staticClass:"onPrensent",class:_vm._isThemeGame ? 'game-btn-input-2' : ''},[_c('div',{staticClass:"earned",class:_vm._isThemeGame ? 'game-label-input' : ''},[_vm._v(" "+_vm._s(_vm._num(_vm.displayBalance))+" ")]),_c('div',{staticClass:"stake-btn-wrapper"},[_c('button',{staticClass:"stake-btn quantity",class:("" + (_vm._isThemeGame ? 'game-border-btn-add mr-3' : '')),attrs:{"disabled":_vm.buttonLoadingStatus.unstake ||
                _vm.isLoadingFarmData ||
                _vm.$store.state.account.wrongNetwork},on:{"click":_vm.onPresentWithdraw}},[(_vm.buttonLoadingStatus.unstake)?_c('UiLoading'):(!_vm._isThemeGame)?_c('Icon',{attrs:{"name":"remove"}}):_c('img',{staticStyle:{"width":"10px"},attrs:{"src":require("@/assets/icon/game/minus.png")}})],1),_c('button',{staticClass:"stake-btn quantity",class:("" + (_vm._isThemeGame ? 'game-border-btn-add' : '')),attrs:{"disabled":_vm.isEnded ||
                _vm.buttonLoadingStatus.stake ||
                _vm.isLoadingFarmData ||
                _vm.$store.state.account.wrongNetwork},on:{"click":_vm.onPresentDeposit}},[(_vm.buttonLoadingStatus.stake)?_c('UiLoading'):(!_vm._isThemeGame)?_c('Icon',{attrs:{"name":"add"}}):_c('img',{staticStyle:{"width":"10px"},attrs:{"src":require("@/assets/icon/game/plus.png")}})],1)])])]:(_vm.isApproved)?[_c('button',{staticClass:"stake-btn",class:("" + (_vm._isThemeGame ? 'game-border-btn-enable' : '')),attrs:{"disabled":_vm.isEnded ||
            _vm.buttonLoadingStatus.stake ||
            _vm.isLoadingFarmData ||
            _vm.$store.state.account.wrongNetwork},on:{"click":_vm.onPresentDeposit}},[(_vm.buttonLoadingStatus.stake)?_c('UiLoading'):_c('span',[_vm._v(" "+_vm._s(_vm.$t('stakeLP'))+" ")])],1)]:[(!_vm.isApproved)?_c('button',{staticClass:"stake-btn",class:("" + (_vm._isThemeGame ? 'game-border-btn-enable' : '')),attrs:{"disabled":_vm.buttonLoadingStatus.enable_farm ||
            _vm.isLoadingFarmData ||
            _vm.$store.state.account.wrongNetwork},on:{"click":_vm.handleClick}},[(_vm.buttonLoadingStatus.enable_farm)?_c('UiLoading'):_c('span',[_vm._v(" "+_vm._s(_vm.$t('enable'))+" ")])],1):_vm._e()]],2),_c('ModalAccount',{attrs:{"open":_vm.modalOpen.account},on:{"close":function($event){_vm.modalOpen.account = false},"login":_vm.handleLogin}}),_c('portal',{attrs:{"to":"modal"}},[_c('ModalStakeToken',{attrs:{"open":_vm.openStakeModal,"is-stacked-buni":false,"label":_vm.farmData.lpSymbol,"actionType":_vm.actionType,"tokenBalance":_vm.farmData.userData && _vm.farmData.userData.tokenBalance,"stakedBalance":_vm.farmData.userData && _vm.farmData.userData.stakedBalance,"farmData":_vm.farmData,"pid":_vm.pid,"liquidityUrl":_vm.poolLink},on:{"closeStakeModal":_vm.closeStakeModal,"loadingStatusUpdated":_vm.onStakingStatusUpdated}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }