<template>
  <div :class="!_isThemeGame ? 'content' : 'content-game'">
    <div class="header">
      <div :class="!_isThemeGame ? 'header-text' : 'header-text-game'">
        {{ $t('trade') }}
      </div>
      <Settings />
    </div>
    <div>
      <swap-pair
        :address-in="assetInAddressInput"
        :amount-in="assetInAmountInput"
        :address-out="assetOutAddressInput"
        :amount-out="assetOutAmountInput"
        :is-exact-in="isExactIn"
        :slippage="slippage"
        :swaps-loading="swapsLoading"
        :validation="validation"
        @change="handleAmountChange"
        @update-address-in="assetInAddressInput = $event"
        @update-amount-in="assetInAmountInput = $event"
        @update-address-out="assetOutAddressInput = $event"
        @update-amount-out="assetOutAmountInput = $event"
        @update-is-exact-in="isExactIn = $event"
        :transactionPending="transactionPending"
      />
      <swap-button
        class="swap-button"
        :class="_isThemeGame ? 'game-button' : ''"
        :address-in="assetInAddressInput"
        :amount-in="assetInAmountInput"
        :address-out="assetOutAddressInput"
        :transaction-pending="transactionPending"
        :validation="validation"
        @unlock="unlock"
        @swap="swap"
      />
    </div>
    <portal to="modal">
      <modal-asset-selector
        :open="isModalOpen"
        :hidden="hiddenAssetSelector"
        @select="handleAssetSelect"
      />
    </portal>
    <routing
      :address-in="assetInAddressInput"
      :amount-in="assetInAmountInput"
      :address-out="assetOutAddressInput"
      :amount-out="assetOutAmountInput"
      :pools="pools"
      :swaps="swaps"
      class="routing"
      type="tokens"
    />
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
import {
  SwapValidation,
  validateNumberInput,
  ValidationError
} from '@/utils/validation';
import config from '@/config';
import Swapper from '@/helpers/swapper';
import Storage from '@/utils/storage';
import { getAddress } from '@ethersproject/address';
import { SOR } from '@/libs/buni_sor';
import provider from '@/helpers/provider';
import { setGoal } from '@/utils/fathom';
import BlockchainHelper from '../../helpers/BlockchainHelper';
import { BNB_KEY, isAddress, scale } from '@/utils/helpers';
import { useIntervalFn } from '@vueuse/core';
import SwapPair from '@/components/swap/Pair.vue';
import Settings from '@/components/swap/Settings.vue';
import SwapButton from '@/components/swap/SwapButton.vue';
import Routing from '@/components/swap/Routing.vue';
import ModalAssetSelector from '@/components/swap/modals/ModalAssetSelector.vue';
import {handleApproveToken} from '../../utils/approve-utils';
import { filter } from 'lodash';
import Vue from 'vue';
import {Contract} from "@ethersproject/contracts";
import abi from "@/helpers/abi";

// eslint-disable-next-line no-undef
const GAS_PRICE = process.env.APP_GAS_PRICE || '100000000000';
const MAX_POOLS = 4;

export default {
  name: 'SwapKalancer',
  components: { SwapPair, ModalAssetSelector, Settings, SwapButton, Routing },
  data() {
    return {
      isExactIn: true,
      assetInAddressInput: '',
      assetInAmountInput: '',
      assetOutAddressInput: '',
      assetOutAmountInput: '',
      slippage: 0,
      transactionPending: false,
      swapsLoading: false,
      swaps: [],
      pools: [],
      sor: SOR | undefined,
      pausePoolIntervalFn: undefined,
      pauseAssetIntervalFn: undefined
    };
  },
  watch: {
    assetInAmountInput() {
      this.assetInAmountInput = this.handleValue(
        this.assetInAmountInput,
        this.assetInAddressInput
      );
    },
    assetOutAmountInput() {
      this.assetOutAmountInput = this.handleValue(
        this.assetOutAmountInput,
        this.assetOutAddressInput
      );
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme.mode;
    },
    isModalOpen() {
      return this.$store.state.ui.modal.asset.isOpen;
    },

    assetModalKey() {
      return this.$store.state.ui.modal.asset.key;
    },

    account() {
      const { connector, address } = this.$store.state.account;
      if (!connector || !connector.id || !address) {
        return '';
      }
      return address;
    },

    validation() {
      // Wrong network
      const { chainId } = this.$store.state.account;

      if (config.chainId != chainId) {
        return SwapValidation.WRONG_NETWORK;
      }
      // Invalid input
      const inputError = validateNumberInput(this.activeInput);
      const outputError = validateNumberInput(this.assetOutAmountInput);
      if (inputError === ValidationError.EMPTY) {
        return SwapValidation.EMPTY_INPUT;
      }
      if (inputError !== ValidationError.NONE) {
        return SwapValidation.INVALID_INPUT;
      }
      // No swaps
      if (
        (this.swapsLoading || this.swaps.length === 0) &&
        !this.isWrapPair(this.assetInAddressInput, this.assetOutAddressInput)
      ) {
        return SwapValidation.NO_SWAPS;
      }
      // No account
      if (!this.account) {
        return SwapValidation.NO_ACCOUNT;
      }
      // Insufficient balance
      const { balances } = this.$store.state.account;
      const metadata = this.$store.getters['assets/metadata'];
      const assetInBalance = balances[this.assetInAddressInput];
      const assetInMetadata = metadata[this.assetInAddressInput];
      if (!assetInMetadata) {
        return SwapValidation.INSUFFICIENT_BALANCE;
      }
      const assetInDecimals = assetInMetadata.decimals;
      const assetInAmountRaw = new BigNumber(this.assetInAmountInput);
      const assetInAmount = scale(assetInAmountRaw, assetInDecimals);
      if (!assetInBalance || assetInAmount.gt(assetInBalance)) {
        return SwapValidation.INSUFFICIENT_BALANCE;
      }
      if (
        outputError === ValidationError.NOT_A_NUMBER ||
        inputError === ValidationError.NOT_POSITIVE ||
        outputError === ValidationError.NOT_POSITIVE
      ) {
        return SwapValidation.INVALID_INPUT;
      }
      return SwapValidation.NONE;
    },

    activeInput() {
      if (this.isExactIn) {
        return this.assetInAmountInput;
      }
      return this.assetOutAmountInput;
    },

    hiddenAssetSelector() {
      const hiddenAssets = [
        this.assetInAddressInput,
        this.assetOutAddressInput
      ];
      const metadata = this.$store.getters['assets/metadata'];
      const focusedAddress = this.isExactIn
        ? this.assetInAddressInput
        : this.assetOutAddressInput;
      const otherAddress = this.isExactIn
        ? this.assetOutAddressInput
        : this.assetInAddressInput;
      const isStableFocusedAddress = metadata[focusedAddress]
        ? metadata[focusedAddress].forKurve
        : false;
      const isStableOtherAddress = metadata[otherAddress]
        ? metadata[otherAddress].forKurve
        : false;

      if (!isStableFocusedAddress && isStableOtherAddress) {
        const stablecoinAddresses = filter(metadata, asset => {
          return asset.forKurve;
        }).map(asset => {
          return asset.address;
        });

        return hiddenAssets.concat(stablecoinAddresses);
      }

      return hiddenAssets;
    }
  },
  async mounted() {
    const { assetIn, assetOut } = this.getInitialPair();
    await this.fetchAssetMetadata(assetIn, assetOut);
    this.assetInAddressInput = assetIn;
    this.assetOutAddressInput = assetOut;
    await this.initSor();

    const { pause, resume, isActive } = useIntervalFn(async () => {
      if (this.sor) {
        console.time('[SOR] fetchPools');
        await this.sor.fetchPools();
        console.timeEnd('[SOR] fetchPools');
        await this.onAmountChange(this.activeInput);
      }
    }, 60 * 1000);

    this.pausePoolIntervalFn = pause;

    const { assetPause, assetResume, assetIsActive } = useIntervalFn(
      async () => {
        const assets = Object.keys(this.$store.getters['assets/metadata']);
        await this.$store.dispatch('account/fetchAssets', assets);
      },
      5 * 60 * 1000
    );

    this.pauseAssetIntervalFn = assetPause;
  },
  methods: {
    handleValue(value, address) {
      value = this._validInputNumber(
        value,
        this._hasLimitedDecimalToken(address) ? 6 : 18
      );
      return value;
    },
    handleAmountChange(amount) {
      this.onAmountChange(amount);
    },
    handleAssetSelect(assetAddress) {
      if (this.assetModalKey === 'input') {
        this.isExactIn = true;
        this.assetInAddressInput = assetAddress;
      }

      if (this.assetModalKey === 'output') {
        this.isExactIn = false;
        this.assetOutAddressInput = assetAddress;
      }

      this.onAmountChange(this.activeInput);
    },
    async unlock() {
      this.transactionPending = true;
      const provider = await this.$store.getters['account/provider'];
      const assetInAddress = this.assetInAddressInput;

      const StakingRewardContract = new Contract(
          config.kalancer.addresses.exchangeProxy,
          abi['ExchangeProxy'],
          provider.getSigner()
      );
      const buniContract = new Contract(
          this.assetInAddressInput,
          abi['ERC20'],
          provider.getSigner()
      );

      const tx = await handleApproveToken(
        provider,
        StakingRewardContract,
        buniContract,
        this.$store.state.account.address,
        1
      );
      setGoal('approve');
      const metadata = this.$store.getters['assets/metadata'];
      const assetSymbol = metadata[assetInAddress].symbol;
      const text = 'transactionTitles.unlock';

      tx.symbol = assetSymbol;

      await this.handleTransaction(tx, text);
      await this.$store.dispatch('account/fetchAssets', [assetInAddress]);
    },
    async swap() {
      try {
        const metadata = this.$store.getters['assets/metadata'];
        this.transactionPending = true;
        const assetInAddress = this.assetInAddressInput;
        const assetOutAddress = this.assetOutAddressInput;
        const assetInDecimals = metadata[assetInAddress].decimals;
        const assetOutDecimals = metadata[assetOutAddress].decimals;
        const assetInAmountNumber = new BigNumber(this.assetInAmountInput);
        const assetInAmount = scale(assetInAmountNumber, assetInDecimals);
        const slippageBufferRate = Storage.getSlippage();
        const provider = await this.$store.getters['account/provider'];
        if (this.isWrapPair(assetInAddress, assetOutAddress)) {
          if (assetInAddress === BNB_KEY) {
            const tx = await BlockchainHelper.wrap(provider, assetInAmount);
            const text = 'transactionTitles.wrap';

            tx.symbol = config.systemCoin.symbol;

            await this.handleTransaction(tx, text);
          } else {
            const tx = await BlockchainHelper.unwrap(provider, assetInAmount);
            const text = 'transactionTitles.unwrap';

            tx.symbol = config.systemCoin.wrap;

            await this.handleTransaction(tx, text);
          }

          this.resetInput();

          return this.$store.dispatch('account/fetchAssets', [
            config.addresses.weth
          ]);
        }
        const assetInSymbol = metadata[assetInAddress].symbol;
        const assetOutSymbol = metadata[assetOutAddress].symbol;
        const text = 'transactionTitles.swap';
        if (this.isExactIn) {
          const assetOutAmountNumber = new BigNumber(this.assetOutAmountInput);
          const assetOutAmount = scale(assetOutAmountNumber, assetOutDecimals);
          const minAmount = assetOutAmount
            .div(1 + slippageBufferRate)
            .integerValue(BigNumber.ROUND_DOWN);
          const tx = await Swapper.swapIn(
            provider,
            this.swaps,
            assetInAddress,
            assetOutAddress,
            assetInAmount,
            minAmount
          );
          // Vue.prototype.$mixpanel.track('swapInToken', {
          //   tx: tx,
          //   params: {
          //     swaps: this.swaps,
          //     assetInAddress: this.assetInAddress,
          //     assetOutAddress: this.assetOutAddress,
          //     assetInAmount: assetInAmount,
          //     minAmount: minAmount,
          //     assetOutAmount,
          //     assetInAddressInput: this.$store.state.account.balances[
          //       this.assetInAddressInput
          //     ],
          //     assetOutAddressInput: this.$store.state.account.balances[
          //       this.assetOutAddressInput
          //     ]
          //   }
          // });

          tx.symbolIn = assetInSymbol;
          tx.symbolOut = assetOutSymbol;
          tx.assetInAmount = assetInAmount;
          tx.assetOutAmount = assetOutAmount;

          await this.handleTransaction(tx, text);
          setGoal('swapIn');
        } else {
          const assetInAmountMax = assetInAmount
            .times(1 + slippageBufferRate)
            .integerValue(BigNumber.ROUND_DOWN);
          const tx = await Swapper.swapOut(
            provider,
            this.swaps,
            assetInAddress,
            assetOutAddress,
            assetInAmountMax
          );

          tx.symbolIn = assetInSymbol;
          tx.symbolOut = assetOutSymbol;
          tx.assetInAmount = assetInAmount;
          tx.assetInAmountMax = assetInAmountMax;

          // Vue.prototype.$mixpanel.track('swapOutToken', {
          //   tx: tx,
          //   params: {
          //     swaps: this.swaps,
          //     assetInAddress,
          //     assetOutAddress,
          //     assetInAmountMax,
          //     assetInAddressInput: this.$store.state.account.balances[
          //       this.assetInAddressInput
          //     ],
          //     assetOutAddressInput: this.$store.state.account.balances[
          //       this.assetOutAddressInput
          //     ]
          //   }
          // });
          await this.handleTransaction(tx, text);
          setGoal('swapOut');
        }
        this.$store.dispatch('account/fetchAssets', [
          assetInAddress,
          assetOutAddress
        ]);
        this.resetInput();

        if (this.sor) {
          this.sor.fetchPools();
          this.onAmountChange(this.activeInput);
        }
      } catch (e) {
        // Vue.prototype.$mixpanel.track('swap', {
        //   message: 'swap error',
        //   exception: e.message
        // });
        this.transactionPending = false;
        console.error(e);
      }
    },
    async initSor() {
      try {
        this.sor = new SOR(provider, new BigNumber(GAS_PRICE), MAX_POOLS);

        const assetInAddress =
          this.assetInAddressInput === BNB_KEY
            ? config.addresses.weth
            : this.assetInAddressInput;
        const assetOutAddress =
          this.assetOutAddressInput === BNB_KEY
            ? config.addresses.weth
            : this.assetOutAddressInput;
        await this.sor.fetchFilteredPairPools(assetInAddress, assetOutAddress);
        console.timeEnd(
          `[SOR] fetchFilteredPairPools: ${assetInAddress}, ${assetOutAddress}`
        );
        await this.onAmountChange(this.activeInput);
        console.time('[SOR] fetchPools');
        await this.sor.fetchPools();
        console.timeEnd('[SOR] fetchPools');
        await this.onAmountChange(this.activeInput);
        this.pools = this.sor.onChainCache.pools;
      } catch (e) {
        // Vue.prototype.$mixpanel.track('initSor', {
        //   message: 'initSor error',
        //   exception: e.message
        // });
        console.error(e);
      }
    },
    async onAmountChange(amount) {
      const metadata = this.$store.getters['assets/metadata'];
      if (validateNumberInput(amount) !== ValidationError.NONE) {
        if (this.isExactIn) {
          this.assetOutAmountInput = '';
        } else {
          this.assetInAmountInput = '';
        }
        this.slippage = 0;
        this.swaps = [];
        return;
      }
      if (
        this.isWrapPair(this.assetInAddressInput, this.assetOutAddressInput)
      ) {
        if (this.isExactIn) {
          this.assetOutAmountInput = amount;
        } else {
          this.assetInAmountInput = amount;
        }
        this.swaps = [];
        return;
      }

      const assetInAddress =
        this.assetInAddressInput === BNB_KEY
          ? config.addresses.weth
          : this.assetInAddressInput;
      const assetOutAddress =
        this.assetOutAddressInput === BNB_KEY
          ? config.addresses.weth
          : this.assetOutAddressInput;

      if (assetInAddress === assetOutAddress) {
        return;
      }
      if (
        !this.sor ||
        !this.sor.hasDataForPair(assetInAddress, assetOutAddress)
      ) {
        this.swapsLoading = true;
        return;
      }

      const assetInDecimals = metadata[assetInAddress].decimals;
      const assetOutDecimals = metadata[assetOutAddress].decimals;

      this.swapsLoading = true;
      if (this.isExactIn) {
        const assetInAmountRaw = new BigNumber(amount);
        const assetInAmount = scale(assetInAmountRaw, assetInDecimals);

        console.time(
          `[SOR] getSwaps ${assetInAddress} ${assetOutAddress} exactIn`
        );
        const [tradeSwaps, tradeAmount, spotPrice] = await this.sor.getSwaps(
          assetInAddress,
          assetOutAddress,
          'swapExactIn',
          assetInAmount
        );
        console.timeEnd(
          `[SOR] getSwaps ${assetInAddress} ${assetOutAddress} exactIn`
        );
        this.swaps = tradeSwaps;
        const assetOutAmountRaw = scale(tradeAmount, -assetOutDecimals);
        const assetOutPrecision = config.precision;
        this.assetOutAmountInput = assetOutAmountRaw.decimalPlaces(
          assetOutPrecision,
          BigNumber.ROUND_DOWN
        );
        if (tradeSwaps.length === 0) {
          this.slippage = 0;
        } else {
          const price = assetInAmount.div(tradeAmount).times('1e18');
          const slippageNumber = price.div(spotPrice).minus(1);
          this.slippage = slippageNumber.isNegative()
            ? 0.00001
            : slippageNumber.toNumber();
        }
      } else {
        const assetOutAmountRaw = new BigNumber(amount);
        const assetOutAmount = scale(assetOutAmountRaw, assetOutDecimals);

        console.time(
          `[SOR] getSwaps ${assetInAddress} ${assetOutAddress} exactOut`
        );
        console.log('ASSET In', assetInAddress);
        console.log('ASSET out', assetOutAddress);

        const [tradeSwaps, tradeAmount, spotPrice] = await this.sor.getSwaps(
          assetInAddress,
          assetOutAddress,
          'swapExactOut',
          assetOutAmount
        );
        console.timeEnd(
          `[SOR] getSwaps ${assetInAddress} ${assetOutAddress} exactOut`
        );
        this.swaps = tradeSwaps;
        const assetInAmountRaw = scale(tradeAmount, -assetInDecimals);
        const assetInPrecision = config.precision;
        this.assetInAmountInput = assetInAmountRaw.decimalPlaces(
          assetInPrecision,
          BigNumber.ROUND_UP
        );

        if (tradeSwaps.length === 0) {
          this.slippage = 0;
        } else {
          const price = tradeAmount.div(assetOutAmount).times('1e18');
          const slippageNumber = price.div(spotPrice).minus(1);
          this.slippage = slippageNumber.isNegative()
            ? 0.00001
            : slippageNumber.toNumber();
        }
      }
      this.swapsLoading = false;
    },
    async handleTransaction(transaction, text) {
      try {
        await this.$store.dispatch('transactions/handleTransaction', {
          transaction,
          titleKey: text,
          titleParams: {
            symbolIn: transaction.symbolIn,
            symbolOut: transaction.symbolOut,
            symbol: transaction.symbol
          }
        });
      } catch (error) {
        console.error(
          `Action swap transaction has error: ${error.toString()}`,
          JSON.stringify(error)
        );
      } finally {
        this.transactionPending = false;
      }
    },
    async fetchAssetMetadata(assetIn, assetOut) {
      const metadata = await this.$store.getters['assets/metadata'];
      const unknownAssets = [];

      if (!metadata[assetIn]) {
        unknownAssets.push(assetIn);
      }
      if (!metadata[assetOut]) {
        unknownAssets.push(assetOut);
      }
      if (unknownAssets.length === 0) {
        return;
      }
      this.$store.dispatch('assets/fetchMetadata', unknownAssets);
      this.$store.dispatch('account/fetchAssets', unknownAssets);
    },
    getInitialPair() {
      const pair = Storage.getPair(config.chainId);
      let assetIn = this.$router.currentRoute.query.assetIn || pair.inputAsset;
      let assetOut =
        this.$router.currentRoute.query.assetOut || pair.outputAsset;
      if (isAddress(assetIn)) {
        assetIn = getAddress(assetIn);
      }
      if (isAddress(assetOut)) {
        assetOut = getAddress(assetOut);
      }

      return {
        assetIn,
        assetOut
      };
    },
    isWrapPair(assetIn, assetOut) {
      if (assetIn === BNB_KEY && assetOut === config.addresses.weth) {
        return true;
      }
      if (assetOut === BNB_KEY && assetIn === config.addresses.weth) {
        return true;
      }
      return false;
    },
    resetInput() {
      this.assetInAmountInput = '';
      this.assetOutAmountInput = '';
    }
  }
};
</script>

<style lang="scss">
.flex .wrapper .content {
  padding: 40px;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .header-text {
    font-size: 32px;
    color: var(--text-color-liquidity);
  }
}

.flex .wrapper .content-game {
  padding: 40px;
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  font-family: $font-forward;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .header-text-game {
    font-size: 20px;
    color: var(--text-color-liquidity);
  }
}

.swap-button {
  margin-top: 30px;
}
.impact-rate.game {
  .info-label {
    line-height: 20px;
  }
  .rate-message {
    .rate-label {
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .flex .wrapper .content {
    padding: 16px 20px;
    .header {
      margin-bottom: 15px !important;
      .header-text {
        font-size: 27px !important;
      }
    }
  }

  .flex .wrapper .content-game {
    padding: 15px 10px !important;
  }

  .swap-button.game {
    font-size: 14px !important;
    height: 20px !important;
    padding: 0 20px !important;
  }
}
</style>
