import { render, staticRenderFns } from "./KurveSwapInput.vue?vue&type=template&id=0c975424&scoped=true&"
import script from "./KurveSwapInput.vue?vue&type=script&lang=js&"
export * from "./KurveSwapInput.vue?vue&type=script&lang=js&"
import style0 from "./KurveSwapInput.vue?vue&type=style&index=0&id=0c975424&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c975424",
  null
  
)

export default component.exports