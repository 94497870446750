<template>
  <div class="row_element row mt-4">
    <div
      :class="_isThemeGame ? 'game game-border-table' : ''"
      v-for="vesting in pendingVestings"
      :key="'vesting-' + vesting.tokenId"
      class="card-wrapper"
    >
      <div class="column_element element-vesting">
        <div class="pt-3 header d-flex">
          <div class="border-logo mr-2">
            <img src="~/@/assets/logo_vesting.png" class="logo" />
          </div>
          <div :class="_isThemeGame ? 'game-vesting-amount' : 'vesting-amount'">
            {{ _num(vesting.amout) }} BUNI (ID: {{ vesting.tokenId }})
          </div>
        </div>
        <div
          class="image-frame"
          @click="openVestingCardModal(vesting.imageUrl)"
        >
          <img :src="vesting.smallImageUrl" class="tokenImage" />
          <div
            v-if="
              !(
                canClaim(vesting) ||
                ($refs[`countdown_${vesting.tokenId}`] &&
                  $refs[`countdown_${vesting.tokenId}`][0].isEnded)
              )
            "
            class="countdown-wrapper"
          >
            <div
              :class="
                ` ${
                  _isThemeGame ? 'game-border-table-countdown' : 'count-down'
                }`
              "
            >
              <Countdown
                :ref="`countdown_${vesting.tokenId}`"
                :key="`countdown_${vesting.tokenId}`"
                :endDate="getVestingCountdown(vesting)"
              />
            </div>
          </div>
        </div>

        <div class="display" :class="_isThemeGame ? 'mt-4' : ''">
          <template v-if="!isApproved">
            <button
              class="button mb-4 button-claim"
              :class="{
                'game-border-btn-add': _isThemeGame,
                disabled: !_isThemeGame && (isApproving || wrongNetwork),
                'game-btn-disabled':
                  _isThemeGame && (isApproving || wrongNetwork)
              }"
              @click="approveAllMyVbuni"
            >
              <UiLoading v-if="isApproving" />
              <span v-else>
                {{ $t('approveAll') }}
              </span>
            </button>
          </template>
          <template
            v-else-if="
              canClaim(vesting) ||
                ($refs[`countdown_${vesting.tokenId}`] &&
                  $refs[`countdown_${vesting.tokenId}`][0].isEnded)
            "
          >
            <div class="claim-button-group">
              <UiButton
                :key="`countdown_${vesting.tokenId}_button`"
                @click="!isClaiming && claimBuni(vesting)"
                class="button-claim"
                :class="{
                  'game-border-btn-add': _isThemeGame,
                  disabled:
                    !_isThemeGame && (vesting.isClaiming || wrongNetwork),
                  'game-btn-disabled':
                    _isThemeGame && (vesting.isClaiming || wrongNetwork)
                }"
              >
                <div class="text-button">{{ $t('claimBuni') }}</div>
              </UiButton>
            </div>
          </template>
          <template v-else>
            <div class="claim-button-group">
              <UiButton
                :class="
                  `button-claim-now ${
                    _isThemeGame ? 'game-border-btn-add' : ''
                  } ${
                    !_isThemeGame && (vesting.isClaiming || wrongNetwork)
                      ? 'disabled'
                      : ''
                  }`
                "
                @click="!isClaiming && onClickOpenWarningStakeModal(vesting)"
              >
                <div class="text-button">{{ $t('claimNow') }}</div>
              </UiButton>
            </div>
          </template>
        </div>
      </div>
    </div>
    <portal to="modal">
      <ModalWarningStake
        :open="openModalWarningStake"
        :vesting="selectedVesting"
        @closeModalWarningStake="closeModalWarningStake"
        @claimNow="claimBuniNow(selectedVesting)"
        :loading="isClaiming"
      />
      <div class="vesting-card" v-if="openVestingCard">
        <div class="content">
          <div class="close-icon" @click="closeVestingCardModal">
            <img src="../../assets/close-vesting-card.svg" />
          </div>
          <div class="card-img">
            <img :src="vestingCardUrl" class="tokenImage" />
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import Countdown from '@/components/Countdown';
import moment from 'moment';
import BlockchainHelper from '@/helpers/BlockchainHelper';
import ModalWarningStake from '@/components/Ui/Modal/ModalWarningStake';
import config from '@/config';

export default {
  props: {
    vestings: {
      type: Array,
      default: () => []
    },
    isApproved: {
      type: Boolean,
      default: false
    },
    isApproving: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModalWarningStake,
    Countdown
  },
  data() {
    return {
      tokenId: null,
      openModalWarningStake: false,
      openVestingCard: false,
      vestingCardUrl: '',
      selectedVesting: null,
      isClaiming: false,
      openModalWarningClaimNFT: false
    };
  },
  computed: {
    pendingVestings() {
      return this.vestings.filter(vesting => !vesting.isClaimed);
    },
    wrongNetwork() {
      return this.$store.state.account.wrongNetwork;
    }
  },
  methods: {
    geUnlockInGameUrl(tokenId) {
      return `${config.unlockInGameUrl}#/inventory/${tokenId}`;
    },
    isGameNFT(poolId) {
      return config.gameNftFarm.includes(Number(poolId));
    },
    closeModalWarningStake() {
      this.openModalWarningStake = false;
      this.selectedVesting = null;
    },
    onClickOpenWarningStakeModal(vesting) {
      this.selectedVesting = vesting;
      this.openModalWarningStake = true;
    },
    closeModalWarningClaimNFT() {
      this.openModalWarningClaimNFT = false;
      this.selectedVesting = null;
    },
    onClickOpenWarningClaimNFT(vesting) {
      this.selectedVesting = vesting;
      this.openModalWarningClaimNFT = true;
    },
    canClaim(vesting) {
      if (!this.isApproved) {
        return false;
      }
      if (!vesting.vestedAt) {
        return false;
      }
      return parseInt(vesting.vestedAt) < moment().unix();
    },
    async claimBuniNow(vesting) {
      if (this.wrongNetwork || !this.isApproved) {
        return;
      }
      this.isClaiming = true;
      this.$set(vesting, 'isClaiming', true);
      const provider = await this.$store.getters['account/provider'];

      try {
        await BlockchainHelper.redeemBuniNow(
          provider,
          config.addresses.masterChef,
          vesting.tokenId
        );
        this.$set(vesting, 'isClaimed', true);
      } catch (e) {
        console.error(
          `claimBuni vesting error`,
          JSON.stringify(vesting),
          JSON.stringify(e)
        );
        this.$set(vesting, 'isClaiming', false);
      } finally {
        this.onClaim(vesting);
      }
      this.isClaiming = false;
      this.closeModalWarningStake();
    },
    async claimBuni(vesting) {
      if (this.wrongNetwork || !this.canClaim(vesting)) {
        return;
      }
      this.isClaiming = true;
      this.$set(vesting, 'isClaiming', true);
      const provider = await this.$store.getters['account/provider'];

      try {
        await BlockchainHelper.redeemBuni(
          provider,
          config.addresses.masterChef,
          vesting.tokenId
        );
        this.$set(vesting, 'isClaimed', true);
      } catch (e) {
        console.error(
          `claimBuni vesting error`,
          JSON.stringify(vesting),
          JSON.stringify(e)
        );
        this.$set(vesting, 'isClaiming', false);
      } finally {
        this.onClaim(vesting);
      }
      this.isClaiming = false;
      this.closeModalWarningStake();
    },
    onClaim(vesting) {
      this.$store.dispatch('account/fetchState');
      this.$store.dispatch('farm/getFarmUserData');
      this.selectedVesting = null;
      this.closeModalWarningStake();
    },
    getVestingCountdown(vesting) {
      if (!vesting.vestedAt) {
        return new Date();
      }
      if (vesting.vestedAt < moment().unix()) {
        return moment().toDate();
      }
      if (vesting.vestedAt < 1000000000000) {
        return moment(vesting.vestedAt * 1000).toDate();
      }
      return moment(vesting.vestedAt).toDate();
    },
    approveAllMyVbuni() {
      if (this.wrongNetwork) {
        return;
      }
      this.$emit('approve-all-buni');
    },
    openVestingCardModal(imageUrl) {
      this.openVestingCard = true;
      this.vestingCardUrl = imageUrl;
    },
    closeVestingCardModal() {
      this.openVestingCard = false;
      this.vestingCardUrl = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  margin: 15px;
  border-radius: 10px;
  box-shadow: 0 0 5px var(--border-table);
  overflow: hidden;
}
.vesting-card {
  background-image: url('../../assets/vesting-modal-bg.svg');
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 10;
  .content {
    position: relative;
    display: flex;
    justify-content: center;
    .close-icon {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 100%;
      width: 45px;
      height: 45px;
      @media only screen and (max-width: 767px) {
        left: 85%;
        top: -50px;
      }
    }
    .card-img {
      width: 450px;
      @media only screen and (max-width: 767px) {
        width: calc(100% - 40px);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.row_element {
  @media only screen and (max-width: 767px) {
    margin-right: 0 !important;
    margin-left: 0 !important;
    display: flex;
    justify-content: center;
  }
}
.countdown-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -13px;
}
.element-vesting {
  height: 100%;
  background: var(--panel-background);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.col-md-3 {
  margin-bottom: 40px;
}

.vesting-amount {
  color: var(--text-color);
  min-width: 85px;
}

.game-vesting-amount {
  color: var(--text-color);
  min-width: 103px;
}

.col-md-3.game {
  margin-bottom: 0 !important;
  @media only screen and (min-width: 769px) {
    padding: 0 !important;
  }
}

.text-percent {
  color: var(--loading-color);
  font-size: 18px;
}
.text-game {
  color: var(--loading-color);
  font-size: 16px;
}

.text-time {
  font-size: 20px;
}
.text-time.vesting {
  color: var(--loading-color);
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  &:hover {
    background: var(--panel-background) !important;
    color: $color-primary !important;
  }
}
.header {
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: var(--vesting-header-text-color);
  font-size: 14px;
  width: calc(100% - 2rem);
}

.logo {
  //padding: 8px 10px;
  width: 100%;
  border-radius: 100%;
}

.display {
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.column {
  width: 33%;
  text-align: center;
}

.column_element {
  padding-bottom: 30px;
  position: relative;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.game-border-button-max {
  font-size: 12px !important;
}
.game-border-table {
  .column_element {
    padding-bottom: 0;

    @media only screen and (max-width: 768px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.compelete {
  border-radius: 50px;
  background: #3990f8;
  color: $text-white;
  padding: 5px 10px;
}

.game-border-button-max {
  background: #3990f8;
  color: $text-white;
  padding: 5px 5px;
  font-size: 14px;
  height: 25px;
}
.count-down {
  box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.35), inset -2px -2px 0px #011838,
    inset 1px 1px 0px rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: #0c2a54;
  padding: 8px 0;
  width: fit-content;
}

.game-border-table {
  background: $text-white;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 20px;
  margin-right: 20px;
}

.game-border-table-countdown {
  background: #f4f4f4;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 20px;
}

.border-logo {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  border: 1px solid $color-primary;
}
.button-claim {
  background-color: $color-primary !important;
  color: #ffffff !important;
  &:hover {
    background: #d35a05 !important;
    color: $text-white;
    border: 2px solid #d35a05 !important;

    @media only screen and (max-width: 767px) {
      background-color: #d35a05 !important;
      border: 2px solid #d35a05;
      color: #ffffff !important;
    }
  }
}
.button-claim,
.button-claim-now {
  color: $color-primary;
  border-color: $color-primary;
  border-width: 2px;
  width: calc(100% - 40px);
  background: none;
  display: flex;
  justify-content: center !important;
  align-items: center;
  font-size: 14px;
  height: 35px;
  margin-bottom: 15px;
  &:hover {
    background: $btn-bg-hover-color;
    color: $text-white;
    border: 2px solid $btn-bg-hover-color;

    @media only screen and (max-width: 767px) {
      background: none !important;
      border: 2px solid $color-primary;
      color: $color-primary !important;
    }
  }
}
.button-claim-now.game-border-btn-add {
  border-radius: 0 !important;
  height: inherit !important;
  color: $color-primary;
  border-color: $color-primary;
  margin-bottom: 35px !important;
  padding: 4px 15px !important;
  .text-button {
    font-family: $font-forward !important;
  }

  &:hover {
    background: $btn-bg-hover-color;
    color: $text-white;
    border: 2px solid $btn-bg-hover-color;

    @media only screen and (max-width: 767px) {
      background: none !important;
      border: 2px solid $color-primary;
      color: $color-primary !important;
    }
  }
}

.button-claim.game-border-btn-add {
  border-radius: 0 !important;
  height: inherit !important;
  background-color: $color-primary;
  color: $text-white;
  margin-bottom: 35px !important;
  padding: 4px 15px !important;
  .text-button {
    font-family: $font-forward !important;
  }

  &:hover {
    background: $game-btn-hover-color;
    @media only screen and (max-width: 767px) {
      background: $color-primary !important;
      color: $text-white !important;
    }
  }
}
.game-btn-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  &:hover {
    background-color: $color-primary !important;
  }
}
a,
a:hover {
  color: unset;
}
.tokenImage {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.image-frame {
  cursor: pointer;
  margin: 15px 20px;
  width: 235px;
  height: 235px;
  position: relative;
  img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.card-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.claim-button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.button-claim-game-item {
  width: calc(100% - 40px);
  // pointer-events: none !important;
}
.game-item-disable {
  margin-top: 10px !important;
}
.claim-buni-tooltip {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
