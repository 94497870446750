<template>
  <Page :requireLogin="true">
    <Container>
      <Title :title="$t('vesting')" class="header" />
      <div class="vesting-nft-des">
        Stake Ancient Buni NFT tokens as Aegirbuni, Rocbuni, Beezlebuni and
        Fornbuni to the
        <a
          class="ancient-pool"
          href="https://bunicorn.exchange/#/nft-pools/ancient-buni"
          target="_blank"
          >Ancient Buni NFT Staking Pools</a
        >
        to get a part of the game revenue as a reward
      </div>
      <div>
        <UiLoadingPage v-if="loading" />
        <div v-else>
          <ConnectVesting v-if="vestingCount === 0" />
          <ListVestings
            v-else
            :vestings="vestings"
            :isApproved="isApproved"
            :isApproving="isApproving"
            @approve-all-buni="approveAllMyVbuni"
          />
        </div>
      </div>
    </Container>
  </Page>
</template>

<script>
import ConnectVesting from '@/views/Vesting/ConnectVesting.vue';
import ListVestings from '@/views/Vesting/ListVestings.vue';
import {
  fetchFarmUserVestingCount,
  fetchFarmUserVesting,
  isApprovedVBuniForAll
} from '@/helpers/farm';
import BlockchainHelper from '@/helpers/BlockchainHelper';
import { useIntervalFn } from '@vueuse/core';

export default {
  name: 'Vesting',
  metaInfo: {
    title: 'VestingNFT'
  },
  components: { ConnectVesting, ListVestings },
  data() {
    return {
      vestingCount: 0,
      vestings: [],
      isApproved: false,
      pauseIntervalFn: undefined,
      loading: false,
      isApproving: false
    };
  },
  watch: {
    '$store.state.account.address': async function() {
      this.loading = true;

      await this.init();

      this.loading = false;
    }
  },
  methods: {
    async fetchFarmUserVestingCount(myAddress) {
      return await fetchFarmUserVestingCount(myAddress);
    },
    async approveAllMyVbuni() {
      if (this.isApproved || this.isApproving) {
        return;
      }

      this.isApproving = true;

      try {
        const provider = await this.$store.getters['account/provider'];

        await BlockchainHelper.setApprovalForAll(provider);
        await this.init();
      } catch (error) {
        console.error('Approving vbuni has error ' + error.message),
          JSON.stringify(error);
      } finally {
        this.isApproving = false;
      }
    },

    async init() {
      const myAddress = this.$store.state.account.address;

      this.vestingCount = parseInt(
        (await this.fetchFarmUserVestingCount(myAddress)) || 0
      );
      this.isApproved = await isApprovedVBuniForAll(myAddress);
      this.vestings = await fetchFarmUserVesting(myAddress, this.vestingCount);

      console.log('VESTING', this.vestings);
    }
  },
  beforeDestroy() {
    if (!this.pauseIntervalFn) {
      return;
    }
    this.pauseIntervalFn();
  },
  async mounted() {
    this.loading = true;

    await this.init();

    const myAddress = this.$store.state.account.address;
    const { pause, resume, isActive } = useIntervalFn(async () => {
      console.log('useIntervalFn fetchFarmUserVestingCount');
      this.vestingCount = parseInt(
        (await this.fetchFarmUserVestingCount(myAddress)) || 0
      );
      this.vestings = await fetchFarmUserVesting(myAddress, this.vestingCount);
    }, 60 * 1000);

    this.pauseIntervalFn = pause;
    this.loading = false;
  }
};
</script>

<style lang="scss" scoped>
.button-claim {
  background: linear-gradient(270deg, #ff8c06 0%, #f47820 100%);
  color: $text-white;
  font-size: 16px;
  margin-top: 20px;
  border: 0 !important;

  &:enabled {
    &:hover {
      background: $color-primary;
    }
  }

  &:disabled {
    opacity: 0.4;
  }
}
.vesting-nft-des {
  color: var(--swap-balance-text-color);
  font-family: $font-weight-regular;
  font-size: 16px;
  line-height: 19px;
  .ancient-pool {
    color: #0056b3;
  }
}
.button-claim.game-border-btn-add {
  border-radius: 0 !important;
  background: $color-primary !important;
  border: 0 !important;
  font-size: 12px !important;
  margin-left: 8px;
  height: 35px !important;

  &:enabled {
    &:hover {
      background: $game-btn-hover-color !important;
    }
  }
}
.text-notification {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: var(--text-color);
}
</style>
