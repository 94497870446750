var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UiModal',{staticClass:"modal-setting-pool",class:_vm._isThemeGame ? 'game' : '',staticStyle:{"max-width":"440px"},attrs:{"open":_vm.open},on:{"close":function($event){return _vm.$emit('close')}}},[_c('UiModalForm',{on:{"submit":_vm.handleSubmit}},[_c('template',{slot:"header"},[_c('h3',{staticClass:"gradual-weight-update-title",domProps:{"textContent":_vm._s(_vm.$t('gradualWeightUpdate'))}})]),_c('div',{staticClass:"mx-3"},[_c('UiTable',{class:!_vm._isThemeGame ? 'custom-table' : 'table-pool-setting mb-5'},[_c('UiTableTh',[_c('div',{staticClass:"flex-auto text-left",domProps:{"textContent":_vm._s(_vm.$t('tokens'))}}),_c('div',{staticClass:"column",domProps:{"textContent":_vm._s(_vm.$t('weights'))}}),_c('div',{staticClass:"column",domProps:{"textContent":_vm._s(_vm.$t('percent'))}})]),_vm._l((_vm.pool.tokens),function(token,i){return _c('UiTableTr',{key:token.checksum},[_c('Token',{staticClass:"mr-2",attrs:{"address":token.checksum,"size":"28"}}),_c('div',{staticClass:"flex-auto text-left"},[_vm._v(" "+_vm._s(_vm._ticker(token.checksum))+" ")]),_c('div',{staticClass:"column-sm text-right tooltipped tooltipped-n",class:_vm._isThemeGame ? 'game-tooltip' : '',attrs:{"aria-label":_vm.currentDenorm(_vm.weights[i])}},[_c('currency-input',{ref:"number",refInFor:true,staticClass:"input text-right",class:((_vm.isWeightInputValid(_vm.weights[i]) ? 'input-text' : 'text-red') + " " + (_vm._isThemeGame
                    ? 'game-btn-input-2 game-placeholder-small no-radius mr-2'
                    : '')),attrs:{"placeholder":"50","type":"text","precision":18},on:{"keyup":function (e) {
                  _vm.handleInputChange(i);
                }},model:{value:(_vm.weights[i]),callback:function ($$v) {_vm.$set(_vm.weights, i, $$v)},expression:"weights[i]"}})],1),_c('div',{staticClass:"column-sm text-right"},[_vm._v(" "+_vm._s(_vm._num( ( parseFloat(_vm.pool.tokens[i].denormWeight) / _vm.oldTotalWeight ).toFixed(4), 'percent' ))+" → "+_vm._s(_vm._num((_vm.weights[i] / _vm.totalWeight).toFixed(4), 'percent'))+" ")])],1)})],2)],1),_c('div',{staticClass:"m-4"},[(this.networkdata.blockNumber > 0)?_c('div',{staticClass:"d-flex flex-items-center mb-2",class:_vm._isThemeGame ? 'mb-4' : ''},[_c('div',{staticClass:"flex-auto text-label time-block",class:_vm._isThemeGame ? 'game' : '',domProps:{"textContent":_vm._s(_vm.$t('startBlock'))}}),_c('div',{staticClass:"column-sm"},[_c('currency-input',{staticClass:"input input-blocknumber text-right ml-2",class:((_vm.isStartBlockValid ? 'input-text' : 'text-red') + " " + (_vm._isThemeGame
                  ? 'game-btn-input-2 game-placeholder-small no-radius ml-4'
                  : '')),attrs:{"placeholder":"0","precision":18},model:{value:(_vm.startBlock),callback:function ($$v) {_vm.startBlock=$$v},expression:"startBlock"}})],1),_c('div',{staticClass:"column-lg text-right text-label ",class:_vm._isThemeGame ? 'game' : '',domProps:{"textContent":_vm._s(_vm.formatBlockNumber(_vm.startBlock))}})]):_vm._e(),_c('div',{staticClass:"d-flex flex-items-center mb-2"},[_c('div',{staticClass:"flex-auto text-label time-block",class:_vm._isThemeGame ? 'game' : '',domProps:{"textContent":_vm._s(_vm.$t('endBlock'))}}),_c('div',{staticClass:"column-sm"},[_c('currency-input',{staticClass:"input input-blocknumber text-right ml-2",class:((_vm.isEndBlockValid ? 'input-text' : 'text-red') + " " + (_vm._isThemeGame
                  ? 'game-btn-input-2 game-placeholder-small no-radius ml-4'
                  : '')),attrs:{"placeholder":"0","precision":18},model:{value:(_vm.endBlock),callback:function ($$v) {_vm.endBlock=$$v},expression:"endBlock"}})],1),_c('div',{staticClass:"column-lg text-right text-label ",class:_vm._isThemeGame ? 'game' : '',domProps:{"textContent":_vm._s(_vm.formatBlockNumber(_vm.endBlock))}})])]),(_vm.validationError)?_c('MessageError',{staticClass:"mt-4",attrs:{"text":_vm.validationError}}):_vm._e(),_c('template',{slot:"footer"},[_c('div',{staticClass:"footer"},[_c('UiButton',{staticClass:"mx-1 btn-setting-confirm btn-setting",class:_vm._isThemeGame ? 'game-border-btn-add' : '',attrs:{"disabled":_vm.loading || !_vm.isValid || this.networkdata.blockNumber == 0,"loading":_vm.loading,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")]),_c('UiButton',{staticClass:"mx-1 btn-setting-cancel btn-setting",class:_vm._isThemeGame ? 'game-border-btn-add' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }