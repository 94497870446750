var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UiTable',{staticClass:"table-pools mt-4"},[_c('UiTableTh',[_c('div',{staticClass:"column-sm hide-sm hide-md hide-lg text-left",staticStyle:{"min-width":"110px!important"},domProps:{"textContent":_vm._s(_vm.$t('poolAddress'))}}),_c('div',{staticClass:"flex-auto text-left ",domProps:{"textContent":_vm._s(_vm.$t('assets'))}}),_c('div',{staticClass:"stablecoin-column",domProps:{"innerHTML":_vm._s(_vm.$t('marketCap'))}}),_c('div',{staticClass:"stablecoin-column hide-sm hide-md",domProps:{"innerHTML":_vm._s(_vm.$t('swapFee24h'))}}),_c('div',{staticClass:"stablecoin-sm-column hide-sm hide-md hide-lg",domProps:{"textContent":_vm._s(_vm.$t('1yfl'))}}),_c('div',{staticClass:"stablecoin-column hide-sm hide-md hide-lg",domProps:{"innerHTML":_vm._s(_vm.$t('myLiquidity'))}}),_c('div',{staticClass:"stablecoin-sm-column hide-sm hide-md hide-lg",domProps:{"textContent":_vm._s(_vm.$t('amp'))}}),_c('div',{staticClass:"stablecoin-column hide-sm hide-md hide-lg",domProps:{"innerHTML":_vm._s(_vm.$t('volume24'))}})]),(_vm.pools.length > 0 && !_vm.loading)?_c('div',_vm._l((_vm.pools),function(pool,i){return _c('liquidity-item',{key:i,attrs:{"pool":pool,"liquidityPosition":_vm.transformedUserLiquidityPositions[pool.id] || {}}})}),1):(!_vm.loading)?_c('UiTableTr',[_c('div',{domProps:{"textContent":_vm._s(_vm.$t('emptyState'))}})]):_vm._e(),(_vm.loading)?_c('ListLoading',{attrs:{"classes":[
      'column-sm text-left hide-sm hide-md hide-lg',
      'flex-auto text-left',
      'stablecoin-column hide-sm hide-md',
      'stablecoin-column hide-sm hide-md',
      'stablecoin-sm-column hide-sm hide-md',
      'stablecoin-column',
      'stablecoin-sm-column hide-sm hide-md hide-lg',
      'stablecoin-column hide-sm hide-md hide-lg'
    ],"height":29}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }