



















export enum LiquidityType {
  MULTI_ASSET,
  SINGLE_ASSET
}

export default {
  props: ['selected', 'onSelect'],
  computed: {
    multiAsset() {
      return LiquidityType.MULTI_ASSET;
    },
    singleAsset() {
      return LiquidityType.SINGLE_ASSET;
    }
  }
};
