<template>
  <div class="d-flex filter-actions">
    <div
      class="total-value-locked"
      :class="_isThemeGame ? 'game-total-value-locked' : ''"
    >
<!--      {{ $t('totalValueLocked') }}-->
<!--      {{ _roundedCurrency($store.state.summaries.totalLiquidity) }}-->
      <template v-if="$store.state.account.address">
        <br>
        {{ $t('totalMyLiquidity') }}:
        {{ _roundedCurrency(totalMyLiquidity) }}
      </template>
    </div>
    <div class="d-flex filter-right" :class="_isThemeGame ? 'game' : ''">
      <div class="d-flex">
        <div
          class="d-flex stacked-switch align-center"
          :class="_isThemeGame ? 'game' : ''"
        >
          <div class="label-filter" :class="_isThemeGame ? 'game mr-4' : ''">
            {{ $t('stakedOnly') }}
          </div>
          <div>
            <b-form-checkbox v-model="stakedOnly" name="check-button" switch />
          </div>
        </div>
        <div class="d-flex align-center sort-farm">
          <div class="label-filter" :class="_isThemeGame ? 'game' : ''">
            {{ $t('sortBy') }}:
          </div>
          <div>
            <PreStakingSelectFarms @select-items="selectItems" />
          </div>
        </div>
      </div>

      <div
        class="search-farms"
        :class="_isThemeGame ? 'game-border-btn-search' : ''"
      >
        <span class="position-absolute icon-search-farms">
          <Icon v-if="!_isThemeGame" name="search-1" />
          <img
            v-else
            class="position-absolute icon-search-farms game"
            src="~@/assets/icon/game/search-icon.png"
            style="width: 25px"
          />
        </span>
        <input
          class="input-search-farms"
          :class="_isThemeGame ? 'game-placeholder query-input' : ''"
          :placeholder="$t('searchFarm')"
          type="text"
          v-model="searchKeyword"
          @input="onInputChange()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PreStakingSelectFarms from '@/components/PreStaking/PreStakingSelectFarms';
import { getBalanceNumber } from "@/helpers/farm";
import BigNumber from "bignumber.js";
export default {
  name: 'FilterActions',
  components: {
    PreStakingSelectFarms
  },
  data() {
    return {
      stakedOnly: false,
      searchKeyword: ''
    };
  },
  watch: {
    stakedOnly() {
      this.$emit('stakedToggle', this.stakedOnly);
    }
  },
  computed: {
    totalMyLiquidity () {
      if (!this.$store.state.prestaking.data) {
        return 0;
      }
      const farmsStaked = this.$store.state.prestaking.data;
      let totalMyLiquidity = new BigNumber(0);
      farmsStaked.map((farm, index) => {
        const myAddress = this.$store.state.account.address;

        if (!this.wrongNetwork && farm.userData && myAddress) {
          const stakedBalance = getBalanceNumber(new BigNumber(farm.userData.stakedBalance));
          const lpPrice = farm.lpPriceUsd;
          const myLiquidity = new BigNumber(stakedBalance).times(lpPrice).toString();
          totalMyLiquidity = new BigNumber(totalMyLiquidity).plus(myLiquidity);
        }
      });

      return totalMyLiquidity.toString();
    }
  },
  methods: {
    selectItems(value) {
      this.$emit('sort', value);
    },
    onInputChange() {
      this.$emit('inputSearch', this.searchKeyword);
    }
  }
};
</script>

<style scoped lang="scss">
.label-filter.game {
  font-size: 10px;
}
.game-border-btn-search {
  .item {
    font-size: 11px !important;
  }
}
.filter-actions {
  align-content: flex-start;
  justify-content: space-between;
  width: 100%;
  input {
    ::placeholder {
      font-size: 14px;
      font-family: $font-bold;
    }
  }
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    .filter-right {
      width: 100%;
      margin-top: 20px;
    }
    .search-farms {
      width: 100%;
      margin-top: 5px;
    }
    .search-farms.game-border-btn-search {
      width: 100% !important;
    }
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    .filter-right {
      flex-direction: column;
      width: 100%;
      margin-top: 5px;
    }
    .sort-farm {
      margin: 10px 0 10px 15px !important;
    }
    .search-farms {
      width: 100%;
      margin-top: 5px;
    }
    .label-filter.game {
      font-size: 9px;
    }
    .filter-right.game {
      margin-top: 20px;
    }
    .search-farms.game-border-btn-search {
      width: 100% !important;
      margin-top: 20px;
    }
  }
}
.search-farms {
  border: 1px solid var(--color-border-select);
  box-sizing: border-box;
  box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.075);
  border-radius: 100px;
  height: 35px;
  padding: 7px 40px 5px 15px;
  position: relative;
  width: 140px;
  background-color: var(--bg-input-search);
  .icon-search-farms {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $color-primary;
  }
  input {
    color: var(--secondary-text-color);
    max-width: 100%;
  }
}
.search-farms.game-border-btn-search {
  border-radius: 0 !important;
  width: 200px;
  padding: 5px 40px 5px 15px;
  height: 30px;
  .icon-search-farms.game {
    right: 0;
  }
}
.label-filter {
  font-size: 13px;
  line-height: 16px;
  color: var(--color-arrow-down);
  margin-right: 10px;
  font-family: $font-weight-regular;
}
.sort-farm {
  margin: 0 15px 0 30px;
}
.total-value-locked {
  font-size: 22px;
  color: var(--total-value);
}
.game-total-value-locked {
  font-size: 14px;
}
</style>
