<template>
  <UiTable class="table-details table">
    <UiTableTh>
      <div
        v-text="'Time'"
        class="flex-auto text-left hide-sm hide-md hide-lg"
      />
      <div v-text="$t('tradeIn')" class="column-xl text-left" />
      <div v-text="$t('tradeOut')" class="column-xl text-left" />
      <div v-text="$t('swapFee')" class="column hide-sm hide-md hide-lg" />
    </UiTableTh>
    <div
      v-infinite-scroll="loadMore"
      infinite-scroll-distance="10"
      class="overflow-hidden table-body"
    >
      <div v-if="swaps.length > 0">
        <UiTableTr v-for="(swap, i) in swaps" :key="i" :swap="swap">
          <div class="flex-auto text-left hide-sm hide-md hide-lg">
            <a
              :href="_etherscanLink(getTxHashFromId(swap.id), 'tx')"
              class="text"
              target="_blank"
            >
              {{ $d(new Date(swap.transaction.timestamp * 1e3), 'long') }}
              <Icon v-if="!_isThemeGame" name="external-link link" />
              <img
                v-else
                src="~/@/assets/icon/game/link.png"
                class="icon-link-game"
              />
            </a>
          </div>
          <div class="column-xl d-flex flex-items-center">
            <Token :address="tradeIn(i).address" class="mr-2" />
            {{ _num(tradeIn(i).amount) }}
            {{ _shorten(tradeIn(i).symbol, 12) }}
          </div>
          <div class="column-xl d-flex flex-items-center">
            <Token :address="tradeOut(i).address" class="mr-2" />
            {{ _num(tradeOut(i).amount) }}
            {{ _shorten(tradeOut(i).symbol, 12) }}
          </div>
          <div
            v-text="_num(parseFloat(swap.feeUSD).toFixed(4), 'usd')"
            class="column hide-sm hide-md hide-lg"
          />
        </UiTableTr>
      </div>
      <ListLoading
        v-if="loading"
        :classes="[
          'flex-auto text-left hide-sm hide-md hide-lg',
          'column-lg text-left',
          'column-lg text-left',
          'column hide-sm hide-md hide-lg'
        ]"
        :height="25"
      />
    </div>
    <div
      v-if="swaps.length === 0 && !loading"
      class="border-top d-flex flex-items-center p-4 text-white"
      v-text="$t('emptyState')"
    />
  </UiTable>
</template>

<script>
import { mapActions } from 'vuex';
import { ITEMS_PER_PAGE } from '@/helpers/utils';
import BigNumber from '@/helpers/bignumber';

export default {
  name: 'PoolDetailSwapsKurve',
  props: ['poolId'],
  data() {
    return {
      loading: false,
      page: 0,
      swaps: []
    };
  },
  methods: {
    ...mapActions(['getKurvePoolSwaps']),
    async loadMore() {
      if (this.swaps.length < this.page * ITEMS_PER_PAGE) return;
      this.loading = true;
      this.page++;
      const page = this.page;
      const transactions = await this.getKurvePoolSwaps({
        pairAddress: this.poolId,
        page
      });
      this.swaps = this.swaps.concat(transactions.swaps);
      this.loading = false;
    },
    getTxHashFromId(id) {
      return id.split('-')[0];
    },
    tradeIn(index) {
      const swap = this.swaps[index];
      if (
        (!swap.amount0In || new BigNumber(swap.amount0In).isEqualTo(0)) &&
        (!swap.amount1Out || new BigNumber(swap.amount1Out).isEqualTo(0))
      ) {
        // revert
        return {
          address: swap.pair.token1.id,
          symbol: swap.pair.token1.symbol,
          amount: swap.amount1In
        };
      }
      return {
        address: swap.pair.token0.id,
        symbol: swap.pair.token0.symbol,
        amount: swap.amount0In
      };
    },
    tradeOut(index) {
      const swap = this.swaps[index];
      if (
        (!swap.amount0In || new BigNumber(swap.amount0In).isEqualTo(0)) &&
        (!swap.amount1Out || new BigNumber(swap.amount1Out).isEqualTo(0))
      ) {
        // revert
        return {
          address: swap.pair.token0.id,
          symbol: swap.pair.token0.symbol,
          amount: swap.amount0Out
        };
      }
      return {
        address: swap.pair.token1.id,
        symbol: swap.pair.token1.symbol,
        amount: swap.amount1Out
      };
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../vars.scss';

.text {
  color: var(--text-color);
}
.link {
  color: $primary-button-text;
  margin-left: 4px;
}
</style>
