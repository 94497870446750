<template>
  <div class="d-flex flex-column mb-2">
    <div class="balance mb-1">
      {{ showBalance ? `Balance ${balance}` : '-' }}
    </div>
    <div class="liquidity-input-wrapper d-flex border pl-3 pr-2 py-3">
      <div class="liquidity-input d-flex">
        <input
          class="w-50 mr-3"
          type="number"
          placeholder="0.0"
          :value="amount"
          :disabled="disable"
          @input="handleChange($event.target.value)"
        />
        <div
          v-if="enableMax"
          class="max-button hand border rounded mr-2 px-3 py-2"
          @click="handleInputMax"
        >
          Max
        </div>
      </div>
      <div class="asset d-flex border rounded mr-2 p-2">
        <div class="icon-wrapper line-20">
          <AssetIcon v-if="address" class="asset-icon" :address="address" />
        </div>
        {{ symbol }}
      </div>
    </div>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
import AssetIcon from '@/components/swap/AssetIcon.vue';
import config from '@/config';
import { scale } from '@/utils/helpers';
export default {
  name: 'LiquidityInput',
  components: { AssetIcon },
  props: {
    type: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    symbol: {
      type: String,
      required: true
    },
    enableMax: {
      type: Boolean,
      default: false
    },
    amount: {
      type: String,
      default: ''
    },
    showBalance: {
      type: Boolean,
      default: true
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    balance() {
      const { balances } = this.$store.state.account;
      const metadata = this.$store.getters['assets/metadata'];
      if (!balances || !metadata) {
        return '0.0';
      }
      const assetMetadata = metadata[this.address];
      const balance = balances[this.address];
      if (!assetMetadata || !balance) {
        return '0.0';
      }

      const balanceNumber = new BigNumber(balance);
      const assetDecimals = assetMetadata.decimals;
      const balanceShortNumber = scale(balanceNumber, -assetDecimals);
      const balanceInNumberLb = parseFloat(
        balanceShortNumber.toFixed(config.precision)
      );
      return balanceInNumberLb;
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('handle-input-change', { value, type: this.type });
    },
    handleInputMax() {
      this.$emit('handle-input-change', {
        value: this.balance,
        type: this.type
      });
      this.$emit('handle-max');
    }
  }
};
</script>

<style lang="scss" scoped>
.liquidity-input-wrapper {
  border-radius: 10px;
  justify-content: space-between;
}
.liquidity-input {
  flex: 1;
  justify-content: space-between;
  input {
    flex: 1;
    font-size: 18px;
  }
}
.hand {
  cursor: pointer;
  text-align: center;
}
.balance {
  text-align: right;
}
.max-button {
  width: 65px;
}
.asset {
  justify-content: center;
  align-items: center;
}
.icon-wrapper {
  margin-right: 5px;
}
.asset-icon {
  width: 18px;
  height: 18px;
}
</style>
