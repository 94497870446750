<template>
  <div class="form-active-vote"
       :class="_isThemeGame ? 'game-border-tabnav-notactive' : ''">
    <div class="form-title"
         :class="_isThemeGame ? 'game' : ''">
      Submit a Proposal
    </div>
    <div class="form-content">
      <div class="mt-3">
        <div class="content-title" :class="_isThemeGame ? 'game' : ''">
          Product
        </div>
        <VoteSelectCategory
          ref="selectCategory"
          :typeSelect="'selectedForm'"
          @select-items="selectItems"
          class="submit-proposal"
        />
      </div>
      <div class="mt-3">
        <div class="content-title" :class="_isThemeGame ? 'game' : ''">
          Tittle
        </div>
        <div :class="`${_isThemeGame ? 'game-border-btn-search' : ''}`">
          <input
            type="text"
            class="asset-input"
            maxlength="300"
            placeholder="Tittle of the proposal"
            v-model="params.title"
            :class="_isThemeGame ? 'game' : ''"
          />
        </div>
      </div>
      <div class="mt-3">
        <div class="content-title" :class="_isThemeGame ? 'game' : ''">
          Details
        </div>
        <div :class="`${_isThemeGame ? 'game-border-btn-search' : ''}`">
          <textarea
            :class="_isThemeGame ? 'game' : ''"
            name="details-vote"
            id="details-vote"
            class="details-vote"
            cols="30"
            maxlength="10000"
            rows="8"
            placeholder="All the details here..."
            v-model="params.details"
          ></textarea>
        </div>
      </div>
    </div>
    <div
      class="mt-4 text-center"
      :class="[
        !isEnableSubmit() && 'tooltipped tooltipped-n',
        _isThemeGame ? 'game-tooltip' : ''
      ]"
      :aria-label="textTooltip()"
    >
      <button
        class="btn-submit"
        :class="[
          !checkSubmit ? 'disable' : '',
          _isThemeGame ? 'game-border-btn-add' : ''
        ]"
        :disabled="!checkSubmit"
        @click="approveVoteVbuni"
      >
        <div class="text-submit"
             :class="_isThemeGame ? 'game' : ''"
             v-if="!isLoading">
          Submit ({{ voteFee }} BUNI)
        </div>
        <UiLoading v-if="isLoading" />
      </button>
    </div>
  </div>
</template>

<script>
import VoteSelectCategory from '@/components/Vote/VoteSelectCategory';
import {createPropose, minBuniHoldToPropose, getVoteFee} from '@/utils/vote-utils';
import { normalizeBalance } from '@/helpers/utils';
import config from '@/config';
import store from '@/store';
import BigNumber from 'bignumber.js';
export default {
  data() {
    return {
      params: {
        categoryId: 'Please select',
        title: '',
        details: ''
      },
      voteFee: 100,
      amountHoldBuni: 5000,
      isLoading: false
    };
  },
  computed: {
    checkSubmit() {
      if (
        this.params.categoryId === 'Please select' ||
        !this.params.title ||
        !this.params.details ||
        this.isLoading ||
        !this.isEnableSubmit()
      ) {
        return false;
      }
      return true;
    },
    isEnoughBuniToPropose() {
      const buniBalance = this.$store.state.account.balances[config.addresses.buni];
      return (
        new BigNumber(buniBalance).comparedTo(this.amountHoldBuni) >= 0
      );
    },
    isEnoughBuniFeeToPropose() {
      const balance = this.$store.state.account.balances[config.addresses.buni];
      const amount = normalizeBalance(balance, 18).toNumber();
      return new BigNumber(amount).comparedTo(this.voteFee) >= 0;
    }
  },
  components: {
    VoteSelectCategory
  },
  methods: {
    selectItems(value) {
      this.$set(this.params, 'categoryId', value);
    },
    async approveVoteVbuni() {
      this.isLoading = true;
      if (!this.account) {
        this.$store.dispatch('openAccountModal');
        this.isLoading = false;
        return;
      }
      try {
        const userProvider = await this.$store.getters['account/provider'];
        await createPropose(
          userProvider,
          this.$store.state.account.address,
          this.params
        );
        this.params = {
          categoryId: 'Please select',
          title: '',
          details: ''
        };
        if (this.$refs.selectCategory) {
          this.$refs.selectCategory.reset();
        }
      } catch (error) {
        if(error.message === 'Proposal pending') {
          store.dispatch('notify', [
            'error',
            {
              text: 'There is 1 Proposal pending',
              type: 'error'
            }
          ]);
        } else {
          this.$store.dispatch('transactions/handleTransactionError', error);
        }
      } finally {
        this.isLoading = false;
      }
    },

    isEnableSubmit() {
      const balance = this.$store.state.account.balances[config.addresses.buni];
      const minBuni = new BigNumber(this.voteFee)
        .plus(this.amountHoldBuni)
        .toString();
      return new BigNumber(minBuni).times(1e18).comparedTo(balance) <= 0;
    },
    textTooltip() {
      const minBuni = new BigNumber(this.voteFee).plus(this.amountHoldBuni).toString();
      return `To create proposal, you need to have ${minBuni} BUNI in your account.`;
    },
  },
  async created() {
    this.amountHoldBuni = await minBuniHoldToPropose();
    this.voteFee = await getVoteFee();
  }
};
</script>

<style lang="scss" scoped>
.form-active-vote {
  width: 370px;
  height: 591px;
  background-color: var(--panel-background);
  border-radius: 10px;
  padding: 24px;
  color: var(--text-color-liquidity);
  margin-left: 30px;
  margin-top: 24px;

  &.game-border-tabnav-notactive {
    height: 550px;
  }
  .form-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-top: 6px;
    margin-bottom: 24px;
    &.game {
      font-size: 1rem;
    }
  }
  .form-content {
    .content-title {
      font-family: Lato-Regular;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 9px;
      &.game {
        font-size: 0.875rem;
      }
    }
    .asset-input {
      font-family: Lato-Regular;
      height: 48px;
      border: 1px solid var(--border-color);
      box-sizing: border-box;
      box-shadow: inset 1px 2px 3px rgb(0 0 0 / 8%);
      border-radius: 5px;
      width: 100%;
      padding: 10px 20px;
      color: var(--text-color-liquidity);
    }
    .details-vote {
      background: var(--panel-background);
      border: 1px solid var(--border-color);
      padding: 10px 20px;
      box-shadow: inset 1px 2px 3px rgb(0 0 0 / 8%);
      width: 100%;
      border-radius: 4px;
      font-family: Lato-Regular;
      color: var(--text-color-liquidity);
    }
  }

  .btn-submit {
    height: 55px;
    background: linear-gradient(270deg, #ff8c06 0%, #f47820 100%);
    border-radius: 100px;
    width: 100%;
    border: none;
    &.game-border-btn-add {
      height: 40px!important;
      width: 97%!important;
    }

    .text-submit {
      font-size: 20px;
      font-weight: 700;
      line-height: 35px;
      color: rgba(255, 255, 255, 1);
    }
  }
  .disable {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

//.submit-proposal::v-deep {
//  .selected-item {
//    div {
//      color: var(--button-color-checkbox);
//    }
//  }
//}
@media only screen and (max-width: 768px) {
  .form-active-vote {
    width: 100%;
    margin-left: 0;
    margin-top: 30px;
  }
}
</style>
