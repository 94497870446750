<template>
  <div
    class="border rounded-1 p-4 action"
    :class="`${_isThemeGame ? 'game-border-table' : ''}`"
  >
    <div
      v-if="bPool.metadata.rights.canChangeWeights"
      class="p-2 rounded-1"
      style="max-width: 750px;"
    >
      <h5 v-text="$t('poke')" class="mb-3 action-title" />
      <p
        v-text="$t('ongoingUpdateLoading')"
        class="mb-3 action-text"
        :class="_isThemeGame ? 'game' : ''"
        v-if="this.networkdata.blockNumber == 0"
      />
      <p
        v-html="$t('pokeWeightsEarly')"
        class="mb-3 action-text"
        v-else-if="tooEarly"
        :class="_isThemeGame ? 'game' : ''"
      />
      <p
        v-html="$t('pokeWeightsOngoing')"
        class="mb-3 action-text"
        v-else-if="activeUpdate"
        :class="_isThemeGame ? 'game' : ''"
      />
      <p
        v-html="$t('pokeWeightsGeneral')"
        class="mb-3 action-text"
        :class="_isThemeGame ? 'game' : ''"
        v-else
      />
      <UiButton
        :loading="loading"
        :disabled="!ongoingUpdate"
        @click="handlePokeWeights()"
        class="action-button"
        :class="_isThemeGame ? 'game-border-btn-add no-radius game mt-4' : ''"
      >
        {{ $t('poke') }}
      </UiButton>
      <MessageError v-if="this.transactionReverted" :text="$t('txReverted')" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { isTxReverted } from '@/helpers/utils';

export default {
  props: ['pool', 'bPool'],
  data() {
    return {
      loading: false,
      transactionReverted: false
    };
  },
  watch: {
    open() {
      this.transactionReverted = false;
    }
  },
  computed: {
    // ongoingUpdate is true if a CRP has started a gradualWeightsUpdate
    ongoingUpdate() {
      return this.bPool.isCrp() && this.bPool.metadata.startBlock !== '0';
    },
    // activeUpdate is true if it has started a gradualWeightsUpdate AND it's not too early to call pokeWeights
    // Note that it's possible for the websocket interface to go down, in which case the blockNumber will be
    //   incorrect. If this happens, we do not want users to be blocked from calling pokeWeights. So only
    //   use activeUpdate for non-critical messaging, and only disable the button if it's a shared pool or
    //   updateGradualWeightsUpdate was never called. (startBlock is obtained through multicall, which should be reliable.)
    activeUpdate() {
      return (
        this.bPool.isCrp() &&
        this.bPool.metadata.startBlock !== '0' &&
        this.networkdata.blockNumber >= this.bPool.metadata.startBlock
      );
    },
    tooEarly() {
      return (
        this.bPool.isCrp() &&
        this.bPool.metadata.startBlock !== '0' &&
        this.networkdata.blockNumber < this.bPool.metadata.startBlock
      );
    }
  },
  methods: {
    ...mapActions(['pokeWeights']),
    async handlePokeWeights() {
      this.loading = true;
      const txResult = await this.pokeWeights({
        poolAddress: this.bPool.metadata.controller
      });
      if (isTxReverted(txResult)) this.transactionReverted = true;
      this.loading = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.action {
  background: transparent;
  color: var(--button-color-checkbox);
}

.game-border-table.action {
  border-radius: 0 !important;
  width: inherit !important;
  .action-title {
    font-size: 13px !important;
  }
  .game {
    font-size: 11px !important;
  }
}

.game-border-btn-add {
  border: 0 !important;
  &:enabled {
    &:hover {
      background-color: $color-primary !important;
      color: $text-white;
    }
  }
}

.action-title {
  color: var(--button-color-checkbox);
  font-size: 13px;
  line-height: 20px;
}

.action-text {
  font-size: 14px;
  line-height: 22px;
  color: var(--text-color);
  font-family: $font-weight-regular;
}

.border {
  background: var(--panel-background);
  box-shadow: 0 0 5px var(--border-table);
  border-radius: 15px !important;
  border: none !important;
}

.action-button {
  border: 2px solid $color-primary;
  background: none !important;
  box-sizing: border-box;
  border-radius: 100px;
  color: $color-primary;
  font-family: $font-bold;
  font-size: 14px;
  text-align: center;
  height: 35px;
  margin-bottom: 20px;
  &:disabled {
    border: 2px solid $color-primary;
    color: $color-primary;
    opacity: 0.4;
  }
  &:hover {
    border: 2px solid $btn-bg-hover-color;
    color: $btn-bg-hover-color;
  }
}

@media only screen and (max-width: 768px) {
  .router-table {
    .border {
      border-radius: 0 !important;
      padding: 10px !important;
    }
  }
}
</style>
