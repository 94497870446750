<template>
  <div class="d-flex flex-column mb-2">
    <div class="info" :class="_isThemeGame ? 'mb-3 game' : ''">
      <div class="type-label">
        {{ type === 'input' ? $t('from') : $t('to') }}
      </div>
      <div class="balance">
        {{
          showBalance && address
            ? `${$t('balance')}: ${balance}`
            : `${$t('balance')}: 0`
        }}
      </div>
    </div>
    <div class="asset-input" :class="_isThemeGame ? 'game-input-swap' : ''">
      <div class="amount-wrapper">
        <div class="amount">
          <div class="input-wrapper">
            <div
              v-if="isCalculating.length > 0 && isCalculating !== type"
              class="loading"
            />
            <currency-input
              class="cards__input-number swap-input"
              v-bind:class="[
                _isThemeGame ? 'game-placeholder game-swap-input' : '',
                isCalculating.length > 0 && isCalculating !== type
                  ? 'hidden'
                  : ''
              ]"
              :precision="getPrecision()"
              type="text"
              placeholder="0.0"
              v-model="amountData"
              :disabled="disable || transactionPending"
              @keyup="handleInputChange"
            />
          </div>
          <div
            v-if="enableMax && this.isAuthenticated"
            class="button max-button"
            v-bind:class="[
              !_isThemeGame ? '' : 'game-border-button-max',
              transactionPending && 'disable'
            ]"
            @click="handleInputMax"
          >
            {{ $t('max') }}
          </div>
        </div>
      </div>
      <div
        class="asset-wrapper"
        @click="handleSelectAsset"
        :class="transactionPending && 'disable'"
      >
        <div
          :class="
            `${!symbol && 'select-token'} ${
              !symbol && _isThemeGame ? 'game-btn-select-token' : ''
            }`
          "
          class="d-flex align-center"
        >
          <div class="asset-meta">
            <div class="icon-wrapper">
              <AssetIcon v-if="address" class="asset-icon" :address="address" />
            </div>
            <span
              v-if="symbol && symbol.length <= 10"
              style="display: flex; align-items: center"
            >
              <span
                class="asset-symbol"
                :class="_isThemeGame ? 'game-asset-symbol-fontsize' : ''"
              >
                {{ symbol }}
              </span>
            </span>
            <span v-else class="text-select-token">{{
              $t('selectAToken')
            }}</span>
          </div>
          <Icon
            v-if="!_isThemeGame"
            :name="'chevron-down'"
            class="chevron-down"
          />
          <img
            v-else
            src="~@/assets/icon/game/arrow-down-icon.png"
            style="width: 30px;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
import AssetIcon from '@/components/swap/AssetIcon.vue';
import config from '@/config';
import { scale } from '@/utils/helpers';
import CurrencyInput from '@/components/CurrencyInput.vue';

export default {
  name: 'KurveSwapInput',
  components: { AssetIcon, CurrencyInput },
  props: {
    type: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    symbol: {
      type: String,
      required: true
    },
    enableMax: {
      type: Boolean,
      default: false
    },
    amount: {
      type: String,
      default: ''
    },
    showBalance: {
      type: Boolean,
      default: true
    },
    disable: {
      type: Boolean,
      default: false
    },
    transactionPending: {
      type: Boolean,
      default: false,
      require: true
    },
    isCalculating: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      amountData: this.amount
    };
  },
  watch: {
    amount() {
      if (new BigNumber(this.amountData).comparedTo(this.amount) === 0) {
        return null;
      }
      this.amountData = this.amount;
    }
  },
  computed: {
    balance() {
      const { balances } = this.$store.state.account;
      const metadata = this.$store.getters['assets/metadata'];
      if (!balances || !metadata) {
        return '0';
      }
      const assetMetadata = metadata[this.address];
      const balance = balances[this.address];
      if (!assetMetadata || !balance) {
        return '0';
      }

      const balanceNumber = new BigNumber(balance);
      const assetDecimals = assetMetadata.decimals;
      const balanceShortNumber = scale(balanceNumber, -assetDecimals);
      const balanceInNumberLb = parseFloat(
        balanceShortNumber.toFixed(config.precision)
      );
      return balanceInNumberLb;
    }
  },
  methods: {
    getPrecision() {
      return this._hasLimitedDecimalToken(this.address) ? 6 : 18;
    },
    // handleChange: _.debounce(e => {
    //   this.$emit('handle-input-change', { value: e.target.value, type: this.type });
    // }, 500),
    handleChange(e) {
      this.amountData = this.handleValue(e.target.value);
      this.$emit('handle-input-change', {
        value: this.amountData,
        type: this.type
      });
    },
    handleInputChange(event) {
      let value = event || this.amountData;
      value = this._validInputNumber(value, this.getPrecision());
      if (new BigNumber(value).comparedTo(this.amount) !== 0) {
        this.$emit('handle-input-change', {
          value: value,
          type: this.type
        });
      }
    },
    handleInputMax() {
      this.amountData = this.balance;
      this.$emit('handle-input-change', {
        value: this.amountData,
        type: this.type
      });
      this.$emit('handle-max');
    },
    handleSelectAsset() {
      this.$emit('handle-select-asset', this.type);
    }
  }
};
</script>

<style lang="scss" scoped>
.disable {
  pointer-events: none;
  opacity: 0.5;
}
.asset-input {
  display: flex;
  justify-content: space-between;
  height: 65px;
  background: var(--input-background);
  border: 1px solid rgba(0, 0, 0, 0.075);
  box-sizing: border-box;
  box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.075);
  border-radius: 5px;
}
.amount-wrapper {
  display: flex;
}
.asset-wrapper {
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.icon-wrapper {
  line-height: 30px;
}
.asset-wrapper:hover {
  background: $line-hover-color;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.asset-meta {
  display: flex;
  align-items: center;
}

.asset-icon {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  margin-left: 10px;
}

.asset-symbol {
  display: block;
  max-width: 68px;
  margin-left: 8px;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  color: var(--secondary-text-color);
  text-overflow: ellipsis;
  @media only screen and (max-width: 768px) {
    font-size: 13px !important;
  }
}
.chevron-down {
  margin-left: 10px;
  margin-top: 4px;
  margin-right: 10px;
  font-size: 7px !important;
  color: var(--text-color);
}
.amount-wrapper {
  display: flex;
}
.amount {
  align-items: center;
  width: 240px;
  display: flex;
}

.input-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 180px;
  position: relative;
}

.balance-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.hidden {
  visibility: hidden;
}
.loading {
  position: absolute;
  width: 120px;
  height: 29px;
  top: 8px;
  left: 20px;
  background: #21222c;
  animation-name: pulse;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  @media only screen and (max-width: 768px) {
    top: 0px;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.2;
  }

  10% {
    opacity: 0.7;
  }

  100% {
    opacity: 0.2;
  }
}

.swap-input {
  padding-left: 20px;
  font-size: 28px;
  color: var(--secondary-text-color);
  border: none;
  background: transparent;
  text-align: left;
  outline: none;
  input::placeholder {
    opacity: 0.3;
    font-size: 28px;
    font-weight: 600;
    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
}

input:not(:placeholder-shown) {
  opacity: 1;
}
.max-button {
  color: #fff;
  background: $counter-btn-bg-color;
  margin: auto;
  display: flex;
  border-radius: 100px;
  width: 57px;
  height: 28px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  line-height: 28px;
  border: none;
  &:hover {
    background: $counter-btn-bg-hover-color !important;
    border: 0 !important;
  }
}

.info {
  display: flex;
  margin-bottom: 3px;
  justify-content: space-between;
  font-size: 16px;
  width: 100%;
}
.type-label {
  color: var(--type-label);
}
.balance {
  color: var(--swap-balance-text-color);
}

.info.game {
  .type-label {
    font-size: 13px;
  }

  .balance {
    font-size: 14px;
  }

  @media only screen and (max-width: 768px) {
    .type-label,
    .balance {
      font-size: 11px;
    }
  }
}

.chevron-icon {
  width: 12px;
  height: 12px;
  margin-right: 12px;
  margin-bottom: 12px;
}

@media only screen and (max-width: 768px) {
  .amount-wrapper {
    .amount {
      width: 150px;
      .input-wrapper {
        width: 130px;
      }
      .max-button {
        margin: auto;
        width: 45px;
        min-width: 45px;
        height: 24px;
        line-height: 1;
        font-size: 10px;
        z-index: 1;
      }

      .game-border-button-max {
        height: 20px;
        font-size: 9px;
      }
    }
  }
  .asset-wrapper {
    width: 130px;
    justify-content: flex-end;
  }
  .swap-input {
    font-size: 20px;
  }
  .input {
    min-width: 100px;
    ::placeholder {
      font-size: 20px;
    }
  }
  .asset-symbol {
    font-size: 14px !important;
  }
}
.select-token {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  background: var(--panel-background);
  color: $color-primary;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-right: 10px;
  .chevron-down {
    margin-right: 0 !important;
    color: $color-primary !important;
  }
}

.select-token.game-btn-select-token {
  border-radius: 0 !important;
  padding: 6px 5px;
  font-size: 9px;
}

.game-asset-symbol-fontsize {
  font-size: 14px !important;
  line-height: 1.75;
  margin-top: 5px;
}

.game-border-button-max {
  border-radius: 0 !important;
  font-size: 9px;
  height: 24px;
  width: 50px;
}

@media only screen and (max-width: 768px) {
  .select-token {
    height: 36px;
    width: 88px;
  }
  .select-token.game-btn-select-token {
    height: 25px;
  }
  .text-select-token {
    width: 50px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .game-border-button-max {
    font-size: 8px;
    height: 20px;
  }
  .game-asset-symbol-fontsize {
    font-size: 11px !important;
    padding-top: 5px;
    line-height: 20px;
  }

  .asset-icon {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    margin-left: 10px;
  }
}

.asset-input.game-input-swap {
  border-radius: 0 !important;
  height: 45px !important;
}

.game-swap-input {
  font-size: 16px !important;
  line-height: 40px !important;
  input {
    ::placeholder {
      font-size: 20px !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .info.game {
    font-size: 11px !important;
  }
}
</style>
