<template>
  <div>
    <div class="liquidity-remove border px-5 py-6 my-4">
      <div class="head-container flex flex-column py-3">
        <div class="back-icon" @click="goBack">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="NavigationTabs__StyledArrowLeft-sc-1pcf11z-3 dsXEtN"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
        </div>
        <div class="title">Remove Liquidity</div>
      </div>

      <div class="my-3">
        <liquidity-pool-input
          :pool="poolId"
          :tokenA="tokenA"
          :tokenB="tokenB"
          :amount="userLiquidity"
          :enable-max="true"
          @handle-input-change="handleInputChange"
          @handle-max="handleMax"
        ></liquidity-pool-input>
      </div>
      <div class="arrow-down d-flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#565A69"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <polyline points="19 12 12 19 5 12"></polyline>
        </svg>
      </div>
      <div class="my-3">
        <liquidity-input
          :type="'tokenOut'"
          :address="tokenA"
          :symbol="symbolA"
          :amount="tokenLiquidity[tokenA]"
          :enable-max="false"
          :show-balance="false"
          :disable="true"
        ></liquidity-input>
      </div>
      <div class="text-center">+</div>
      <div class="my-3">
        <liquidity-input
          :type="'tokenOut'"
          :address="tokenB"
          :symbol="symbolB"
          :amount="tokenLiquidity[tokenB]"
          :enable-max="false"
          :show-balance="false"
          :disable="true"
        ></liquidity-input>
      </div>
      <div class="price d-flex">
        <div>Price</div>
        <div>
          <div v-if="poolInfo.price">
            1 {{ symbolA }} = {{ poolInfo.price.price.decimalPlaces(5) }}
            {{ symbolB }}
          </div>
          <div v-if="poolInfo.price">
            1 {{ symbolB }} = {{ poolInfo.price.invert.decimalPlaces(5) }}
            {{ symbolA }}
          </div>
        </div>
      </div>
      <div class="liquidity-button">
        <button
          :class="{
            'btn-disable':
              !userLiquidity || approval === ApprovalState.APPROVED,
            'bg-info': userLiquidity && approval !== ApprovalState.APPROVED,
            'bg-success': userLiquidity && approval === ApprovalState.APPROVED,
            'bg-none': !userLiquidity
          }"
          :disabled="!userLiquidity || approval === ApprovalState.APPROVED"
          @click="handleApproval"
        >
          <!-- <UiLoading v-if="buttonError.loading" /> -->
          <span>{{
            approval === ApprovalState.APPROVED ? 'Approved' : 'Approve'
          }}</span>
        </button>
        <button
          :class="
            buttonError.disable || approval !== ApprovalState.APPROVED
              ? 'btn-disable'
              : ''
          "
          :disabled="buttonError.disable || approval !== ApprovalState.APPROVED"
          @click="handleRemoveLiquidity"
        >
          <UiLoading v-if="buttonError.loading" />
          <span v-else>Remove</span>
        </button>
      </div>
      <div v-if="requireLogin" class="liquidity-button">
        <button
          class="btn-connect bg-info"
          :disabled="!requireLogin"
          @click="handleConnectWallet"
        >
          <UiLoading v-if="connectButtonLoading" />
          <span v-else>Connect Wallet</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';
import { mapActions } from 'vuex';
import LiquidityInput from '@/components/Kurve/LiquidityInput';
import LiquidityPoolInput from '@/components/Kurve/LiquidityPoolInput';
import {
  getKurvePoolTotalSupply,
  kurveRemoveLiquidity,
  kurveRequestSignature,
  wrappedToken
} from '@/helpers/kurve';
import { getEtherscanLink } from '@/utils/helpers';
import { ApprovalState } from '@/utils';
import Storage from '@/utils/storage';
import { ErrorCode } from '@ethersproject/logger';
import _provider from '@/helpers/provider';

export default {
  name: 'RemoveLiquidityKurve',
  components: { LiquidityPoolInput, LiquidityInput },
  data: () => {
    return {
      loading: true,
      provider: null,
      poolId: '',
      poolInfo: {},
      userLiquidity: '',
      totalSupply: '',
      tokenA: '',
      tokenLiquidity: {},
      tokenB: '',
      approval: ApprovalState.NOT_APPROVED,
      signature: null,
      buttonError: {
        loading: false,
        disable: true
      },
      connectButtonLoading: false,
      ApprovalState
    };
  },
  computed: {
    requireLogin() {
      return !this.isAuthenticated;
    },
    account() {
      const { connector, address } = this.$store.state.account;
      if (!connector || !connector.id || !address) {
        return '';
      }
      return address;
    },
    symbolA() {
      return this.symbol(this.tokenA);
    },
    symbolB() {
      return this.symbol(this.tokenB);
    }
  },
  async mounted() {
    this.provider = await this.$store.getters['account/provider'];

    this.poolId = this.$route.params.poolId;
    const tokenA = this.$route.params.tokenA;
    const tokenB = this.$route.params.tokenB;
    this.tokenA =
      wrappedToken(tokenA).toLowerCase() < wrappedToken(tokenB).toLowerCase()
        ? tokenA
        : tokenB;
    this.tokenB =
      wrappedToken(tokenA).toLowerCase() < wrappedToken(tokenB).toLowerCase()
        ? tokenB
        : tokenA;

    const assets = this.$store.getters['assets/metadata'];
    const poolInfo = await this.getKurvePoolDetailByAddress({
      provider: this.provider,
      address: this.poolId,
      tokenA: this.tokenA,
      tokenB: this.tokenB,
      assets
    });
    this.poolInfo = poolInfo;

    const _totalSupply = await getKurvePoolTotalSupply(
      this.provider,
      this.poolId
    );
    this.totalSupply = new BigNumber(_totalSupply.toString()).dividedBy(
      new BigNumber(10).pow(18)
    );

    this.loading = false;
  },
  methods: {
    ...mapActions(['getKurvePoolDetailByAddress']),
    symbol(address) {
      const assets = this.$store.getters['assets/metadata'];
      const asset = assets[address];
      if (!asset) {
        return '';
      }
      return asset.symbol;
    },
    handleInputChange({ value }) {
      // reset signature permission
      this.signature = null;
      this.approval = ApprovalState.NOT_APPROVED;

      this.userLiquidity = value;
      if (this.loading) {
        return;
      }
      if (!this.userLiquidity) {
        this.tokenLiquidity[this.poolInfo.pool.tokenA.address] = '';
        this.tokenLiquidity[this.poolInfo.pool.tokenB.address] = '';
        this.buttonError.disable = true;
        return;
      }
      // calcualte amount A
      // const _userLiquidity = await getKurvePoolBalanceByAddress(this.provider, this.poolId, this.account)
      // const userLiquidity = new BigNumber(_userLiquidity.toString()).dividedBy(new BigNumber(10).pow(18))
      const userLiquidityA = new BigNumber(this.userLiquidity)
        .multipliedBy(this.poolInfo.pool.tokenA.tokenAmount)
        .dividedBy(this.totalSupply);
      this.tokenLiquidity[
        this.poolInfo.pool.tokenA.address
      ] = userLiquidityA.decimalPlaces(18).toString();
      const userLiquidityB = new BigNumber(this.userLiquidity)
        .multipliedBy(this.poolInfo.pool.tokenB.tokenAmount)
        .dividedBy(this.totalSupply);
      this.tokenLiquidity[
        this.poolInfo.pool.tokenB.address
      ] = userLiquidityB.decimalPlaces(18).toString();

      this.buttonError.disable = false;
    },
    handleMax() {
      // do nothing
    },
    handleConnectWallet() {
      this.connectButtonLoading = true;
      this.$store.dispatch('openAccountModal');
    },
    async handleApproval() {
      const deadline = Storage.getDeadline();
      this.signature = await kurveRequestSignature(
        this.provider,
        this.account,
        this.poolId,
        new BigNumber(dayjs().valueOf())
          .dividedBy(1000)
          .plus(deadline * 60)
          .decimalPlaces(0),
        new BigNumber(this.userLiquidity)
          .multipliedBy(new BigNumber(10).pow(18))
          .decimalPlaces(0)
      );
      if (this.signature) {
        this.approval = ApprovalState.APPROVED;
      }
    },
    async handleRemoveLiquidity() {
      this.buttonError.loading = true;
      const assets = this.$store.getters['assets/metadata'];
      const finalUserLiquidity = new BigNumber(this.userLiquidity).multipliedBy(
        new BigNumber(10).pow(18)
      );

      const finalTokenAmountA = new BigNumber(
        this.tokenLiquidity[this.poolInfo.pool.tokenA.address]
      ).multipliedBy(new BigNumber(10).pow(assets[this.tokenA].decimals));
      const finalTokenAmountB = new BigNumber(
        this.tokenLiquidity[this.poolInfo.pool.tokenB.address]
      ).multipliedBy(new BigNumber(10).pow(assets[this.tokenB].decimals));

      const slippage = this.poolInfo.noLiquidity ? 0 : Storage.getSlippage();
      const finalMinTokenAmountA = finalTokenAmountA.multipliedBy(1 - slippage);
      const finalMinTokenAmountB = finalTokenAmountB.multipliedBy(1 - slippage);

      const tx = await kurveRemoveLiquidity(
        this.tokenA,
        this.tokenB,
        this.poolId,
        finalUserLiquidity.decimalPlaces(0),
        finalMinTokenAmountA.decimalPlaces(0),
        finalMinTokenAmountB.decimalPlaces(0),
        this.account,
        this.signature,
        this.provider
      );
      if (tx === null) {
        this.buttonError.loading = false;
        this.$store.dispatch('notify', {
          text: this.$t('transactionMessages.transactionFailContactSupport'),
          type: 'warning',
          link: config.helpUrl
        });
        return;
      }
      await this.handleTransaction(
        tx,
        'transactionTitles.removeStablecoinLiquidity'
      );
      this.$store.dispatch('account/fetchAssets', [this.tokenA, this.tokenB]);
      this.buttonError.loading = false;
    },
    async handleTransaction(transaction, text) {
      await this.$store.dispatch('transactions/handleTransaction', {
        transaction,
        titleKey: text
      });

      this.transactionPending = false;
    },
    goBack() {
      this.$router.push(
        `/liquidity/kurve?tokenA=${this.tokenA}&tokenB=${this.tokenB}`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.liquidity-remove {
  background-color: white;
  border-radius: 20px;
  width: 500px;
  margin: 0px auto;
}
.head-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .title {
    font-size: 25px;
  }
}
.info {
  justify-content: space-between;
  padding: 16px 0px;
}
.back-icon {
  cursor: pointer;
  position: absolute;
  left: 0;
}
.arrow-down {
  align-items: center;
  justify-content: center;
}
.price {
  margin-top: 20px;
  justify-content: space-between;
}
.liquidity-button {
  margin: 30px 0px 10px;
  display: flex;
  justify-content: space-between;
  button {
    width: 50%;
    max-width: 190px;
    border: none;
    border-radius: 10px;
    background-color: #ff6871;
    color: white;
    font-size: 18px;
    padding: 16px 0px;
  }
  button.bg-blue {
    background-color: #08a1e7;
  }
  button.bg-none {
    color: #3f3f3f;
    background-color: white;
    border: 2px solid #ccc;
  }
  button.bg-none.btn-disable {
    background-color: #ccc;
  }
  button.bg-success:hover {
    background-color: #28a745 !important;
  }
}
.btn-connect {
  width: 100% !important;
  max-width: initial !important;
}
.btn-disable {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>
