<template>
  <div>
    <UiLoading v-if="!poolId" class="big py-4" />
    <UiTable
      style="border-top-left-radius: 0 !important;"
      class="details-balances"
      v-else
    >
      <UiTableTh>
        <div v-text="$t('token')" class="flex-auto text-left" />
        <div v-text="$t('weight')" class="column" />
        <div v-text="$t('poolBalance')" class="column hide-sm" />
        <div v-text="$t('myBalance')" class="column hide-sm hide-md" />
        <div
          v-html="$t('myAssetValue')"
          class="column hide-sm hide-md hide-lg"
        />
      </UiTableTh>
      <div class="table-body">
        <UiTableTr
          v-for="token in poolTokens.tokens"
          :key="token.address"
          class="table-tr"
        >
          <div class="flex-auto text-left" style="display: flex">
            <Token
              :address="token.address"
              :symbol="token.symbol"
              class="mr-1"
            />
            <span
              :class="token.symbol.length > 28 && 'tooltipped tooltipped-n'"
              :aria-label="token.symbol"
            >
              <span class="name">
                {{ _shorten(token.symbol, 28) }}
              </span>
            </span>
          </div>
          <UiNum
            :value="token.weightPercent / 1e2"
            format="percent"
            class="column"
          />
          <span class="column hide-sm">{{ getPoolBalance(token) }}</span>
          <span class="column hide-sm hide-md">{{
            getMyPoolBalance(token)
          }}</span>
          <div
            v-text="getMyPoolBalanceInUSD(token)"
            format="currency"
            class="column hide-sm hide-md hide-lg"
          />
        </UiTableTr>
      </div>
    </UiTable>
  </div>
</template>

<script>
import { formattedNum } from '@/utils';
export default {
  name: 'PoolDetailTokensKurve',
  props: ['poolId', 'poolTokens'],
  methods: {
    getPoolBalance(token) {
      return `${formattedNum(token.denormWeight)}`;
    },
    getMyPoolBalance(token) {
      if (!this.poolTokens.poolTokenPercentage) {
        return '-';
      }
      return `${formattedNum(
        token.denormWeight * this.poolTokens.poolTokenPercentage
      )}`;
    },
    getMyPoolBalanceInUSD(token) {
      if (!this.poolTokens.poolTokenPercentage) {
        return '-';
      }
      const price = this.price.values[token.checksum];
      return `$${formattedNum(
        token.denormWeight * this.poolTokens.poolTokenPercentage * price
      )}`;
    }
  }
};
</script>
<style lang="scss" scoped>
.table-body {
  // border: 1px solid var(--border-table-body);
  // border-bottom-left-radius: 5px;
  // border-bottom-right-radius: 5px;
  border-top: none;
  overflow: hidden;
}
.table-tr {
  height: 50px !important;
}
.name {
  color: var(--text-color);
  margin-left: 10px;
}
</style>
