<template>
  <Page>
    <Container>
      <NavBarVote />
      <div>
        <SearchVote
          @select-item="selectCategory"
          @sort="sort"
          @search="search"
        />
      </div>
      <div class="proposal-body">
        <div class="proposal-card" v-if="proposals.length > 0">
          <ProposalCard
            v-for="item in proposals"
            :key="item.id"
            :typeProposalCard="'vote'"
            :proposalInfo="item"
            @REFRESH_DATA="fetchProposals(params)"
          />
        </div>
        <div class="proposal-card" v-else>
          <div class="no-result-wrapper">
            {{ $t('noVoteResult') }}
          </div>
        </div>
        <div class="submit-vote">
          <FormSubmitVote />
        </div>
      </div>
    </Container>
  </Page>
</template>

<script>
import ProposalCard from '@/components/Vote/ProposalCard.vue';
import NavBarVote from '@/components/Vote/NavBarVote.vue';
import FormSubmitVote from '@/components/Vote/FormSubmitVote.vue';
import SearchVote from '@/components/Vote/SearchVote.vue';
import Page from '@/components/Page.vue';
import Container from '@/components/Container.vue';
import { normalizeData } from '@/helpers/utils';
import rf from '@/requests/RequestFactory';

export default {
  data() {
    return {
      proposals: [],
      params: {
        status: 2,
        limit: 1000
      }
    };
  },
  components: {
    ProposalCard,
    Page,
    Container,
    FormSubmitVote,
    SearchVote,
    NavBarVote
  },
  watch: {
    async params() {
      await this.fetchProposals(this.params);
    }
  },
  methods: {
    async fetchProposals(params) {
      const res = await rf
        .getRequest('VoteRequest')
        .getProposals(normalizeData(params));
      this.proposals = res.docs || [];
    },
    async init() {
      await this.fetchProposals(normalizeData(this.params));
    },
    async selectCategory(selectId) {
      this.params = {
        ...this.params,
        categoryId: selectId
      };
    },
    search(value) {
      this.params = {
        ...this.params,
        search: value
      };
    },
    async sort(value) {
      if (value === 'Up vote') {
        this.params = {
          ...this.params,
          sortByUpvotedNumber: -1,
          sortByDownvotedNumber: null,
          sortByCreatedTime: null
        };
      }
      if (value === 'Down vote') {
        this.params = {
          ...this.params,
          sortByDownvotedNumber: -1,
          sortByUpvotedNumber: null,
          sortByCreatedTime: null
        };
      }
      if (value === 'Created time') {
        this.params = {
          ...this.params,
          sortByDownvotedNumber: null,
          sortByUpvotedNumber: null,
          sortByCreatedTime: 1
        };
      }
      if (value === 'Any') {
        this.params = {
          ...this.params,
          sortByDownvotedNumber: null,
          sortByUpvotedNumber: null,
          sortByCreatedTime: null
        };
      }
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.proposal-body {
  display: flex;
  align-items: flex-start;
}

.proposal-card {
  min-width: 70%;
  .no-result-wrapper {
    margin-top: 24px;
    width: 100%;
    max-width: 100%;
    background: var(--panel-background);
    border-radius: 10px;
    padding: 29px 24px;
    color: var(--type-label);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
}

.submit-vote {
  width: 30%;
}

@media only screen and (max-width: 768px) {
  .proposal-body {
    display: block;
  }
  .proposal-card {
    width: 100%;
  }

  .submit-vote {
    width: 100%;
  }
}
</style>
