var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pool.id)?_c('UiModal',{staticClass:"modal-liquidity",attrs:{"open":_vm.open},on:{"close":function($event){return _vm.$emit('close')}}},[_c('UiModalForm',{staticClass:"style-addLiquidity"},[_c('template',{slot:"header"},[_c('div',{staticClass:"remove-Liquidity",class:_vm._isThemeGame && 'game',domProps:{"textContent":_vm._s(_vm.$t('removeLiquidity'))}})]),_c('div',{staticClass:"m-4 mt-0 liquidity-toggle",class:_vm._isMobile && _vm._isThemeGame ? 'pt-4' : ''},[_c('Toggle',{staticClass:"mt-4 margin",class:_vm.loading && 'disable',attrs:{"value":_vm.type,"options":_vm.liquidityToggleOptions},on:{"select":_vm.handleSelectType}})],1),_c('div',{staticClass:"m-4 d-block d-sm-flex"},[_c('PoolOverview',{staticClass:"hide-sm hide-md col-3 float-left",attrs:{"pool":_vm.pool,"userShare":_vm.userLiquidity.relative}}),_c('div',{staticClass:"col-12 col-md-9 float-left pl-0 pl-md-4 padding-table"},[_c('UiTable',{staticClass:"table-liquidity"},[_c('UiTableTh',[_c('div',{staticClass:"column-lg flex-auto text-left text",domProps:{"textContent":_vm._s(_vm.$t('asset'))}}),_c('div',{staticClass:"column text",domProps:{"textContent":_vm._s(_vm.$t('myPoolBalance'))}}),_c('div',{staticClass:"column-sm-liquidity text",domProps:{"textContent":_vm._s(_vm.$t('withdrawalAmt'))}})]),_vm._l((_vm.tokens),function(token){return _c('UiTableTr',{key:token.address,staticClass:"asset",class:{
              active: _vm.isMultiAsset || _vm.activeToken === token.address
            }},[_c('div',{staticClass:"column-lg-liquidity flex-auto flex-items-center d-flex text-left"},[(!_vm.isMultiAsset)?_c('UiRadio',{staticClass:"mr-1",class:_vm.loading && 'disable',attrs:{"checked":_vm.activeToken === token.address,"onChange":function (e) {
                    _vm.onTokenSelect(token.address);
                  }}}):_vm._e(),_c('div',{staticClass:"text-token d-flex flex-items-center text-uppercase",class:[
                  token.symbol.length > 7 && 'tooltipped tooltipped-ne',
                  _vm._isThemeGame && 'game-text-token'
                ],attrs:{"aria-label":token.symbol}},[_c('Token',{staticClass:"mr-3",attrs:{"address":token.address,"size":"20"}}),_vm._v(" "+_vm._s(_vm._isMobile ? _vm._shorten(token.symbol, 4) : _vm._shorten(token.symbol, 7))+" ")],1)],1),_c('div',{staticClass:"column-token",class:_vm._isThemeGame && 'game-text-token'},[_vm._v(" "+_vm._s(token.myBalance ? _vm._num(_vm.checkWithdrawalAmt(token.myBalance)) ? _vm._num(token.myBalance) : _vm._num(parseFloat(token.myBalance)) : '-')+" ")]),_c('div',{staticClass:"column-token",class:_vm._isThemeGame && 'game-text-token'},[_vm._v(" "+_vm._s(_vm.getTokenAmountOut(token) ? _vm._num(_vm.checkWithdrawalAmt(_vm.getTokenAmountOut(token))) ? _vm._num(_vm.getTokenAmountOut(token)) : _vm._num(parseFloat(_vm.getTokenAmountOut(token))) : '-')+" ")])])})],2),_c('UiTable',{staticClass:"mt-4 table-liquidity"},[_c('UiTableTh',{staticClass:"text-left flex-items-center text-token table-liquidity"},[_c('div',{staticClass:"flex-auto",domProps:{"textContent":_vm._s(_vm.$t('amount'))}}),_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm._num(parseFloat(_vm.poolTokenBalance)))+" "+_vm._s(_vm._shorten(_vm.pool.symbol, 12))+" "),_c('a',{staticClass:"link-text mr-3",class:_vm.loading && 'disable',on:{"click":_vm.setMax}},[_c('UiLabel',{domProps:{"textContent":_vm._s(_vm.$t('max'))}})],1)]),_c('currency-input',{staticClass:"cards__input-number text-right column-sm",class:[
                _vm.validationError ? 'text-red' : 'text-input',
                _vm._isThemeGame
                  ? 'game-placeholder-small game-text-token game-btn-input-liquidity'
                  : 'input',
                _vm.loading && 'disable'
              ],attrs:{"id":"poolAmountIn","max":_vm.totalShares,"min":0,"precision":12,"type":"text","step":"any","placeholder":"0.0"},model:{value:(_vm.poolAmountIn),callback:function ($$v) {_vm.poolAmountIn=$$v},expression:"poolAmountIn"}})],1)],1)],1)],1),_c('div',{staticClass:"m-4"},[(_vm.validationError)?_c('MessageError',{attrs:{"text":_vm.validationError}}):_vm._e(),(_vm.slippage)?_c('MessageSlippage',{staticClass:"mb-4",attrs:{"value":_vm.slippage,"isDeposit":false}}):_vm._e()],1),_c('template',{slot:"footer"},[_c('div',{class:_vm._isThemeGame ? 'pt-4 pb-3 mt-4' : 'pt-4'},[_c('Button',{staticClass:"btn-liquidity",class:_vm._isThemeGame ? 'game-button' : 'liquidity',attrs:{"requireLogin":true,"disabled":_vm.validationError || _vm.loading,"loading":_vm.loading},on:{"submit":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t('removeLiquidity'))+" ")])],1)])],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }