<template>
  <div class="row_element row mt-4">
    <div
      :class="_isThemeGame ? 'game game-border-table' : ''"
      v-for="nftItem in listNFTs"
      :key="'nftItem-' + nftItem.tokenId"
      class="card-wrapper"
    >
      <div class="column_element element-vesting">
        <div class="pt-3 header d-flex">
          <div class="border-logo mr-2">
            <img src="~/@/assets/logo_vesting.png" class="logo" />
          </div>
          <div :class="_isThemeGame ? 'game-vesting-amount' : 'vesting-amount'">
            Hash rate: {{ _num(nftItem.hashRate) }}
            <template v-if="nftItem.tokenId">
              (ID: {{ nftItem.tokenId }})
            </template>
          </div>
        </div>
        <div
          class="image-frame"
          @click="openVestingCardModal(nftItem.imageUrl)"
        >
          <img :src="nftItem.smallImageUrl" class="tokenImage"/>
        </div>
        <div>{{ nftItem.name }}</div>
      </div>
    </div>
    <portal to="modal">
      <div class="vesting-card" v-if="openVestingCard">
        <div class="content">
          <div class="close-icon" @click="closeVestingCardModal">
            <img src="../../assets/close-vesting-card.svg" />
          </div>
          <div class="card-img">
            <img :src="vestingCardUrl" class="tokenImage" />
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import Countdown from '@/components/Countdown';
import moment from 'moment';
import BlockchainHelper from '@/helpers/BlockchainHelper';
import ModalWarningStake from '@/components/Ui/Modal/ModalWarningStake';
import config from '@/config';

export default {
  props: {
    listNFTs: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      openVestingCard: false,
      vestingCardUrl: '',
    };
  },
  computed: {
    wrongNetwork() {
      return this.$store.state.account.wrongNetwork;
    }
  },
  methods: {
    openVestingCardModal(imageUrl) {
      this.openVestingCard = true;
      this.vestingCardUrl = imageUrl;
    },
    closeVestingCardModal() {
      this.openVestingCard = false;
      this.vestingCardUrl = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  margin: 15px;
  border-radius: 10px;
  box-shadow: 0 0 5px var(--border-table);
  overflow: hidden;
}
.vesting-card {
  background-image: url('../../assets/vesting-modal-bg.svg');
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 10;
  .content {
    position: relative;
    display: flex;
    justify-content: center;
    .close-icon {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 100%;
      width: 45px;
      height: 45px;
      @media only screen and (max-width: 767px) {
        left: 85%;
        top: -50px;
      }
    }
    .card-img {
      width: 450px;
      @media only screen and (max-width: 767px) {
        width: calc(100% - 40px);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.row_element {
  @media only screen and (max-width: 767px) {
    margin-right: 0 !important;
    margin-left: 0 !important;
    display: flex;
    justify-content: center;
  }
}
.countdown-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -13px;
}
.element-vesting {
  height: 100%;
  background: var(--panel-background);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.col-md-3 {
  margin-bottom: 40px;
}

.vesting-amount {
  color: var(--text-color);
  min-width: 85px;
}

.game-vesting-amount {
  color: var(--text-color);
  min-width: 103px;
}

.col-md-3.game {
  margin-bottom: 0 !important;
  @media only screen and (min-width: 769px) {
    padding: 0 !important;
  }
}

.text-percent {
  color: var(--loading-color);
  font-size: 18px;
}
.text-game {
  color: var(--loading-color);
  font-size: 16px;
}

.text-time {
  font-size: 20px;
}
.text-time.vesting {
  color: var(--loading-color);
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  &:hover {
    background: var(--panel-background) !important;
    color: $color-primary !important;
  }
}
.header {
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: var(--vesting-header-text-color);
  font-size: 14px;
  width: calc(100% - 2rem);
}

.logo {
  //padding: 8px 10px;
  width: 100%;
  border-radius: 100%;
}

.display {
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.column {
  width: 33%;
  text-align: center;
}

.column_element {
  padding-bottom: 30px;
  position: relative;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.game-border-button-max {
  font-size: 12px !important;
}
.game-border-table {
  .column_element {
    padding-bottom: 0;

    @media only screen and (max-width: 768px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.compelete {
  border-radius: 50px;
  background: #3990f8;
  color: $text-white;
  padding: 5px 10px;
}

.game-border-button-max {
  background: #3990f8;
  color: $text-white;
  padding: 5px 5px;
  font-size: 14px;
  height: 25px;
}
.count-down {
  box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.35), inset -2px -2px 0px #011838,
    inset 1px 1px 0px rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: #0c2a54;
  padding: 8px 0;
  width: fit-content;
}

.game-border-table {
  background: $text-white;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 20px;
  margin-right: 20px;
}

.game-border-table-countdown {
  background: #f4f4f4;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 20px;
}

.border-logo {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  border: 1px solid $color-primary;
}
.button-claim {
  background-color: $color-primary !important;
  color: #ffffff !important;
  &:hover {
    background: #d35a05 !important;
    color: $text-white;
    border: 2px solid #d35a05 !important;

    @media only screen and (max-width: 767px) {
      background-color: #d35a05 !important;
      border: 2px solid #d35a05;
      color: #ffffff !important;
    }
  }
}
.button-claim,
.button-claim-now {
  color: $color-primary;
  border-color: $color-primary;
  border-width: 2px;
  width: calc(100% - 40px);
  background: none;
  display: flex;
  justify-content: center !important;
  align-items: center;
  font-size: 14px;
  height: 35px;
  margin-bottom: 15px;
  &:hover {
    background: $btn-bg-hover-color;
    color: $text-white;
    border: 2px solid $btn-bg-hover-color;

    @media only screen and (max-width: 767px) {
      background: none !important;
      border: 2px solid $color-primary;
      color: $color-primary !important;
    }
  }
}
.button-claim-now.game-border-btn-add {
  border-radius: 0 !important;
  height: inherit !important;
  color: $color-primary;
  border-color: $color-primary;
  margin-bottom: 35px !important;
  padding: 4px 15px !important;
  .text-button {
    font-family: $font-forward !important;
  }

  &:hover {
    background: $btn-bg-hover-color;
    color: $text-white;
    border: 2px solid $btn-bg-hover-color;

    @media only screen and (max-width: 767px) {
      background: none !important;
      border: 2px solid $color-primary;
      color: $color-primary !important;
    }
  }
}

.button-claim.game-border-btn-add {
  border-radius: 0 !important;
  height: inherit !important;
  background-color: $color-primary;
  color: $text-white;
  margin-bottom: 35px !important;
  padding: 4px 15px !important;
  .text-button {
    font-family: $font-forward !important;
  }

  &:hover {
    background: $game-btn-hover-color;
    @media only screen and (max-width: 767px) {
      background: $color-primary !important;
      color: $text-white !important;
    }
  }
}
.game-btn-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  &:hover {
    background-color: $color-primary !important;
  }
}
a, a:hover {
  color: unset;
}
.tokenImage {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.image-frame {
  cursor: pointer;
  margin: 15px 20px;
  width: 235px;
  height: 235px;
  position: relative;
  img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.card-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.claim-button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.button-claim-game-item {
  width: calc(100% - 40px);
  // pointer-events: none !important;
}
.game-item-disable {
  margin-top: 10px !important;
}
.claim-buni-tooltip {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vesting-amount {
  text-align: right;
}
</style>
