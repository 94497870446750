<template>
  <Page>
    <Container>
      <Title
        :title="$t('tokenPlayFarms')"
        :subTitle="$t('subTitleTokenPlayFarms')"
        :tvl="$store.state.summaries.totalLiquidity"
        :totalMyLiquidity="totalMyLiquidity"
      />
      <TokenFarmBannar />
      <FilterActions
        @switchTab="switchTab"
        @stakedToggle="onStakeChange"
        @sort="onSort"
        v-on:inputSearch="onInputSearch"
      />
    </Container>
    <FarmTable
      :farmType="farmType"
      :isStaked="isStaked"
      :isSortedBy="isSortedBy"
      :query="query"
      :farmData="tokenFarms"
      :isInit="isInit"
    />
  </Page>
</template>

<script>
import FilterActions from '@/components/Farms/FilterActions';
import FarmTable from '@/components/Farms/TokenFarm/FarmTable';
import {
  getBalanceNumber,
  getMasterChefAddress,
  getPoolMetadata
} from '@/helpers/farm';
import BigNumber from 'bignumber.js';
import config from '@/config';
import TokenFarmBannar from '@/components/Farms/TokenFarm/TokenFarmBanner';
import { multicall } from '@/_balancer/utils';
import provider from '@/helpers/provider';
import abi from '@/helpers/abi';
import Vue from 'vue';
import { getTokenPriceOnChain } from "@/helpers/price";

const farmConfig = config.tokenFarms;
const tokenMasterChefAddr = config.addresses.tokenMasterChef;
export default {
  metaInfo: { title: 'Farms' },
  data() {
    return {
      farmType: 'live',
      isStaked: false,
      isSortedBy: '',
      query: '',
      tokenFarms: [],
      isInit: false
    };
  },
  components: {
    TokenFarmBannar,
    FilterActions,
    FarmTable
  },
  async mounted() {
    await this.$store.dispatch('initPrices');
    try {
      this.tokenFarms = await this.getFarmData();
    } catch (e) {
      console.error(e);
    }
    this.isInit = true;

    if (this.myAccount) {
      this.getFarmUserData();
      this.getTokenFarmAllowances();
    }

    this.$root.$on('UPDATE_TOKEN_FARM_DATA', async () => {
      await this.updateTokenFarmData();
    });
    this.$root.$on('UPDATE_TOKEN_FARM_USER_DATA', () => {
      this.getFarmUserData();
    });
    this.$root.$on('UPDATE_TOKEN_FARM_USER_ALLOWANCES', () => {
      this.getTokenFarmAllowances();
    });
  },
  beforeDestroy() {
    this.$root.$off('UPDATE_TOKEN_FARM_DATA');
    this.$root.$off('UPDATE_TOKEN_FARM_USER_DATA');
  },
  watch: {
    myAccount() {
      this.getTokenFarmAllowances();
      this.getFarmUserData();
    }
  },
  computed: {
    myAccount() {
      return this.$store.state.account.address;
    },
    totalMyLiquidity() {
      if (!this.tokenFarms) {
        return 0;
      }
      const farmsStaked = this.tokenFarms;

      let totalMyLiquidity = new BigNumber(0);
      farmsStaked.map((farm, index) => {
        const myAddress = this.$store.state.account.address;

        if (!this.wrongNetwork && farm.userData && myAddress) {
          const stakedBalance = getBalanceNumber(
            new BigNumber(farm.userData.stakedBalance)
          );
          const lpPrice = farm.lpPriceUsd;
          const myLiquidity = new BigNumber(stakedBalance)
            .times(lpPrice)
            .toString();
          totalMyLiquidity = new BigNumber(totalMyLiquidity).plus(myLiquidity);
        }
      });
      if (totalMyLiquidity < 1e-4) return '< 0.0001';
      return totalMyLiquidity.toString();
    }
  },
  methods: {
    async updateTokenFarmData() {
      const data = (await this.getFarmData()) || [];
      data.forEach((record, index) => {
        Vue.set(this.tokenFarms, index, {
          ...record,
          userData: this.tokenFarms[index].userData
        });
      });
    },
    switchTab(value) {
      this.farmType = value;
    },
    onStakeChange(isStaked) {
      this.isStaked = isStaked;
    },
    onSort(value) {
      this.isSortedBy = value;
    },
    onInputSearch(value) {
      this.query = value;
    },
    async getTokenPrice(contractAddress) {
      const prices = this.$store.getters.prices;
      let tokenPrice = (
        prices[contractAddress] || prices[contractAddress.toLowerCase()] || 0
      );

      if (new BigNumber(tokenPrice).isZero()) {
        tokenPrice = await getTokenPriceOnChain(contractAddress.toLowerCase());
      }

      return tokenPrice;
    },
    async getStakeUnitPriceUsd(farmConfig) {

      if (farmConfig.stakeType === 'token') {
        return await this.getTokenPrice(farmConfig.poolTokens[0].contractAddress);
      }

      const calls = [];
      farmConfig.poolTokens.forEach(poolToken => {
        calls.push({
          address: poolToken.contractAddress,
          name: 'balanceOf',
          params: [farmConfig.lpAddresses]
        });
      });

      const prices = {};
      for (let index = 0; index < farmConfig.poolTokens.length; index ++) {
        prices[farmConfig.poolTokens[index].contractAddress] = await this.getTokenPrice(farmConfig.poolTokens[index].contractAddress);
      }

      const result = await multicall(provider, abi['LPErc20'], calls);
      const totalLiq = result
        .reduce((total, tokenAmount, index) => {
          const unitPrice = prices[farmConfig.poolTokens[index].contractAddress];

          const totalToken = new BigNumber(unitPrice).times(
            tokenAmount.toString()
          ).div(1e18);

          total = new BigNumber(total).plus(totalToken);

          return total;
        }, new BigNumber(0)).toString();

      let [totalSupply] = await multicall(provider, abi['LPErc20'], [
        {
          address: farmConfig.lpAddresses,
          name: 'totalSupply'
        }
      ]);

      totalSupply = new BigNumber(totalSupply.toString()).div(1e18);
      return new BigNumber(totalLiq).div(totalSupply).toString();
    },
    async getFarmData() {
      const data = await Promise.all(
        farmConfig.map(async farmConfig => {
          const [info, totalAllocPoint] = await multicall(
            provider,
            abi['TokenFarmMasterChef'],
            [
              {
                address: tokenMasterChefAddr,
                name: 'poolInfo',
                params: [farmConfig.pid]
              },
              {
                address: tokenMasterChefAddr,
                name: 'totalAllocPoint'
              }
            ]
          );

          const lpAddress = farmConfig.lpAddresses;

          const [lpTokenBalanceMC] = await multicall(provider, abi['LPErc20'], [
            {
              address: lpAddress,
              name: 'balanceOf',
              params: [tokenMasterChefAddr]
            }
          ]);

          const allocPoint = new BigNumber(info.allocPoint._hex);
          const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint));
          const lpPriceUsd = await this.getStakeUnitPriceUsd(farmConfig);

          return {
            ...farmConfig,
            lpSymbol: farmConfig.lpSymbol,
            lpPriceUsd: new BigNumber(lpPriceUsd).toFixed(5),
            poolTokens: farmConfig.poolTokens,
            poolWeight: poolWeight.toJSON(),
            multiplier: `${allocPoint.div(100).toString()}X`,
            myStake: new BigNumber(lpPriceUsd)
              .times(lpTokenBalanceMC)
              .toString()
          };
        })
      );
      return data;
    },

    async getFarmUserData() {
      const userFarmTokenBalances = await this.fetchFarmUserTokenBalances();
      const userStakedBalances = await this.fetchFarmUserStakedBalances();
      const userFarmEarnings = await this.fetchFarmUserEarnings();

      const arrayOfUserDataObjects = userFarmTokenBalances.map(
        (farmAllowance, index) => {
          return {
            index,
            tokenBalance: userFarmTokenBalances[index],
            stakedBalance: userStakedBalances[index],
            earnings: userFarmEarnings[index]
          };
        }
      );
      arrayOfUserDataObjects.forEach(userDataEl => {
        const { index } = userDataEl;
        Vue.set(this.tokenFarms, index, {
          ...this.tokenFarms[index],
          userData: userDataEl
        });
      });
    },

    async getTokenFarmAllowances() {
      const masterChefAddress = config.addresses.tokenMasterChef;
      const calls = farmConfig.map(farm => {
        const lpContractAddress = farm.lpAddresses;

        return {
          address: lpContractAddress,
          name: 'allowance',
          params: [this.myAccount, masterChefAddress]
        };
      });

      const rawLpAllowances = await multicall(provider, abi['ERC20'], calls);

      const allowances = rawLpAllowances.map(lpBalance => {
        return new BigNumber(lpBalance).toJSON();
      });

      const addAllowances = {};
      config.tokenFarms.forEach((item, index) => {
        addAllowances[
          `${masterChefAddress}-${item.poolAddress}-${item.pid}`
        ] = {};
        addAllowances[`${masterChefAddress}-${item.poolAddress}-${item.pid}`][
          item.lpAddresses
        ] = allowances[index];
      });
      this.$store.commit('account/addAllowances', addAllowances);
    },

    async fetchFarmUserTokenBalances() {
      const calls = farmConfig.map(farm => {
        const lpContractAddress = farm.lpAddresses;
        return {
          address: lpContractAddress,
          name: 'balanceOf',
          params: [this.myAccount]
        };
      });

      const rawTokenBalances = await multicall(provider, abi['ERC20'], calls);
      const parsedTokenBalances = rawTokenBalances.map(tokenBalance => {
        return new BigNumber(tokenBalance).toJSON();
      });
      return parsedTokenBalances;
    },

    async fetchFarmUserStakedBalances() {
      const calls = farmConfig.map(farm => {
        return {
          address: tokenMasterChefAddr,
          name: 'userInfo',
          params: [farm.pid, this.myAccount]
        };
      });

      const rawStakedBalances = await multicall(
        provider,
        abi['TokenFarmMasterChef'],
        calls
      );
      return rawStakedBalances.map(stakedBalance => {
        return new BigNumber(stakedBalance[0]._hex).toJSON();
      });
    },

    async fetchFarmUserEarnings() {
      const calls = farmConfig.map(farm => {
        return {
          address: tokenMasterChefAddr,
          name: 'pendingReward',
          params: [farm.pid, this.myAccount]
        };
      });

      const rawEarnings = await multicall(
        provider,
        abi['TokenFarmMasterChef'],
        calls
      );
      return rawEarnings.map(earnings => {
        return new BigNumber(earnings).toJSON();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  margin: 27px 0 40px 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
