var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-farm-wrapper"},[(_vm.loading)?_c('UiTable',[_c('ListLoading',{attrs:{"classes":[
        'column-sm text-left hide-sm hide-md hide-lg',
        'flex-auto text-left',
        'column hide-sm hide-md',
        'column hide-sm hide-md',
        'column hide-sm hide-md',
        'column',
        'column hide-sm hide-md hide-lg',
        'column hide-sm hide-md hide-lg'
      ],"height":29}})],1):_c('div',[(!_vm.loading && _vm.formattedPreStakings.length === 0)?_c('div',{staticClass:"py-3 text-center no-token"},[_c('div',[(!_vm._isThemeGame)?_c('img',{attrs:{"src":require("@/assets/icon/no-search.svg")}}):_c('img',{attrs:{"src":require("@/assets/icon/game/no-search.png")}})]),_c('div',{class:("mt-4 " + (_vm._isThemeGame ? 'game' : ''))},[_vm._v(" "+_vm._s(_vm.$t('noTokenFound'))+" ")])]):_c('UiTable',{staticClass:"table-farm"},_vm._l((_vm.formattedPreStakings),function(formattedPreStaking,index){return _c('UiTableTr',{key:index},[[_c('PreStakingTr',{key:formattedPreStaking.details.lpSymbol,attrs:{"poolTokens":formattedPreStaking.details.poolTokens,"poolId":formattedPreStaking.details.pid,"buniEarned":formattedPreStaking.earned.earnings,"buniClaimable":formattedPreStaking.earned.claimable,"stake":formattedPreStaking.myStake,"apy":formattedPreStaking.apr.value,"details":formattedPreStaking.details,"reward":formattedPreStaking.multiplier,"buniPrice":formattedPreStaking.apr.buniPriceUsd,"originalValue":formattedPreStaking.apr.originalValue}})]],2)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }