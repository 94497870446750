// @ts-ignore
import { request } from '@/helpers/subgraph';
import { SubGraphPools, Pools, Pool, Token } from './types';
import * as bmath from './bmath';
import BigNumber from "bignumber.js";

export class POOLS {
  async getAllPublicSwapPools(): Promise<SubGraphPools> {
    console.log('POOL:getAllPublicSwapPools');
    const query = {
      pools: {
        __args: {
          orderBy: 'liquidity',
          orderDirection: 'desc'
        }
      }
    };
    let { pools } = await request('getPools', query);
    pools = pools.filter(pool => pool.publicSwap && new BigNumber(pool.liquidity).comparedTo(100) >= 0);
    return { pools };
  }

  async formatPoolsBigNumber(pools: SubGraphPools): Promise<Pools> {
    const onChainPools: Pools = { pools: [] };

    for (let i = 0; i < pools.pools.length; i++) {
      const tokens: Token[] = [];

      const p: Pool = {
        id: pools.pools[i].id,
        swapFee: bmath.scale(bmath.bnum(pools.pools[i].swapFee), 18),
        totalWeight: bmath.scale(bmath.bnum(pools.pools[i].totalWeight), 18),
        tokens: tokens,
        tokensList: pools.pools[i].tokensList
      };

      pools.pools[i].tokens.forEach(token => {
        const decimals = Number(token.decimals);

        p.tokens.push({
          address: token.address,
          balance: bmath.scale(bmath.bnum(token.balance), decimals),
          decimals: decimals,
          denormWeight: bmath.scale(bmath.bnum(token.denormWeight), 18)
        });
      });
      onChainPools.pools.push(p);
    }

    return onChainPools;
  }
}
