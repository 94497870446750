<template>
  <div
    id="sidebar"
    class="d-flex flex-column bottom-0 top-0  animate"
    :class="
      `${ui.sidebarIsOpen ? 'is-open' : 'is-closed'} ${
        _isThemeGame ? 'game-border-sidebar' : ''
      }`
    "
  >
    <nav class="d-flex flex-column height-full">
      <div class="flex-auto content-sidebar pt-3">
        <div
          v-if="!_isThemeGame & !_isMobile"
          class="flex-auto d-flex flex-items-center pl-4 mb-4"
        >
          <a class="d-block mr-4 icon-toggle" @click="handleToggleSidebar">
            <Icon v-if="ui.sidebarIsOpen" name="menu-left" size="16" />
            <Icon v-else name="menu-left-close" size="16" />
          </a>
          <router-link :to="{ name: 'tradeTokens' }" class="text-blue d-flex">
            <img
              src="~/@/assets/icon/logo-topnav-light.svg"
              class="mr-2 v-align-middle"
            />
          </router-link>
        </div>
        <div v-for="item in menu" :key="item.name" class="item-menu">
          <div v-if="item.child && item.child.length">
            <ParentLink
              :icon="item.icon"
              :label="item.label"
              :child="item.child"
            />
          </div>
          <div v-else @click="changeRouter(item)">
            <ItemLink
              :name="item.name"
              :label="item.label"
              :icon="item.icon"
              :linkSingle="true"
            />
          </div>
        </div>
        <!-- <div class="item-menu">
          <div>
            <a
              href="https://lottery.bunicorn.exchange"
              target="_blank"
              class="link-menu"
            >
              <div class="icon-link-menu">
                <img
                  v-if="_isThemeGame"
                  :src="`/images/games/union.png`"
                  class="icon-game-sidebar"
                />
                <img v-else :src="`/images/union.svg`" />
              </div>
              <span :class="_isThemeGame ? 'game' : ''"> Lottery </span>
            </a>
          </div>
        </div> -->
      </div>
      <div class="footer-slidebar">
        <div class="btn-switch-theme">
          <SwitchTheme />
        </div>
        <FooterSideBar />
      </div>
    </nav>
  </div>
</template>

<script>
import pkg from '@/../package.json';
import { mapActions } from 'vuex';

const commitSha = process.env.VUE_APP_COMMIT_SHA;

export default {
  data() {
    return {
      modalOpen: false,
      pkg,
      commitSha,
      menu: [
        // {
        //   name: 'home',
        //   label: 'home',
        //   icon: 'home'
        // },
        {
          name: 'tradeTokens',
          label: 'trade',
          icon: 'sync'
          // child: [
          //   {
          //     name: 'tradeTokens',
          //     label: 'tokens'
          //   },
          //   {
          //     name: 'tradeStableCoins',
          //     label: 'stableCoins'
          //   }
          // ]
        },
        {
          label: 'liquidity',
          icon: 'style',
          child: [
            {
              name: 'explore',
              label: 'tokens'
            },
            {
              name: 'liquidityKurve',
              label: 'stableCoins'
            }
          ]
        },
        {
          name: 'wallet',
          label: 'myAssets',
          icon: 'union'
        },
        {
          label: 'partnerFarms',
          icon: 'trees',
          child: [
            {
              name: 'hero_arena_farms',
              label: 'Hero Arena'
            },
            {
              name: 'token_play',
              label: 'Token Play'
            }
          ]
        },
        {
          label: 'farms',
          icon: 'trees',
          child: [
            {
              name: 'krypto_monster_farms',
              label: 'Ancient Monster Farm'
            },
            {
              name: 'farms',
              label: 'NFT Farms'
            },
            {
              name: 'pre-staking',
              label: 'Pre-Staking'
            }
          ]
        },
        {
          label: 'NFT Pools',
          icon: 'nft-pools-icon',
          child: [
            {
              name: 'farming_nft_new',
              label: 'Ancient BUNI (New)'
            },
            {
              name: 'farming_nft',
              label: 'Ancient BUNI (Old)'
            },
            {
              name: 'bounty_nft-pool',
              label: 'Bounty NFT'
            }
          ]
        },
        // {
        //   label: 'Game NFTs',
        //   icon: 'games',
        //   child: [
        //     {
        //       name: 'game_nft_trainer_farm_old',
        //       label: 'Trainers - T12'
        //     },
        //     {
        //       name: 'game_nft_bunicorn_farm_old',
        //       label: 'Bunicorns - T12'
        //     },
        //     {
        //       name: 'game_nft_trainer_farm',
        //       label: 'Trainers - T1'
        //     },
        //     {
        //       name: 'game_nft_bunicorn_farm',
        //       label: 'Bunicorns - T1'
        //     },
        //   ]
        // },
        {
          name: 'vesting',
          label: 'vesting',
          icon: 'vesting'
        },
        // {
        //   name: 'bounty_nft',
        //   label: 'Bounty NFT',
        //   icon: 'vesting'
        // },
        {
          name: 'vote',
          label: 'Vote',
          icon: 'up-down'
        }
        // {
        //   name: 'listing',
        //   label: 'listing',
        //   icon: 'listing'
        // }
      ]
    };
  },
  methods: {
    ...mapActions(['hideSidebar', 'toggleSidebar', 'hideSelectLanguage']),
    changeRouter(item) {
      if (this._isMobile && this.$router.currentRoute.name === item.name) {
        this.hideSidebar();
      }
    },
    handleToggleSidebar() {
      this.toggleSidebar();

      if (!this.ui.sidebarIsOpen) {
        this.hideSelectLanguage();
      }
    }
  }
};
</script>

<style lang="scss">
#sidebar {
  position: fixed;
  background: $sidebar-bg-color;
  width: 65px;
  left: 0;
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.15);
  transition: 0.4s;
  border-top-right-radius: 30px;
  z-index: 6;
  ul > li > a {
    font-size: 16px;
    display: block;
    padding: 10px 20px 12px;

    &.active {
      color: $primary-button-text;
    }
  }

  .btn-switch-theme {
    display: none;
  }
  .icon-toggle {
    color: $text-white;
  }

  @media only screen and (max-width: $width-md) {
    left: -264px;
    margin-top: 70px;
    border-radius: 0 !important;

    .btn-switch-theme {
      display: block;
      margin: 15px 0;
    }
  }

  .footer-slidebar {
    padding-top: 10px;
  }

  .content-sidebar {
    overflow-y: auto;
    overflow-x: hidden;

    @media only screen and (max-width: 768px) {
      padding-top: 0 !important;
    }
  }
}

.game-border-sidebar {
  border-top-right-radius: 0 !important;
  background: #444444 !important;
  margin-top: 79px !important;
  width: 62px !important;
}

.game-border-sidebar.is-open {
  width: 190px !important;
}

#sidebar.is-open {
  width: 190px;
  transition: width 0.4s;

  @media only screen and (max-width: $width-xl) {
    left: 0;
    transition: 0.4s;
  }
}
</style>

<style scoped lang="scss">
.link-menu {
  padding: 15px 0;
  color: $text-white;
  font-family: $font-weight-semibold;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 42px;

  &:hover {
    color: $sidebar-text-horver-color;
  }

  img {
    margin: 0 auto;
  }

  .icon-link-menu {
    display: flex;
    min-width: 65px;
  }
}

.link-menu.active,
.link-menu.current {
  color: $text-white;
  position: relative;
  background-color: #f47820;
}

.link-menu.active.game,
.link-menu.current.game {
  background-color: #444444;
}

.link-menu.active {
  &:before {
    content: '';
    height: 100%;
    width: 4px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: $text-white;
  }
}

.link-menu.game {
  height: 50px !important;
}

.icon-game-sidebar {
  height: 35px;
  width: 35px;
}

.game {
  font-size: 12px;
}

.router-link-active {
  color: $text-white !important;
}
</style>
