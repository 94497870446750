<template>
  <UiTable class="p-4 table-details class-format ">
    <div class="d-flex flex-column">
      <div class="mb-3">
        <div v-text="$t('pairName')" class="mb-2 about-title" />
        <div
          v-text="poolAbout.token0Symbol + '-' + poolAbout.token1Symbol"
          class="text about-text"
          :class="`${_isThemeGame ? 'game' : ''}`"
        />
      </div>

      <div class="mb-3">
        <div v-text="$t('pairAddress')" class="mb-2 about-title" />
        <a
          :href="_etherscanLink(poolAbout.pairAddress, 'address')"
          target="_blank"
          class="text link"
          :class="_isThemeGame ? 'game-about-text' : 'about-text'"
        >
          {{ _shortenAddress(poolAbout.pairAddress) }}
          <Icon
            v-if="!_isThemeGame"
            name="external-link"
            size="16"
            class="ml-1 link"
          />
          <img
            v-else
            src="~/@/assets/icon/game/link.png"
            class="icon-link-game"
          />
        </a>
      </div>

      <div class="mb-3">
        <div
          v-text="poolAbout.token0Symbol + ' Address'"
          class="mb-2 about-title"
        />
        <a
          :href="_etherscanLink(poolAbout.token0Address, 'address')"
          target="_blank"
          class="text link"
          :class="_isThemeGame ? 'game-about-text' : 'about-text'"
        >
          {{ _shortenAddress(poolAbout.token0Address) }}
          <Icon
            v-if="!_isThemeGame"
            name="external-link"
            size="16"
            class="ml-1 link"
          />
          <img
            v-else
            src="~/@/assets/icon/game/link.png"
            class="icon-link-game"
          />
        </a>
      </div>

      <div class="mb-3">
        <div
          v-text="poolAbout.token1Symbol + ' Address'"
          class="mb-2 about-title"
        />
        <a
          :href="_etherscanLink(poolAbout.token1Address, 'address')"
          target="_blank"
          class="text link"
          :class="_isThemeGame ? 'game-about-text' : 'about-text'"
        >
          {{ _shortenAddress(poolAbout.token1Address) }}
          <Icon
            v-if="!_isThemeGame"
            name="external-link"
            size="16"
            class="ml-1 link"
          />
          <img
            v-else
            src="~/@/assets/icon/game/link.png"
            class="icon-link-game"
          />
        </a>
      </div>
    </div>

    <div class="d-flex flex-column">
      <div class="mb-3">
        <div v-text="$t('bptSupply')" class="mb-2 about-title" />
        <div
          v-if="poolAbout.totalSupply"
          v-text="poolAbout.totalSupply"
          class="text about-text"
          :class="`${_isThemeGame ? 'game' : ''}`"
        />
      </div>

      <div class="mb-3">
        <div v-text="$t('swapVol')" class="mb-2 about-title" />
        <div
          v-if="poolAbout.totalSwapVolume"
          v-text="'$' + poolAbout.totalSwapVolume"
          class="text about-text"
          :class="`${_isThemeGame ? 'game' : ''}`"
        />
      </div>

      <div class="mb-3">
        <div v-text="$t('swapFeeTotal')" class="mb-2 about-title" />
        <div
          v-if="poolAbout.totalSwapFee"
          v-text="'$' + poolAbout.totalSwapFee"
          class="text about-text"
          :class="`${_isThemeGame ? 'game' : ''}`"
        />
      </div>
    </div>
  </UiTable>
</template>

<script>
export default {
  name: 'PoolDetailAboutKurve',
  props: ['poolAbout']
};
</script>
<style lang="scss" scoped>
@import '../../../vars.scss';
.link {
  color: $primary-button-text;
}
.about-title {
  color: var(--button-color-checkbox);
}

.about-text {
  font-size: 14px;
  line-height: 22px;
  color: var(--text-color);
  font-family: $font-weight-regular;
}

.class-format.table {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 500px !important;
  padding-right: 50% !important;
  background: var(--panel-background);
}

.game-border-table {
  .about-title {
    font-size: 9px !important;
  }

  .about-text {
    font-size: 12px;
  }
}

.game-about-text {
  font-family: $font-forward;
  font-size: 12px;
  line-height: 22px;
  color: var(--text-color);
}

@media only screen and (max-width: 768px) {
  .class-format.table {
    display: inherit !important;
    max-height: 100% !important;
  }

  .link.game-about-text {
    font-size: 10px !important;

    img {
      margin-left: 0 !important;
    }
  }
}
</style>
