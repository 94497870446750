import { render, staticRenderFns } from "./Counter.vue?vue&type=template&id=6010bee1&scoped=true&"
var script = {}
import style0 from "./Counter.vue?vue&type=style&index=0&id=6010bee1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6010bee1",
  null
  
)

export default component.exports