<template>
  <UiTableTr
    :to="{
      name: 'pool-kurve',
      params: { pairId: pairId, shortAddress: this.pool.id }
    }"
  >
    <div
      class="column-sm text-left hide-sm hide-md hide-lg"
      style="min-width:110px!important;"
    >
      {{ _shortenAddress(pool.id) }}
    </div>
    <div class="column flex-auto text-left d-flex align-items-center">
      <div>
        <UiPie :values="values" :colors="colors" :size="34" />
      </div>

      <div class="d-flex list-asset flex-wrap ml-3">
        <div class="column-pool">
          <Icon
            name="bullet"
            size="16"
            :style="`color: ${pool.token0.color} `"
          />
          {{ raito().percentToken0 }}% {{ pool.token0.symbol }}
        </div>
        <div>
          <Icon
            name="bullet"
            size="16"
            :style="`color: ${pool.token1.color}`"
          />
          {{ raito().percentToken1 }}% {{ pool.token1.symbol }}
        </div>
      </div>
    </div>
    <div class="stablecoin-column">
      {{ _num(pool.reserveUSD, 'usd') }}
    </div>
    <div class="stablecoin-column hide-sm hide-md">
      {{ _num(fee, 'usd') }}
    </div>
    <div class="stablecoin-sm-column hide-sm hide-md hide-lg">
      {{ oneYearFL }}%
    </div>
    <div class="stablecoin-column hide-sm hide-md hide-lg">
      {{ _num(getMyLiquidity(liquidityPosition), 'usd') }}
    </div>
    <div class="stablecoin-sm-column hide-sm hide-md hide-lg">
      {{ formattedNum(amp) }}
    </div>
    <div class="stablecoin-column hide-sm hide-md hide-lg">
      {{ _num(volume, 'usd') }}
    </div>
  </UiTableTr>
</template>

<script>
import BigNumber from '@/helpers/bignumber';
import { formattedNum } from '@/utils';

const DEFAULT_MY_LIQUIDITY = '--/--';

export default {
  name: 'Liquiditypool',
  data: () => {
    return {
      formattedNum
    };
  },
  props: {
    pool: {
      type: Object,
      required: true
    },
    liquidityPosition: {
      type: Object,
      required: true
    }
  },
  computed: {
    amp() {
      return new BigNumber(this.pool.amp).dividedBy(10000);
    },
    volume() {
      return this.pool.oneDayVolumeUSD
        ? this.pool.oneDayVolumeUSD
        : this.pool.oneDayVolumeUntracked;
    },
    fee() {
      return this.pool.oneDayFeeUSD
        ? this.pool.oneDayFeeUSD
        : this.pool.oneDayFeeUntracked;
    },
    oneYearFL() {
      return this.getOneYearFL(this.pool.reserveUSD, this.fee).toFixed(2);
    },
    pairId() {
      if (!this.pool) {
        return '';
      }
      return `${this.pool.token0.id.toLowerCase()}_${this.pool.token1.id.toLowerCase()}`;
    },
    values() {
      return [+this.raito().percentToken0, +this.raito().percentToken1];
    },
    colors() {
      return [this.pool.token0.color, this.pool.token1.color];
    }
  },
  methods: {
    raito() {
      if (
        !this.pool ||
        !Number(this.pool.reserve0) ||
        !Number(this.pool.reserve1) ||
        !Number(this.pool.vReserve0) ||
        !Number(this.pool.vReserve1)
      ) {
        return { percentToken0: '50', percentToken1: '50' };
      }
      const percentToken0 = new BigNumber(this.pool.reserve0)
        .dividedBy(this.pool.vReserve0)
        .multipliedBy('100')
        .dividedBy(
          new BigNumber(this.pool.reserve0)
            .dividedBy(this.pool.vReserve0)
            .plus(
              new BigNumber(this.pool.reserve1).dividedBy(this.pool.vReserve1)
            )
        )
        .decimalPlaces(2)
        .toString();
      const percentToken1 = new BigNumber(100)
        .minus(percentToken0)
        .decimalPlaces(2)
        .toString();
      return { percentToken0, percentToken1 };
    },
    getOneYearFL(liquidity, feeOneDay) {
      return !feeOneDay || parseFloat(liquidity) === 0
        ? 0
        : (parseFloat(feeOneDay) * 365 * 100) / parseFloat(liquidity);
    },
    getMyLiquidity(liquidityPosition) {
      if (
        !liquidityPosition ||
        parseFloat(liquidityPosition.liquidityTokenTotalSupply) === 0
      ) {
        return DEFAULT_MY_LIQUIDITY;
      }

      const myLiquidity =
        (parseFloat(liquidityPosition.liquidityTokenBalance) *
          parseFloat(liquidityPosition.reserveUSD)) /
        parseFloat(liquidityPosition.liquidityTokenTotalSupply);

      if (myLiquidity === 0 || Number.isNaN(myLiquidity)) {
        return DEFAULT_MY_LIQUIDITY;
      }

      return myLiquidity.toString();
    }
  }
};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 768px) {
  .list-asset {
    flex-direction: column !important;
    margin-left: 15px;

    .column-pool {
      margin: 0 !important;
    }
  }
}
</style>
