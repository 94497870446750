<template>
  <div :class="!_isThemeGame ? 'content' : 'content-game'">
    <div class="header">
      <div :class="!_isThemeGame ? 'header-text' : 'header-text-game'">
        {{ $t('trade') }}
      </div>
      <Settings :for-kurve="true" />
    </div>
    <div class="swap-input-container d-flex">
      <kurve-swap-input
        type="input"
        :address="tokenIn.address"
        :symbol="tokenIn.symbol"
        :amount="tokenIn.amount"
        :enable-max="enableMaxIn"
        @handle-input-change="handleAmountChange"
        @handle-select-asset="openAssetModal"
        :transactionPending="loading"
        :isCalculating="isCalculating"
      />

      <div class="rate-wrapper">
        <pair-toggle @toggle="toggle" :transactionPending="loading" />
      </div>

      <kurve-swap-input
        type="output"
        :address="tokenOut.address"
        :symbol="tokenOut.symbol"
        :amount="tokenOut.amount"
        @handle-input-change="handleAmountChange"
        @handle-select-asset="openAssetModal"
        :transactionPending="loading"
        :isCalculating="isCalculating"
      />
    </div>

    <div v-if="tradePrice" class="space-between-20 d-flex">
      <div>{{ $t('price') }}</div>
      <div class="d-flex">
        <div class="mr-2">
          {{ showInverted ? tradePrice.price : tradePrice.priceInverted }}
        </div>
        <div @click="setShowInverted">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="17 1 21 5 17 9"></polyline>
            <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
            <polyline points="7 23 3 19 7 15"></polyline>
            <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
          </svg>
        </div>
      </div>
    </div>

    <kurve-swap-button
      class="swap-button"
      :address-in="tokenIn.address"
      :amount-in="tokenIn.amount"
      :address-out="tokenOut.address"
      :transaction-pending="transactionPending"
      :approval-balance="approvalBalance"
      :validation="validation"
      @unlock="handleAppove"
      @swap="handleSwap"
    />

    <div
      v-if="tradeSummary"
      class="swap-info dashed d-flex flex-column mt-4"
      :class="_isThemeGame ? 'game' : ''"
    >
      <div class="space-between-10 d-flex">
        <div>
          {{
            tradeSummary.isExactIn ? $t('minimumReceived') : $t('maximumSold')
          }}
        </div>
        <div v-if="tradeSummary.isExactIn">
          {{ tradeSummary.slippageAdjustedAmounts[Field.OUTPUT] }}
          {{ tradeInfo.tradeCurrencyOut.symbol }}
        </div>
        <div v-else>
          {{ tradeSummary.slippageAdjustedAmounts[Field.INPUT] }}
          {{ tradeInfo.tradeCurrencyIn.symbol }}
        </div>
      </div>
      <div v-if="tradeSummary" class="space-between-10 d-flex">
        <div>{{ $t('priceImpact') }}</div>
        <div>{{ tradeSummary.priceImpact }}</div>
      </div>
      <div v-if="tradeSummary" class="space-between-10 d-flex">
        <div>{{ $t('liquidityProviderFee') }}</div>
        <div>
          {{
            tradeSummary.realizedLPFee
              ? `${tradeSummary.realizedLPFee} ${tradeInfo.tradeCurrencyIn.symbol}`
              : '-'
          }}
        </div>
      </div>
    </div>

    <div
      v-if="tradeInfo.tradePaths && tradeInfo.tradePaths.length > 2"
      class="swap-info dashed d-flex flex-column mt-4"
      :class="_isThemeGame ? 'game' : ''"
    >
      <div>{{ $t('route') }}</div>
      <div class="route-info d-flex" :class="_isThemeGame ? 'game' : ''">
        <div
          class="path-info d-flex align-items-center"
          v-for="(path, index) in tradeInfo.tradePaths"
          :key="path.address"
        >
          <Token
            :address="path.address"
            :symbol="path.symbol"
            :size="_isMobile ? '20' : '30'"
            class="mr-0 mr-md-3"
          />
          <div class="token-symbol">
            {{ _shorten(path.symbol, 28) }}
          </div>
          <svg
            v-if="index !== tradeInfo.tradePaths.length - 1"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#C3C5CB"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </div>
      </div>
    </div>

    <portal to="modal">
      <modal-asset-selector
        :open="openModal"
        :hidden="[tokenIn.address, tokenOut.address]"
        :forKurve="true"
        @select="handleTokenSelect"
        @close="handleCloseModal"
      />
      <modal-settings
        v-if="isSettingsModalForKurve"
        :open="isSettingsModalOpen"
        @slippage-change="handleSlippageChange"
      />
    </portal>
  </div>
</template>

<script>
import {
  SwapValidation,
  validateNumberInput,
  ValidationError
} from '@/utils/validation';
import Settings from '@/components/swap/Settings.vue';
import KurveSwapInput from '@/components/Kurve/KurveSwapInput.vue';
import KurveSwapButton from '@/components/swap/KurveSwapButton.vue';
import ModalAssetSelector from '@/components/swap/modals/ModalAssetSelector.vue';
import ModalSettings from '@/components/swap/modals/ModalSettings.vue';
import BigNumber from '@/helpers/bignumber';
import config from '@/config';
import { BNB_KEY, scale, isAddress } from '@/utils/helpers';
import {
  Field,
  getKurveAllowances,
  kurveSwap,
  getBestTradeInfo
} from '@/helpers/kurve';
import { mapActions } from 'vuex';
import { ApprovalState } from '@/utils';
import dayjs from 'dayjs';
import Storage from '@/utils/storage';
import { MaxUint256 } from '@ethersproject/constants';
import { getAddress } from '@ethersproject/address';
import PairToggle from '@/components/swap/PairToggle';
import { cloneDeep } from 'lodash';

let prepareSwapInterval = null;
export default {
  name: 'SwapKurve',
  components: {
    KurveSwapInput,
    KurveSwapButton,
    Settings,
    ModalAssetSelector,
    ModalSettings,
    PairToggle
  },
  data() {
    return {
      openModal: false,
      provider: null,
      isExactIn: true,
      assetInput: null,
      tokenIn: {
        address: '',
        symbol: '',
        amount: ''
      },
      tokenOut: {
        address: '',
        symbol: '',
        amount: ''
      },
      enableMaxIn: true,
      transactionPending: false,
      approvalBalance: '',
      showInverted: false,
      tradeInfo: {
        tradeType: null,
        pairs: [],
        tradePaths: [],
        tradeAmountIn: '',
        tradeCurrencyIn: null,
        tradeAmountOut: '',
        tradeCurrencyOut: null
      },
      hasEnoughLiquidity: false,
      tradePrice: null,
      tradeSummary: null,
      priceImpactSeverity: null,
      Field,
      amountInput: '',
      loading: false,
      isCalculating: ''
    };
  },
  computed: {
    isSettingsModalOpen() {
      return this.$store.state.ui.modal.settings.isOpen;
    },
    isSettingsModalForKurve() {
      return this.$store.state.ui.modal.settings.forKurve;
    },
    account() {
      const { connector, address } = this.$store.state.account;
      if (!connector || !connector.id || !address) {
        return '';
      }
      return address;
    },
    validation() {
      // Wrong network
      const { chainId } = this.$store.state.account;

      if (config.chainId != chainId) {
        return SwapValidation.WRONG_NETWORK;
      }

      if (!this.tokenIn.address || !this.tokenOut.address) {
        return SwapValidation.SELECT_TOKEN;
      }

      // Invalid input
      const inputError = validateNumberInput(this.activeInput);
      if (inputError === ValidationError.EMPTY) {
        return SwapValidation.EMPTY_INPUT;
      }
      if (inputError !== ValidationError.NONE) {
        return SwapValidation.INVALID_INPUT;
      }

      // No account
      if (!this.account) {
        return SwapValidation.NO_ACCOUNT;
      }
      // Insufficient balance
      const { balances } = this.$store.state.account;
      const metadata = this.$store.getters['assets/metadata'];
      const assetInBalance = balances[this.tokenIn.address];
      const assetInMetadata = metadata[this.tokenIn.address];
      if (!assetInMetadata) {
        return SwapValidation.INSUFFICIENT_BALANCE;
      }
      const assetInDecimals = assetInMetadata.decimals;
      const assetInAmountRaw = new BigNumber(this.tokenIn.amount);
      const assetInAmount = scale(assetInAmountRaw, assetInDecimals);
      if (!assetInBalance || assetInAmount.gt(assetInBalance)) {
        return SwapValidation.INSUFFICIENT_BALANCE;
      }

      if (this.priceImpactSeverity > 3) {
        return SwapValidation.PRICE_IMPACT_TOO_HIGH;
      }

      if (!this.hasEnoughLiquidity) {
        return SwapValidation.NO_SWAPS;
      }

      return SwapValidation.NONE;
    },
    activeInput() {
      if (this.isExactIn) {
        return this.tokenIn.amount;
      }
      return this.tokenOut.amount;
    }
  },
  async mounted() {
    const { assetIn, assetOut } = this.getInitialPair();

    Object.assign(this.tokenIn, assetIn);
    Object.assign(this.tokenOut, assetOut);

    this.provider = await this.$store.getters['account/provider'];
  },
  // watch: {
  //   amountInput() {
  //     this.amountInput = this.handleValue(this.tokenIn.amount);
  //   }
  // },
  methods: {
    // handleValue(value) {
    //   value = this._validInputNumber(value);
    //   return value;
    // },
    ...mapActions(['approve']),
    openAssetModal(type) {
      this.assetInput = type;
      this.openModal = true;
    },
    handleCloseModal() {
      this.openModal = false;
    },
    handleTokenSelect(assetAddress) {
      const assets = this.$store.getters['assets/metadata'];
      if (this.assetInput === 'input') {
        this.tokenIn.address = assetAddress;
        this.tokenIn.symbol = assets[assetAddress].symbol;
      }
      if (this.assetInput === 'output') {
        this.tokenOut.address = assetAddress;
        this.tokenOut.symbol = assets[assetAddress].symbol;
      }
      this.assetInput = null;
      this.prepareSwap();
    },
    handleAmountChange({ value, type }) {
      this.isCalculating = type;
      if (type === 'input') {
        this.isExactIn = true;
        this.tokenIn.amount =
          value && !isNaN(value) ? new BigNumber(value).toString() : '';
        if (!value || isNaN(value)) {
          this.tokenOut.amount = '';
          this.tradePrice = null;
          this.tradeSummary = null;
          this.tradeInfo = {};
        }
      }
      if (type === 'output') {
        this.isExactIn = false;
        this.tokenOut.amount =
          value && !isNaN(value) ? new BigNumber(value).toString() : '';
        if (!value || isNaN(value)) {
          this.tokenIn.amount = '';
          this.tradePrice = null;
          this.tradeSummary = null;
          this.tradeInfo = {};
        }
      }
      this.prepareSwap();
    },
    handleSlippageChange() {
      this.prepareSwap();
    },
    setShowInverted() {
      this.showInverted = !this.showInverted;
    },
    async prepareSwap() {
      this.transactionPending = true;
      // if (
      //   this.validation !== SwapValidation.NONE &&
      //   this.validation !== SwapValidation.NO_SWAPS
      // ) {
      //   this.transactionPending = false;
      //   this.isCalculating = '';
      //   console.log('prepareSwap return');
      //   return;
      // }

      if (prepareSwapInterval) {
        clearTimeout(prepareSwapInterval);
        prepareSwapInterval = null;
      }
      prepareSwapInterval = setTimeout(async () => {
        await this.loadTradeInfo();
        clearTimeout(prepareSwapInterval);
        prepareSwapInterval = null;
      }, 500);
    },
    async loadTradeInfo() {
      this.tradeInfo = {};
      this.tradePrice = null;
      this.tradeSummary = null;
      this.priceImpactSeverity = null;

      if (
        this.tokenIn.address !== BNB_KEY &&
        this.tokenIn.address !== config.addresses.weth
      ) {
        const allowances = await getKurveAllowances(
          this.provider,
          this.account,
          config.kurve.addresses.routerV2,
          [this.tokenIn.address],
          [this.tokenIn.amount]
        );
        if (allowances[0].state === ApprovalState.APPROVED) {
          this.approvalBalance = allowances[0].approvalBalance;
        }
      } else {
        this.approvalBalance = MaxUint256.toString();
      }

      // load v2Trade
      const assets = this.$store.getters['assets/metadata'];
      const slippage = new BigNumber(Storage.getSlippage())
        .multipliedBy(10000)
        .decimalPlaces(0)
        .toNumber();
      const tradeInfo = await getBestTradeInfo(
        this.isExactIn,
        this.tokenIn.address,
        this.tokenOut.address,
        this.tokenIn.amount,
        this.tokenOut.amount,
        assets,
        this.provider,
        slippage
      );

      if (tradeInfo) {
        const {
          tradeType,
          tradePairs,
          tradePaths,
          tradeCurrencyIn,
          tradeAmountIn,
          tradeCurrencyOut,
          tradeAmountOut,
          calculatedAmount,
          tradePrice,
          tradeSummary,
          priceImpactSeverity
        } = tradeInfo;

        if (this.isExactIn) {
          this.tokenOut.amount = calculatedAmount.toString();
        } else {
          this.tokenIn.amount = calculatedAmount.toString();
        }

        this.tradeInfo.tradeType = tradeType;
        this.tradeInfo.pairs = tradePairs;
        this.tradeInfo.tradePaths = tradePaths;
        this.tradeInfo.tradeCurrencyIn = tradeCurrencyIn;
        this.tradeInfo.tradeAmountIn = tradeAmountIn;
        this.tradeInfo.tradeCurrencyOut = tradeCurrencyOut;
        this.tradeInfo.tradeAmountOut = tradeAmountOut;

        this.tradePrice = tradePrice;
        this.tradeSummary = tradeSummary;
        this.priceImpactSeverity = priceImpactSeverity;
        this.hasEnoughLiquidity = true;
      } else {
        if (this.isExactIn) {
          this.tokenOut.amount = '0';
        } else {
          this.tokenIn.amount = '0';
        }

        this.tradeInfo.tradeType = null;
        this.tradeInfo.pairs = [];
        this.tradeInfo.tradePaths = [];
        this.tradeInfo.tradeCurrencyIn = null;
        this.tradeInfo.tradeAmountIn = '';
        this.tradeInfo.tradeCurrencyOut = null;
        this.tradeInfo.tradeAmountOut = '';

        this.tradePrice = null;
        this.tradeSummary = null;
        this.priceImpactSeverity = null;
        this.hasEnoughLiquidity = false;
      }

      this.transactionPending = false;
      this.isCalculating = '';
    },
    async handleAppove() {
      this.transactionPending = true;
      this.loading = true;
      try {
        await this.approve({
          token: this.tokenIn.address,
          spender: config.kurve.addresses.routerV2
        });
        await this.prepareSwap();
      } catch (e) {
        // do nothing
      }
      this.transactionPending = false;
      this.loading = false;
    },
    async handleSwap() {
      if (this.validation !== SwapValidation.NONE) {
        this.reset();
        return;
      }

      this.transactionPending = true;
      this.loading = true;
      const assets = this.$store.getters['assets/metadata'];
      const slippage = new BigNumber(Storage.getSlippage())
        .multipliedBy(10000)
        .decimalPlaces(0)
        .toNumber();
      const deadline = Storage.getDeadline();
      const text = 'transactionTitles.swap';

      try {
        const tx = await kurveSwap(
          // this.trade,
          this.tradeInfo.pairs,
          this.tradeInfo.tradeCurrencyIn.address ||
            this.tradeInfo.tradeCurrencyIn.symbol.toLowerCase(),
          this.tradeInfo.tradeCurrencyOut.address ||
            this.tradeInfo.tradeCurrencyOut.symbol.toLowerCase(),
          this.tradeInfo.tradeAmountIn,
          this.tradeInfo.tradeAmountOut,
          this.tradeInfo.tradeType,
          slippage,
          this.account,
          new BigNumber(dayjs().valueOf())
            .dividedBy(1000)
            .plus(deadline * 60)
            .decimalPlaces(0),
          assets,
          this.provider
        );

        tx.symbolIn = this.tradeInfo.tradeCurrencyIn.symbol;
        tx.symbolOut = this.tradeInfo.tradeCurrencyOut.symbol;
        tx.assetInAmount = this.tradeInfo.tradeAmountIn;
        tx.assetOutAmount = this.tradeInfo.tradeAmountOut;

        await this.handleTransaction(tx, text);

        this.$store.dispatch('account/fetchAssets', [
          this.tokenIn.address,
          this.tokenOut.address
        ]);
        this.reset();
      } catch (e) {
        console.error('swap stablecoin error:', e);
        this.transactionPending = false;
      }
      this.reset();
      this.loading = false;
    },
    async handleTransaction(transaction, text) {
      await this.$store.dispatch('transactions/handleTransaction', {
        transaction,
        titleKey: text,
        titleParams: {
          symbolIn: transaction.symbolIn,
          symbolOut: transaction.symbolOut,
          symbol: transaction.symbol
        }
      });

      this.transactionPending = false;
    },
    reset() {
      this.tokenIn.amount = '';
      this.tokenOut.amount = '';

      this.enableMaxIn = true;
      this.showInverted = false;
      this.approvalBalance = '';

      this.tradeInfo.tradeType = null;
      this.tradeInfo.pairs = [];
      this.tradeInfo.tradePaths = [];
      this.tradeInfo.tradeCurrencyIn = null;
      this.tradeInfo.tradeAmountIn = '';
      this.tradeInfo.tradeCurrencyOut = null;
      this.tradeInfo.tradeAmountOut = '';

      this.tradePrice = null;
      this.tradeSummary = null;
      this.priceImpactSeverity = null;
      this.hasEnoughLiquidity = false;
    },
    isWrapPair(assetIn, assetOut) {
      if (assetIn === BNB_KEY && assetOut === config.addresses.weth) {
        return true;
      }
      if (assetOut === BNB_KEY && assetIn === config.addresses.weth) {
        return true;
      }
      return false;
    },
    getInitialPair() {
      const pair = Storage.getKurvePair(config.chainId);
      let addressIn =
        this.$router.currentRoute.params.assetIn || pair.inputAsset;
      let addressOut =
        this.$router.currentRoute.params.assetOut || pair.outputAsset;

      if (isAddress(addressIn)) {
        addressIn = getAddress(addressIn);
      }

      if (isAddress(addressOut)) {
        addressOut = getAddress(addressOut);
      }

      const assets = this.$store.getters['assets/metadata'];
      const assetInSymbol = assets[addressIn].symbol;
      const assetOutSymbol = assets[addressOut].symbol;

      return {
        assetIn: {
          address: addressIn,
          symbol: assetInSymbol
        },
        assetOut: {
          address: addressOut,
          symbol: assetOutSymbol
        }
      };
    },
    toggle() {
      const currentTokenIn = cloneDeep(this.tokenIn);
      const currentTokenOut = cloneDeep(this.tokenOut);

      this.tokenIn = currentTokenOut;
      this.tokenOut = currentTokenIn;
      this.isExactIn = !this.isExactIn;
      this.isCalculating = this.isCalculating === 'input' ? 'output' : 'input';
      this.prepareSwap();
    }
  }
};
</script>

<style lang="scss" scoped>
.flex .wrapper .content-game {
  padding: 40px;
  padding-top: 20px !important;
  font-family: $font-forward;
  @media only screen and (min-width: 768px) {
    padding-bottom: 40px !important;
  }
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .header-text-game {
    font-size: 20px;
    color: var(--text-color-liquidity);
  }
}
</style>

<style lang="scss" scoped>
.swap-input-container {
  flex-direction: column;
}
.arrow-down {
  margin: 20px 0px 10px;
  align-items: center;
  justify-content: center;
}
.space-between-10 {
  margin-top: 10px;
  justify-content: space-between;
}
.space-between-20 {
  margin-top: 20px;
  justify-content: space-between;
}
.dashed {
  border: 1px dashed #ccc;
}
.swap-info {
  padding: 10px 15px 20px 15px;
  border-radius: 10px;
}

.dashed.game {
  border: 3px dashed #ccc;
  font-size: 11px;
}

.rate-wrapper {
  margin: 8px 0 5px 0;
  display: flex;
  justify-content: center;
}
.icon-wrapper {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--swap-btn-background);
  border-radius: 50%;
  cursor: pointer;
}
.route-info {
  margin-top: 15px;
  flex-wrap: wrap !important;
  .circle {
    margin-right: 5px !important;
  }

  .path-info {
    margin-bottom: 10px;
  }
}

.route-info.game {
  flex-wrap: wrap !important;

  .path-info {
    margin-bottom: 10px;
  }

  .token-symbol {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 768px) {
  .route-info.game {
    flex-wrap: wrap !important;

    .path-info {
      margin-bottom: 10px;
    }

    .token-symbol {
      font-size: 9px !important;
    }
  }

  .route-info {
    .token-symbol {
      font-size: 13px !important;
    }
  }
}
</style>
