var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[_c('div',{staticClass:"flex",class:_vm._isThemeGame && 'flex-game'},[(_vm._isThemeGame)?_c('div',{class:_vm._isThemeGame && 'swap-game'},[_c('div',{staticClass:"cloud-big"},[_c('img',{attrs:{"src":require("@/assets/icon/game/cloud-big.png")}})]),_c('div',{staticClass:"rabbit-animation"},[_c('img',{attrs:{"src":require("@/assets/icon/buni_loading.gif")}})]),_c('div',{staticClass:"cloud-big-2"},[_c('img',{attrs:{"src":require("@/assets/icon/game/cloud-big.png")}})]),_c('div',{staticClass:"cloud-small"},[_c('img',{attrs:{"src":require("@/assets/icon/game/cloud-small.png")}})]),_c('div',{staticClass:"cloud-small-2"},[_c('img',{attrs:{"src":require("@/assets/icon/game/cloud-small.png")}})]),_c('div',{staticClass:"house-big"},[_c('img',{attrs:{"src":require("@/assets/icon/game/house-big.png")}})]),_c('div',{staticClass:"house-max"},[_c('img',{attrs:{"src":require("@/assets/icon/game/house-max.png")}})]),_c('div',{staticClass:"house-small"},[_c('img',{attrs:{"src":require("@/assets/icon/game/house-small.png")}})])]):_vm._e(),_c('div',{staticClass:"wrapper swap-wrapper is-rounded"},[_c('div',{staticClass:"tabs",class:_vm._isThemeGame && 'game-border'},[_c('b-nav',{staticClass:"tab-nav",class:!_vm._isThemeGame ? '' : 'game',attrs:{"tabs":""}},[_c('b-nav-item',{class:[
                !_vm._isThemeGame ? 'tab-item' : 'tab-item-game',
                _vm.$route.name === 'tradeTokens' && _vm._isThemeGame
                  ? 'game-border-tabnav-active'
                  : '',
                _vm.$route.name !== 'tradeTokens' && _vm._isThemeGame
                  ? 'game-border-tabnav-notactive'
                  : ''
              ],attrs:{"to":"tokens","active":_vm.$route.name === 'tradeTokens'}},[(_vm._isThemeGame)?_c('img',{staticClass:"rabbit",class:_vm.$route.name === 'tradeTokens' && 'visibility',attrs:{"src":require("@/assets/icon/game/rabbit.png")}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('tokens'))+" ")]),_c('b-nav-item',{class:[
                !_vm._isThemeGame ? 'tab-item' : 'tab-item-game',
                _vm.$route.name === 'tradeStableCoins' && _vm._isThemeGame
                  ? 'game-border-tabnav-active'
                  : '',
                _vm.$route.name !== 'tradeStableCoins' && _vm._isThemeGame
                  ? 'game-border-tabnav-notactive'
                  : ''
              ],attrs:{"to":"stablecoins","active":_vm.$route.name === 'tradeStableCoins'}},[(_vm._isThemeGame)?_c('img',{staticClass:"rabbit",class:_vm.$route.name === 'tradeStableCoins' && 'visibility',attrs:{"src":require("@/assets/icon/game/rabbit.png")}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('stableCoins'))+" ")]),_c('div',{class:[
                !_vm._isThemeGame ? 'tab-item' : 'tab-item-game',
                _vm._isThemeGame ? 'game-border-tabnav-notactive' : ''
              ]},[_c('a',{staticClass:"nav-link",class:_vm._isThemeGame ? 'link-nav-game' : '',attrs:{"href":"https://www.binance.org/en/bridge?utm_source=BuniSwap","target":"_blank"}},[_c('img',{staticClass:"rabbit",class:_vm.$route.name === 'bridge' && 'visibility',attrs:{"src":require("@/assets/icon/game/rabbit.png")}}),_vm._v(" "+_vm._s(_vm.$t('bridge'))+" ")])])],1),_c('div',{staticClass:"tab-content",class:!_vm._isThemeGame ? '' : 'tab-content-game'},[_c('div',{staticClass:"p-2",class:['tab-pane', { active: _vm.$route.name === 'tradeTokens' }]},[_c('swap-kalancer')],1),_c('div',{staticClass:"p-2",class:[
                'tab-pane',
                { active: _vm.$route.name === 'tradeStableCoins' }
              ]},[_c('swap-kurve')],1)])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }