import { render, staticRenderFns } from "./Slippage.vue?vue&type=template&id=631cccfc&scoped=true&"
import script from "./Slippage.vue?vue&type=script&lang=js&"
export * from "./Slippage.vue?vue&type=script&lang=js&"
import style0 from "./Slippage.vue?vue&type=style&index=0&id=631cccfc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "631cccfc",
  null
  
)

export default component.exports