var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pool)?_c('div',{staticClass:"d-block text-center text-md-left d-md-flex col-md-4 main-info"},[_c('div',{class:("'pt-1' " + (_vm._isThemeGame && 'game-pb'))},[(_vm.forKurve || (!_vm.forKurve && _vm.pool.isWhitelisted()))?_c('Token',{staticClass:"mr-0 mr-md-3",attrs:{"custom":true,"address":_vm.forKurve ? _vm.pool.shortenAddress : _vm.pool.getBptAddress(),"size":"60"}}):_c('Pie',{staticClass:"mr-0 mr-md-3",attrs:{"tokens":_vm.pool.metadata.tokens,"size":"66"}})],1),_c('div',[_c('a',{staticClass:"color",attrs:{"href":_vm._etherscanLink(
          _vm.forKurve ? _vm.pool.shortenAddress : _vm.pool.getBptAddress(),
          'token'
        ),"target":"_blank"}},[(
          _vm.forKurve || (!_vm.forKurve && (_vm.pool.config.name || _vm.pool.metadata.name))
        )?_c('span',{class:_vm._isThemeGame ? 'game-metadata-name' : 'metadata-name',domProps:{"textContent":_vm._s(
          _vm.forKurve
            ? _vm.pool.name
            : _vm._shorten(_vm.pool.config.name || _vm.pool.metadata.name, 24)
        )}}):_c('span',[_vm._v(" "+_vm._s(_vm.$t('pool'))+" "+_vm._s(_vm._shortenAddress(_vm.pool.address))+" ")]),(
          _vm.forKurve ||
            (!_vm.forKurve && (_vm.pool.config.symbol || _vm.pool.metadata.symbol))
        )?_c('span',{staticClass:"ml-1 ",class:_vm._isThemeGame ? 'game-metadata-name' : 'metadata-name',domProps:{"textContent":_vm._s(
          _vm.forKurve
            ? ("(" + (_vm.pool.symbol) + ")")
            : ("(" + (_vm._shorten(_vm.pool.config.symbol || _vm.pool.metadata.symbol)) + ")")
        )}}):_vm._e(),(!_vm._isThemeGame)?_c('Icon',{staticClass:"ml-1 mr-2",class:_vm._isThemeGame ? 'game-metadata-name' : 'metadata-name',attrs:{"name":"external-link","size":"16"}}):_c('img',{staticClass:"icon-link-game",attrs:{"src":require("@/assets/icon/game/link.png")}})],1),(!_vm.forKurve && !_vm.pool.metadata.finalized)?_c('UiLabel',{class:_vm._isThemeGame ? 'game-border-smart-pool' : 'pool-label',domProps:{"textContent":_vm._s(_vm.pool.getTypeStr())}}):_vm._e(),_c('div',{class:_vm._isThemeGame ? 'game-price' : 'price',domProps:{"textContent":_vm._s(_vm._num(_vm.forKurve ? _vm.pool.lpPrice : _vm.pool.getBptPrice(), 'usd-long'))}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }