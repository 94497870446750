<template>
  <div class="select-item">
    <div
      @click="open = !open"
      :class="
        `selected-item ${open ? 'show' : ''} ${
          _isThemeGame ? 'game game-border-btn-search' : ''
        } ${typeSelect !== selectedForm ? 'filter' : ''}`
      "
    >
      <div>
        {{ selectedCategoryName }}
      </div>
      <div class="icon-drop">
        <Icon v-if="!_isThemeGame" name="menu-down" size="6" />
        <img
          v-else
          src="~@/assets/icon/game/arrow-down-icon.png"
          style="width: 30px;"
        />
      </div>
    </div>
    <div
      class="items"
      :class="
        `${open ? 'show' : ''}  list-item-${optionSelect.length} ${
          _isThemeGame && open ? 'game-border-btn-search' : ''
        }`
      "
    >
      <div
        v-for="item in optionSelect"
        :key="item.categoryId"
        class="item"
        :class="_isThemeGame ? 'game' : ''"
        @click="selectItem(item)"
      >
        {{ $t(`${item.title}`) }}
      </div>
    </div>
  </div>
</template>

<script>
import rf from '@/requests/RequestFactory';
export default {
  data() {
    return {
      selected: 'Please select',
      open: false,
      selectedForm: 'selectedForm',
      categories: [],
    };
  },
  props: {
    typeSelect: {
      type: String
    }
  },
  computed: {
    optionSelect() {
      return this.categories.filter(item => item.categoryId !== this.selected);
    },
    selectedCategoryName() {
      const category = this.categories.find(item => item.categoryId === this.selected);
      if (!category) {
        return this.selected;
      }
      return category.title;
    }
  },
  methods: {
    reset() {
      this.selected = 'Please select';
    },
    selectItem(item) {
      this.open = false;
      this.selected = item.categoryId;
      this.$emit('select-items', item.categoryId);
    },
    async fetchCategories() {
      this.categories = await rf.getRequest('VoteRequest').getCategories();
    }
  },
  created() {
    this.fetchCategories();
    window.addEventListener('click', e => {
      if (!this.$el.contains(e.target)) {
        this.open = false;
      }
    });
  }
};
</script>

<style scoped lang="scss">
.select-item {
  position: relative;
  color: var(--secondary-text-color);
  font-family: Lato-Regular;
  @for $i from 1 through 10 {
    $heightChild: $i * 35px;
    .items.show.list-item-#{$i} {
      height: $heightChild;
      background: var(--bg-select);
    }
  }
}
.selected-item.game-border-btn-search {
  border-radius: 0 !important;
  height: 30px;
  padding-right: 0 !important;
}
.items.game-border-btn-search {
  width: 92%;
  border-radius: 0 !important;
}
.selected-item {
  font-family: Lato-Regular;
  height: 48px;
  padding: 0 16px;
  display: flex;
  font-weight: 500;
  align-items: center;
  border: 1px solid var(--color-border-select);
  justify-content: space-between;
  box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.075);
  cursor: pointer;
  div {
    color: unset;
  }
  .icon-drop {
    transition: transform 0.4s;
    color: var(--color-arrow-down);
  }
}
.selected-item.show {
  border-radius: 18px 18px 0 0;
  transition: border-radius 0.15s ease 0s;
  .icon-drop {
    transform: rotate(-180deg);
    transition: transform 0.4s;
  }
}

.selected-item.filter {
  height: 35px;
  border-radius: 25px;
  width: 255px;
}
.items {
  font-family: Lato-Regular;
  background-color: var(--bg-select);
  position: absolute;
  height: 0;
  overflow: hidden;
  width: 100%;
  transition: height 0.1s;
}
.item {
  height: 35px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--secondary-text-color);
  background-color: var(--bg-select);
  &:hover {
    color: $color-primary;
  }
}
.game-border-btn-search {
  .item {
    font-size: 11px !important;
  }
}
.items.show {
  border: 1px solid var(--color-border-select);
  border-top: 0;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.25);
  background: var(--bg-select);
  z-index: 999;
}

@media only screen and (max-width: 768px) {
  .selected-item {
    width: 100% !important;
  }
}
</style>
