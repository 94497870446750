<template>
  <Page>
    <Container>
      <Title
        :title="$t('Pre-Staking')"
        :subTitle="!isEnded ? $t('subTitlePreStaking') : $t('endedPrestaking')"
        class="prestaking-title"
      />
      <MessageWarningPreStaking class="mt-4 message warning-prestaking" />
      <PreStakingFilterActions
        @switchTab="switchTab"
        @stakedToggle="onStakeChange"
        @sort="onSort"
        v-on:inputSearch="onInputSearch"
      />
    </Container>
    <PreStakingTable
      :farmType="farmType"
      :isStaked="isStaked"
      :isSortedBy="isSortedBy"
      :query="query"
    />
  </Page>
</template>

<script>
import PreStakingFilterActions from '@/components/PreStaking/PreStakingFilterActions';
import PreStakingTable from '@/components/PreStaking/PreStakingTable';

export default {
  metaInfo: { title: 'Farms' },
  data() {
    return {
      farmType: 'live',
      isStaked: false,
      isSortedBy: '',
      query: '',
      endTimePrestaking: new Date('2021-07-28T14:00:00.000Z')
    };
  },
  components: {
    PreStakingFilterActions,
    PreStakingTable
  },
  computed: {
    isEnded() {
      return this.$store.state.prestaking.isEnded;
    }
  },
  methods: {
    switchTab(value) {
      this.farmType = value;
    },
    onStakeChange(isStaked) {
      this.isStaked = isStaked;
    },
    onSort(value) {
      this.isSortedBy = value;
    },
    onInputSearch(value) {
      this.query = value;
    },
    tick() {
      const currentTime = new Date();
      const distance = Math.max(this.endTimePrestaking - currentTime, 0);
      if (distance === 0) {
        clearInterval(this.timer);
        this.$store.dispatch('prestaking/setEndPrestaking', true);
      }
    }
  },
  mounted() {
    this.tick();
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
};
</script>

<style lang="scss" scoped>
.header-prestaking {
  justify-content: space-between;
  align-items: flex-start;
  // flex-wrap: wrap;
  width: 100%;
}
.total-value {
  font-size: 20px;
  line-height: 24px;
  font-family: $font-bold;
  color: var(--text-color);
}
.label-total-value {
  color: var(--text-btn);
  font-size: 13px;
  line-height: 20px;
  font-family: $font-bold;
}
.total-value.game {
  font-size: 16px;
}
.label-total-value.game {
  font-size: 10px;
}
@media only screen and (max-width: 768px) {
  .total-value.game {
    font-size: 14px;
  }
}
.tvl.text-right {
  padding-top: 10px;
}
.warning-prestaking {
  margin-bottom: 20px !important;
}
</style>
