<template>
  <div>
    <div class="d-flex flex-items-center px-4 px-md-0 mb-3">
      <h4
        v-text="$t('rights')"
        :class="`flex-auto text-size ${_isThemeGame ? 'game' : ''}`"
      />
    </div>
    <div class="list-checkbox" :class="`${_isThemeGame ? 'game' : ''}`">
      <div class="d-flex flex-column">
        <UiCheckbox
          :checked="rights.canPauseSwapping"
          @change="$emit('toggle-right', 'canPauseSwapping')"
        >
          <span v-text="$t('canPauseSwapping')" class="ml-2 check-box" />
        </UiCheckbox>
        <UiCheckbox
          :checked="rights.canChangeSwapFee"
          @change="$emit('toggle-right', 'canChangeSwapFee')"
        >
          <span v-text="$t('canChangeSwapFee')" class="ml-2 check-box" />
        </UiCheckbox>
        <UiCheckbox
          :checked="rights.canChangeWeights"
          @change="$emit('toggle-right', 'canChangeWeights')"
        >
          <span v-text="$t('canChangeWeights')" class="ml-2 check-box" />
        </UiCheckbox>
        <div v-if="rights.canChangeWeights" class="ml-custom">
          <span
            v-text="$t('minimumUpdatePeriod')"
            class="color-text-checkbox mr-2"
          />
          <input
            class="cards__input-number input text-right check-box-input mb-3"
            :class="
              _isThemeGame
                ? 'game-btn-input-2 game-placeholder-crp-input game-pool-input'
                : 'pool-input'
            "
            type="text"
            v-model="minimumWeight"
            :min="0"
            :step="10"
            @input="handleInputChangeWeight($event.target.value)"
            placeholder="10"
          />
          <div
            class="d-flex flex-items-center p-2 warning-box message"
            :class="_isThemeGame && 'game-border-message-warning'"
          >
            <Icon name="info" size="22" class="mr-4 icon-format" />
            {{ `${$t('percentRange', { min: divisor, max: maxPercentage })}` }}
          </div>
        </div>
        <!-- <UiCheckbox
          :checked="rights.canAddRemoveTokens"
          @change="$emit('toggle-right', 'canAddRemoveTokens')"
        >
          <span v-text="$t('canAddRemoveTokens')" class="ml-2 check-box" />
        </UiCheckbox>
        <div v-if="rights.canAddRemoveTokens" class="ml-custom">
          <span
            v-text="$t('addTokenTimelock')"
            class="color-text-checkbox mr-2"
          />
          <input
            class="cards__input-number input text-right check-box mb-3"
            :class="
              _isThemeGame
                ? 'game-btn-input-2  game-placeholder-crp-input game-pool-input'
                : 'pool-input'
            "
            type="text"
            :min="0"
            :step="10"
            v-model="timeLock"
            @input="handleInputChangeTimeLock($event.target.value)"
            placeholder="10"
          />
        </div> -->
        <UiCheckbox
          :checked="rights.canWhitelistLPs"
          @change="$emit('toggle-right', 'canWhitelistLPs')"
        >
          <span
            v-text="$t('canWhitelistLPs')"
            class="ml-2 check-box tooltipped tooltipped-n"
            :aria-label="$t('whitelistExplanation')"
          />
        </UiCheckbox>
      </div>
    </div>
  </div>
</template>

<script>
import { getMaxTotalWeight, getMaxPercentage } from '@/helpers/weights';

// Testing Guide
//
// With all rights unchecked, divisor = 2, maxPercentage = 98 (100 - 2)
// Check canChangeWeights; then divisor = 4, maxPercentage = 96 (100 - 4)
// Uncheck canChangeWeights; then divisor/maxPercentage return to 2/98
//

export default {
  props: [
    'rights',
    'minimumWeightChangeBlockPeriod'
    // 'addTokenTimeLockInBlocks'
  ],
  data() {
    return {
      minimumWeight: this.minimumWeightChangeBlockPeriod
      // timeLock: this.addTokenTimeLockInBlocks
    };
  },
  computed: {
    // The "resolution" of the pool depends on its type
    // A shared pool, or a smart pool with fixed weights, can have the full range of denorm weights (1-49)
    // A smart pool with canChangeWeights enabled can get "stuck" during gradual weight changes if the total
    //   is too close to the max. So we use the core pool max of 50 for shared pools (2% resolution), but
    // limit the total denorm to 25 (4% resolution) for smart pools.
    //
    // Display this range to the user as a percentage
    //   e.g.; 4% - 96%, when the max total is 25 (denorm range 1-24)
    //
    // The input on the main component will show an error outside this range; the intent here is to explain it to the user
    // Denorm weights are calculated as <input percentage> / <divisor>
    //
    // For instance, for a shared pool with percentages set to 90/10, the divisor would be 100 / 50 = 2, so denorms are 45/5
    //   For a smart pool with canChangeWeights, the max total is 25, divisor is 100 / 25 = 4, so denorms are 22.5/2.5
    divisor() {
      return 100 / getMaxTotalWeight(this.isSharedOrLocked());
    },
    maxPercentage() {
      return getMaxPercentage(this.isSharedOrLocked());
    }
  },
  methods: {
    // Since this form means we're already editing a smart pool, we only have to check for weight permission
    isSharedOrLocked() {
      return !this.rights.canChangeWeights;
    },
    handleInputChangeWeight(value) {
      this.minimumWeight = value && this._validInputNumber(value);
      this.$emit('change-weight-period', this.minimumWeight);
    }
    // handleInputChangeTimeLock(value) {
    //   this.timeLock = value && this._validInputNumber(value);
    //   this.$emit('change-add-timelock', this.timeLock);
    // }
  }
};
</script>

<style scoped lang="scss">
.pool-input {
  width: 110px;

  @media only screen and (max-width: 767px) {
    width: 80px;
  }
}

.game-pool-input {
  width: 90px;

  @media only screen and (max-width: 767px) {
    width: 70px;
  }
}

.message {
  @media only screen and (max-width: 767px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.pool-input-responsive {
  @media only screen and (max-width: 767px) {
    margin-left: 25px;
    width: 110px !important;
  }
}

.list-checkbox {
  @media only screen and (max-width: 768px) {
    margin-left: 1.5rem;
  }
}

.text-size {
  font-family: $font-bold;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: var(--text-color);
}

.text-size.game {
  font-size: 14px !important;

  @media only screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}

.check-box {
  color: var(--text-color);
}

.check-box-input {
  color: var(--text-color);
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
  }
}

.color-text-checkbox {
  color: var(--text-color-checkbox);
  margin-left: 33px;
}

.warning-box {
  margin-left: 33px;
  margin-right: 33px;
}

.game-border-message-warning {
  font-family: $font-forward;
  font-size: 10px !important;
}

.game-btn-input-2 {
  font-family: $font-forward;
  font-size: 10px !important;
  border-radius: 0 !important;
  border: none;
  line-height: 20px !important;
  min-height: 15px;
  margin-left: 20px;
}

.game-placeholder {
  font-size: 10px !important;
}

@media only screen and (max-width: 768px) {
  .warning-box,
  .color-text-checkbox {
    margin-left: 0px !important;
  }
  .icon-format {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .ml-custom {
    margin-left: 30px !important;
    line-height: 30px;
  }

  .list-checkbox.game {
    .check-box,
    .color-text-checkbox {
      font-size: 10px !important;
    }
  }
}

.color-text-input {
  color: var(--text-color-input);
}

.icon-format {
  margin-left: 20px;
}
</style>
