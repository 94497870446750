<template>
  <Page>
    <div class="flex" :class="_isThemeGame && 'flex-game'">
      <div v-if="_isThemeGame" :class="_isThemeGame && 'swap-game'">
        <div class="cloud-big">
          <img src="~/@/assets/icon/game/cloud-big.png" />
        </div>
        <div class="rabbit-animation">
          <img src="~/@/assets/icon/buni_loading.gif" />
        </div>
        <div class="cloud-big-2">
          <img src="~/@/assets/icon/game/cloud-big.png" />
        </div>
        <div class="cloud-small">
          <img src="~/@/assets/icon/game/cloud-small.png" />
        </div>
        <div class="cloud-small-2">
          <img src="~/@/assets/icon/game/cloud-small.png" />
        </div>
        <div class="house-big">
          <img src="~/@/assets/icon/game/house-big.png" />
        </div>
        <div class="house-max">
          <img src="~/@/assets/icon/game/house-max.png" />
        </div>
        <div class="house-small">
          <img src="~/@/assets/icon/game/house-small.png" />
        </div>
      </div>
      <div class="wrapper swap-wrapper is-rounded">
<!--        <div-->
<!--          class="total-value-locked"-->
<!--          :class="_isThemeGame ? 'game-total-value-locked' : ''"-->
<!--        >-->
<!--          {{ $t('totalValueLocked') }}-->
<!--          {{ _roundedCurrency($store.state.summaries.totalLiquidity) }}-->
<!--        </div>-->
        <div class="tabs" :class="_isThemeGame && 'game-border'">
          <b-nav tabs class="tab-nav" :class="!_isThemeGame ? '' : 'game'">
            <b-nav-item
              to="tokens"
              :active="$route.name === 'tradeTokens'"
              v-bind:class="[
                !_isThemeGame ? 'tab-item' : 'tab-item-game',
                $route.name === 'tradeTokens' && _isThemeGame
                  ? 'game-border-tabnav-active'
                  : '',
                $route.name !== 'tradeTokens' && _isThemeGame
                  ? 'game-border-tabnav-notactive'
                  : ''
              ]"
            >
              <img
                v-if="_isThemeGame"
                src="~/@/assets/icon/game/rabbit.png"
                class="rabbit"
                :class="$route.name === 'tradeTokens' && 'visibility'"
              />
              {{ $t('tokens') }}
            </b-nav-item>
            <b-nav-item
              to="stablecoins"
              :active="$route.name === 'tradeStableCoins'"
              v-bind:class="[
                !_isThemeGame ? 'tab-item' : 'tab-item-game',
                $route.name === 'tradeStableCoins' && _isThemeGame
                  ? 'game-border-tabnav-active'
                  : '',
                $route.name !== 'tradeStableCoins' && _isThemeGame
                  ? 'game-border-tabnav-notactive'
                  : ''
              ]"
            >
              <img
                v-if="_isThemeGame"
                src="~/@/assets/icon/game/rabbit.png"
                class="rabbit"
                :class="$route.name === 'tradeStableCoins' && 'visibility'"
              />
              {{ $t('stableCoins') }}
            </b-nav-item>
            <div
              v-bind:class="[
                !_isThemeGame ? 'tab-item' : 'tab-item-game',
                _isThemeGame ? 'game-border-tabnav-notactive' : ''
              ]"
            >
              <a
                href="https://www.binance.org/en/bridge?utm_source=BuniSwap"
                target="_blank"
                class="nav-link"
                :class="_isThemeGame ? 'link-nav-game' : ''"
              >
                <img
                  src="~/@/assets/icon/game/rabbit.png"
                  class="rabbit"
                  :class="$route.name === 'bridge' && 'visibility'"
                />
                {{ $t('bridge') }}
              </a>
            </div>
          </b-nav>
          <div
            class="tab-content"
            :class="!_isThemeGame ? '' : 'tab-content-game'"
          >
            <div
              :class="['tab-pane', { active: $route.name === 'tradeTokens' }]"
              class="p-2"
            >
              <swap-kalancer></swap-kalancer>
            </div>
            <div
              :class="[
                'tab-pane',
                { active: $route.name === 'tradeStableCoins' }
              ]"
              class="p-2"
            >
              <swap-kurve></swap-kurve>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import SwapKalancer from '@/views/Swap/SwapKalancer';
import SwapKurve from '@/views/Swap/SwapKurve';
import Page from '@/components/Page';

export default {
  name: 'Swap',
  metaInfo: {
    title: 'Trade'
  },
  components: { Page, SwapKalancer, SwapKurve }
};
</script>

<style lang="scss" scoped>
.swap-game {
  background-image: url('../../assets/icon/game/bg-game.png');
  background-size: cover;
  position: fixed;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.swap-wrapper {
  z-index: 0;
  overflow: visible !important;
  padding: 3px !important;
  margin-bottom: 0px !important;
}
.total-value-locked {
  text-align: center;
  font-size: 22px;
  line-height: 26px;
  margin: 30px;
  color: var(--total-value);
}
.game-total-value-locked {
  margin: 30px 0 90px 0 !important;
  line-height: 40px;
}
.tabs.game-border {
  margin-top: 70px !important;
  padding: 0 !important;
  background: #d8d8d8 !important;
}

.rabbit {
  bottom: 100%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}
.visibility {
  visibility: visible;
}
.flex {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 70px);
  margin-top: -50px;
  .wrapper {
    background: none;
    width: 480px;
    overflow: hidden;
    margin: 45px 0 160px 0;
  }
}

#view.view-game {
  padding-top: 0 !important;
}

.flex-game {
  min-height: calc(100vh - 100px);
  align-items: center;
  margin-top: 0 !important;

  .wrapper {
    background: none;
    width: 480px;
    overflow: hidden;
    margin: 0 0 160px 0;
    height: 100%;
  }
}
.nav-link.active {
  border-color: transparent;
  position: relative;
  &:before {
    content: '';
    height: 4px;
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: $color-primary;
  }
}

.tab-nav {
  background: none !important;
  justify-content: center;
}

.tab-item {
  text-align: center;
  .nav-link {
    color: var(--type-label);
    border: 0;
    display: flex;
    justify-content: center;
    padding: 20px 15px !important;

    &:hover {
      background-color: inherit !important;
    }
  }
}

.tab-item-game {
  .nav-link {
    padding: 10px !important;
    color: $text-white !important;
    text-align: center;

    &:hover {
      background: $game-btn-hover-color !important;
    }
  }
  .nav-link.active {
    border-color: transparent;
    position: relative;
    &:before {
      content: '';
      height: 0px;
      width: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: none;
    }

    &:hover {
      background: $text-white !important;
    }
  }
}

.tab-item {
  .link-nav-game {
    padding: 16px 20px 10px !important;
  }
}

.game {
  font-size: 16px !important;
}

.game-border-tabnav-active {
  background: white;
  margin-bottom: 10px;
  width: 30.5%;
}

.game-border-tabnav-notactive {
  background: $color-primary;
  width: 30.8%;
  margin-bottom: 10px;
}

.tab-content {
  border-radius: 25px;
  background: var(--panel-background);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
}

.tab-content-game {
  border-radius: 0;
}

@media only screen and (max-width: 768px) {
  .tab-item-game {
    .nav-link {
      padding: 5px !important;
      &:hover {
        background: $color-primary !important;
      }
    }
    a {
      font-size: 10px !important;
    }
  }

  .tab-item {
    .link-nav-game {
      font-size: 12px !important;
      padding: 11px 20px !important;
    }
  }

  .game-border-tabnav-active {
    width: 28%;
  }

  .game-border-tabnav-notactive {
    width: 30%;
    .nav-link {
      padding-top: 8px !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .flex {
    .wrapper {
      width: 335px;
      margin-top: 35px;
    }
  }

  .tabs.game-border {
    margin-top: 50px !important;
  }
}

.cloud-big {
  position: absolute;
  bottom: 0;
  -webkit-animation: movecloudbig 35s linear infinite;
  -moz-animation: movecloudbig 35s linear infinite;
  -o-animation: movecloudbig 35s linear infinite;
  z-index: 4;
}

.rabbit-animation {
  position: absolute;
  top: 40%;
  transform: translateX(-50%);
  -webkit-animation: moverabbit 40s forwards infinite;
  -moz-animation: moverabbit 40s linear infinite;
  -o-animation: moverabbit 40s linear infinite;
  z-index: 4;

  img {
    width: 140px;
  }
}

.cloud-big-2 {
  position: absolute;
  bottom: 0;
  -webkit-animation: movecloudbig 45s linear infinite;
  -moz-animation: movecloudbig 45s linear infinite;
  -o-animation: movecloudbig 45s linear infinite;
  z-index: 4;
  img {
    width: 600px;
  }
}

.cloud-small {
  position: absolute;
  top: 15%;
  -webkit-animation: moveclouds 35s linear infinite;
  -moz-animation: moveclouds 35s linear infinite;
  -o-animation: moveclouds 35s linear infinite;
  z-index: 1;
  img {
    width: 250px;
  }
}

.cloud-small-2 {
  position: absolute;
  top: 40%;
  transform: translateX(-50%);
  -webkit-animation: moveclouds 20s linear infinite;
  -moz-animation: moveclouds 20s linear infinite;
  -o-animation: moveclouds 20s linear infinite;
  z-index: 3;
}

.house-big {
  position: absolute;
  top: 25%;
  right: 5%;
  animation: aniDropUp 2s infinite ease;
  z-index: 2;
}
.house-max {
  position: absolute;
  top: 50%;
  left: 30%;
  animation: aniDropDown 2s infinite ease;
  z-index: 3;
}

.house-small {
  position: absolute;
  top: 20%;
  left: 20%;
  animation: aniDropUp 2s infinite ease;
}

@-webkit-keyframes aniDropDown {
  0% {
    transform: translateY(-25px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-25px);
  }
}

@-webkit-keyframes aniDropUp {
  0% {
    transform: translateY(25px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(25px);
  }
}

@-webkit-keyframes moveclouds {
  0% {
    margin-left: -300px;
  }
  100% {
    margin-left: calc(100vw + 300px);
  }
}

@-moz-keyframes moveclouds {
  0% {
    margin-left: -300px;
  }
  100% {
    margin-left: calc(100vw + 300px);
  }
}

@-o-keyframes moveclouds {
  0% {
    margin-left: -300px;
  }
  100% {
    margin-left: calc(100vw + 300px);
  }
}

@-webkit-keyframes movecloudbig {
  0% {
    margin-left: -1000px;
  }
  100% {
    margin-left: calc(100vw + 300px);
  }
}

@-moz-keyframes movecloudbig {
  0% {
    margin-left: -1000px;
  }
  100% {
    margin-left: calc(100vw + 300px);
  }
}

@-o-keyframes movecloudbig {
  0% {
    margin-left: -1000px;
  }
  100% {
    margin-left: calc(100vw + 300px);
  }
}

@-webkit-keyframes moverabbit {
  0% {
    margin-left: -150px;
  }
  50% {
    margin-left: -150px;
  }
  100% {
    margin-left: calc(100vw + 300px);
  }
}

@-moz-keyframes moverabbit {
  0% {
    margin-left: -300px;
  }
  60% {
    margin-left: -300px;
  }
  100% {
    margin-left: calc(100vw + 300px);
  }
}

@-o-keyframes moverabbit {
  0% {
    margin-left: -300px;
  }
  60% {
    margin-left: -300px;
  }
  100% {
    margin-left: calc(100vw + 300px);
  }
}
</style>
