<template>
  <div class="table-farm-wrapper">
    <UiTable v-if="loading">
      <ListLoading
        :classes="[
          'column-sm text-left hide-sm hide-md hide-lg',
          'flex-auto text-left',
          'column hide-sm hide-md',
          'column hide-sm hide-md',
          'column hide-sm hide-md',
          'column',
          'column hide-sm hide-md hide-lg',
          'column hide-sm hide-md hide-lg'
        ]"
        :height="29"
      />
    </UiTable>
    <div v-else>
      <div
        v-if="!loading && formattedPreStakings.length === 0"
        class="py-3 text-center no-token"
      >
        <div>
          <img v-if="!_isThemeGame" src="~@/assets/icon/no-search.svg" />
          <img v-else src="~@/assets/icon/game/no-search.png" />
        </div>
        <div :class="`mt-4 ${_isThemeGame ? 'game' : ''}`">
          {{ $t('noTokenFound') }}
        </div>
      </div>
      <UiTable v-else class="table-farm">
        <UiTableTr
          v-for="(formattedPreStaking, index) in formattedPreStakings"
          :key="index"
        >
          <template>
            <PreStakingTr
              :key="formattedPreStaking.details.lpSymbol"
              :poolTokens="formattedPreStaking.details.poolTokens"
              :poolId="formattedPreStaking.details.pid"
              :buniEarned="formattedPreStaking.earned.earnings"
              :buniClaimable="formattedPreStaking.earned.claimable"
              :stake="formattedPreStaking.myStake"
              :apy="formattedPreStaking.apr.value"
              :details="formattedPreStaking.details"
              :reward="formattedPreStaking.multiplier"
              :buniPrice="formattedPreStaking.apr.buniPriceUsd"
              :originalValue="formattedPreStaking.apr.originalValue"
            />
          </template>
        </UiTableTr>
      </UiTable>
    </div>
  </div>
</template>
<script>
import PreStakingTr from '@/components/PreStaking/PreStakingTr.vue';
import BigNumber from 'bignumber.js';
import { orderBy } from 'lodash';
import { getBalanceNumber } from '@/helpers/farm';
import { useIntervalFn } from '@vueuse/core';
import config from '../../config';
import get from 'lodash/get';
import { getBuniPrice } from '@/helpers/price';
const BUNI_PER_BLOCK = new BigNumber(config.buniPerBlock);
const BLOCK_TIME = config.blockTime;
export const BLOCKS_PER_YEAR = new BigNumber((60 / BLOCK_TIME) * 60 * 24 * 365); // 10512000
export default {
  name: 'PreStakingTable',
  components: {
    PreStakingTr
  },
  props: {
    farmType: {
      type: String,
      require: true,
      default: 'live'
    },
    isStaked: {
      type: Boolean,
      require: true,
      default: false
    },
    isSortedBy: {
      type: String,
      require: false,
      default: ''
    },
    query: {
      type: String,
      require: false,
      default: ''
    }
  },
  data() {
    return {
      prices: [],
      buniPriceUsd: 0,
      pauseIntervalFn: undefined
    };
  },
  computed: {
    loading() {
      return !this.$store.state.prestaking.isInit;
    },
    wrongNetwork() {
      return this.$store.state.account.wrongNetwork;
    },

    formattedPreStakings() {
      const farmsStaked = this.getFarmsList(this.$store.state.prestaking.data);
      const data = this.sortFarms(farmsStaked).map((farm, index) => {
        const { quoteTokenAdresses, quoteTokenSymbol, tokenAddresses } = farm;

        const buniPriceUsd = this.buniPriceUsd;
        const myAddress = this.$store.state.account.address;

        let myLiquidity = 0;
        if (!this.wrongNetwork && farm.userData && myAddress) {
          const stakedBalance = getBalanceNumber(new BigNumber(farm.userData.stakedBalance));
          const lpPrice = farm.lpPriceUsd;
          myLiquidity = new BigNumber(stakedBalance).times(lpPrice).toString();
        }

        farm.myLiquidity = myLiquidity;
        const row = {
          apr: {
            value:
              farm.apy &&
              farm.apy.toLocaleString('en-US', { maximumFractionDigits: 2 }),
            multiplier: farm.multiplier,
            quoteTokenAdresses,
            quoteTokenSymbol,
            tokenAddresses,
            buniPriceUsd,
            originalValue: farm.apy
          },
          earned: {
            earnings:
              !this.wrongNetwork && farm.userData && myAddress
                ? getBalanceNumber(new BigNumber(farm.userData.earnings))
                : null,
            claimable:
              !this.wrongNetwork && farm.userData && myAddress
                ? getBalanceNumber(new BigNumber(farm.userData.claimable))
                : null,
            pid: index
          },
          myStake: farm.myStake,
          myLiquidity: myLiquidity,
          details: farm
        };

        return row;
      });

      let filterQuery = data;
      if (this.query) {
        filterQuery = data.filter(farm =>
          farm.details.lpSymbol.toLowerCase().includes(this.query.toLowerCase())
        );
      }
      const filterActive =
        this.farmType == 'live'
          ? filterQuery.filter(farm => farm.multiplier !== '0X')
          : filterQuery.filter(farm => farm.multiplier === '0X');
      const filterStake = this.isStaked
        ? filterActive.filter(
            farm =>
              get(farm, 'details.userData.stakedBalance') &&
              new BigNumber(farm.details.userData.stakedBalance).isGreaterThan(
                0
              )
          )
        : filterActive;
      return this.sortFarms(filterStake);
    }
  },
  beforeDestroy() {
    if (!this.pauseIntervalFn) {
      return;
    }
    this.pauseIntervalFn();
  },
  async mounted() {
    this.buniPriceUsd = await getBuniPrice();
    const { pause, resume, isActive } = useIntervalFn(async () => {
      console.log(
        `==== Fetching state pre-staking data  with interval ${BLOCK_TIME *
          60}s at:`,
        new Date().toLocaleString()
      );
      await this.$store.dispatch('prestaking/fetchPreStakingData');

      if (!this.$store.state.account.address) {
        return;
      }

      console.log(
        `==== Fetching state pre-staking user data with interval ${BLOCK_TIME *
          60}s at:`,
        new Date().toLocaleString()
      );
      await this.$store.dispatch('prestaking/fetchPreStakingUserData');
    }, 15 * 60 * 1000); // update after 60s

    this.pauseIntervalFn = pause;
  },
  methods: {
    sortFarms(farms) {
      switch (this.isSortedBy) {
        case 'apr':
          return farms.sort(
            (poolA, poolB) =>
              ((poolB.apr && poolB.apr.originalValue) || 0) -
              ((poolA.apr && poolA.apr.originalValue) || 0)
          );
        case 'multiplier':
          return orderBy(
            farms,
            farm =>
              farm.multiplier ? Number(farm.multiplier.slice(0, -1)) : 0,
            'desc'
          );
        case 'earned':
          return orderBy(
            farms,
            farm => (farm.earned ? farm.earned.earnings : 0),
            'desc'
          );
        case 'liquidity':
          return orderBy(farms, farm => Number(farm.myStake), 'desc');

        case 'myLiquidity':
          return orderBy(farms, farm => Number(farm.myLiquidity), 'desc');
        default:
          return farms;
      }
    },
    getFarmApy(rewardAmount, rewardsDuration, buniPriceUsd, poolLiquidityUsd) {
      return 0;
      // try {
      //   const yearlyCakeRewardAllocation = new BigNumber(
      //     getBalanceNumber(rewardAmount)
      //   )
      //     .div(rewardsDuration)
      //     .times(60 * 60 * 24 * 365);
      //
      //
      //   const apy = yearlyCakeRewardAllocation
      //     .times(buniPriceUsd)
      //     .div(poolLiquidityUsd)
      //     .times(100);
      //
      //   return apy.isNaN() || !apy.isFinite() ? 0 : apy.toNumber();
      // } catch (e) {
      //   console.error(e);
      //   return 0;
      // }
    },
    getFarmsList(farmsToDisplay) {
      const farmList = farmsToDisplay.map(farm => {
        const totalLiquidity = getBalanceNumber(farm.myStake);

        const apy = this.getFarmApy(
          farm.preStaking.rewardAmount,
          farm.preStaking.rewardsDuration,
          this.buniPriceUsd,
          totalLiquidity
        );

        return { ...farm, apy, myStake: totalLiquidity };
      });
      return farmList;
    }
  }
};
</script>
<style lang="scss" scoped>
.no-token {
  color: var(--color-text-not-found);
  font-size: 20px;
  font-family: $font-bold;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
