<template>
  <Page>
    <Container>
      <NavBarVote />
      <div class="d-flex">
        <div class="proposal-card">
          <div class="proposal-card" v-if="proposals.length > 0">
            <ProposalCard
              v-for="item in proposals"
              :key="item.id"
              :typeProposalCard="'related'"
              :proposalInfo="item"
              @REFRESH_DATA="fetchProposals(params)"
            />
          </div>
          <div class="proposal-card" v-else>
            <div class="no-result-wrapper">
              {{ $t('noVoteResult') }}
            </div>
          </div>
        </div>
      </div>
    </Container>
  </Page>
</template>

<script>
import ProposalCard from '@/components/Vote/ProposalCard.vue';
import Page from '@/components/Page.vue';
import Container from '@/components/Container.vue';
import NavBarVote from '@/components/Vote/NavBarVote.vue';
import rf from '@/requests/RequestFactory';
import { normalizeData } from '@/helpers/utils';
export default {
  data() {
    return {
      proposals: []
    };
  },
  computed: {
    myAddress() {
      return this.$store.state.account.address;
    },
    params() {
      return {
        userAddress: this.myAddress
      };
    }
  },
  watch: {
    async params() {
      await this.fetchProposals(this.params);
    }
  },
  components: {
    ProposalCard,
    Page,
    Container,
    NavBarVote
  },
  methods: {
    async fetchProposals(params) {
      const res = await rf
        .getRequest('VoteRequest')
        .getRelatedProposal(normalizeData(params));
      console.log('RELATED', res);
      this.proposals = res.docs || [];
    }
  },
  async mounted() {
    await this.fetchProposals(this.params);
  }
};
</script>

<style lang="scss" scoped>
.proposal-card {
  width: 100%;
  .no-result-wrapper {
    margin-top: 24px;
    width: 100%;
    max-width: 100%;
    background: var(--panel-background);
    border-radius: 10px;
    padding: 29px 24px;
    color: var(--type-label);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  .proposal-user {
    background: var(--panel-background);
    border-radius: 10px;
    margin-top: 30px;
    font-family: Lato-Regular;

    .title-table-user {
      padding: 0 24px;
      font-size: 13px;
      font-weight: 600;
      line-height: 40px;
      color: var(--text-color-liquidity);
      opacity: 0.4;
      border-bottom: 1px solid rgba(204, 204, 204, 0.3);
    }

    .content-down {
      color: var(--text-color-liquidity);
      border-bottom: 1px solid rgba(204, 204, 204, 0.3);
      padding: 16px 24px;
      .content-tx {
        font-size: 15px;
        font-weight: 400;
      }

      .content-down-vote {
        font-size: 15px;
        font-weight: 500;

        span {
          color: red;
        }
      }
    }

    .content-up {
      color: var(--text-color-liquidity);
      padding: 16px 24px;

      .content-tx {
        font-size: 15px;
        font-weight: 400;
      }

      .content-down-up {
        font-size: 15px;
        font-weight: 500;

        span {
          color: #4fba6f;
        }
      }
    }

    .proposal-type {
      margin-top: 8px;
      font-family: $font-weight-regular;
      font-weight: normal;
      font-size: 12px;
      color: #f47820;
      background: rgba(244, 120, 32, 0.1);
      border-radius: 4px;
      padding: 5px 8px;
      width: fit-content;
    }
  }
}

.submit-vote {
  width: 30%;
}

@media only screen and (max-width: 768px) {
  .proposal-card {
    width: 100%;
  }
}
</style>
