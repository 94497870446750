<template>
  <div
    class="proposal-card-wrapper"
    v-if="proposalInfo"
    :class="_isThemeGame ? 'game-border-tabnav-notactive' : ''"
  >
    <div class="up-down-wrapper">
      <div
        :class="[
          !isProcessedPropose ? 'tooltipped tooltipped-n' : '',
          _isThemeGame ? 'game-tooltip' : ''
        ]"
        :aria-label="getTooltipContent()"
      >
        <div
          class="vote-wrapper up-vote"
          :class="[
            loadVoteUp ? 'loading' : '',
            isVoteDisabled() ? 'disabled' : ''
          ]"
          @click.stop="vote('up')"
        >
          <div v-if="!loadVoteUp">
            <div class="vote-icon">
              <img src="@/assets/icon/up-vote.png" />
            </div>
            <div class="vote-number" :class="_isThemeGame ? 'game' : ''">
              {{ proposalInfo.weightUpvotedNumer | formatAmount }}
            </div>
          </div>
          <UiLoading v-if="loadVoteUp" />
        </div>
      </div>
      <div
        :class="[
          !isProcessedPropose ? 'tooltipped tooltipped-n' : '',
          _isThemeGame ? 'game-tooltip' : ''
        ]"
        :aria-label="getTooltipContent()"
      >
        <div
          class="vote-wrapper down-vote"
          :class="[
            loadVoteDown ? 'loading' : '',
            isVoteDisabled() ? 'disabled' : ''
          ]"
          @click.stop="vote('down')"
        >
          <div v-if="!loadVoteDown">
            <div class="vote-icon">
              <img src="@/assets/icon/down-vote.png" />
            </div>
            <div class="vote-number" :class="_isThemeGame ? 'game' : ''">
              {{ proposalInfo.weighDownvotedNumber | formatAmount }}
            </div>
          </div>
          <UiLoading v-if="loadVoteDown" />
        </div>
      </div>
    </div>
    <div class="content-wrapper">
      <div>
        <div class="proposal-title" @click="goToDetail">
          <div class="title-text" :class="_isThemeGame ? 'game' : ''">
            {{ proposalInfo.title }}
          </div>
          <div class="time-posted" :class="_isThemeGame ? 'game' : ''">
            Posted: {{ timeSince(proposalInfo.createdAt * 1000) }}
          </div>
        </div>
        <div class="proposal-type" :class="_isThemeGame ? 'game' : ''">
          {{ getCategoryName(proposalInfo.categoryId) }}
        </div>
        <div class="proposal-description" :class="_isThemeGame ? 'game' : ''">
          <p v-html="(proposalInfo.details || '').replaceAll('\n', '<br>')" />
        </div>
      </div>
      <div v-if="typeProposalCard !== 'related'" class="vote-static-wrapper">
        <div class="static-group" :class="_isThemeGame ? 'game' : ''">
          <span class="static-title">Total Voted: </span>
          <span class="static-number">{{ getTotalVotes() }} Votes</span>
        </div>
      </div>
      <div v-if="typeProposalCard === 'related'" class="action-wrapper">
        <div
          v-if="proposalInfo.action"
          class="label"
          :class="_isThemeGame ? 'game' : ''"
        >
          {{ proposalInfo.action }}
        </div>
        <button
          v-if="proposalInfo.status === 1"
          class="btn-submit"
          :class="{ disable: !checkSubmit }"
          :disabled="!checkSubmit"
          @click="createPropose"
        >
          <div class="text-submit" v-if="!isLoading">
            Publish
          </div>
          <UiLoading v-if="isLoading" />
        </button>
      </div>

      <div
        class="vote-note-wrapper"
        v-if="typeProposalCard === processedProposals"
      >
        <div class="proposal-title">
          <div class="title-note-text">Resolved</div>
          <div class="time-note-posted">
            Processing time：{{ timeSince(proposalInfo.processedTime * 1000) }}
          </div>
        </div>
        <div class="description-note-posted">
          {{ proposalInfo.memo }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  downVote,
  minBuniHoldToPropose,
  minBuniHoldToVote,
  submitProposeToOnChain,
  upVote
} from '@/utils/vote-utils';
import rf from '@/requests/RequestFactory';
import config from '@/config';
import { normalizeBalance } from '@/helpers/utils';
import { scrollToTop, timeSince } from '@/helpers/utils';
import BigNumber from 'bignumber.js';
import { Contract } from '@ethersproject/contracts';
import abi from '@/helpers/abi';
import store from '@/store';

export default {
  data() {
    return {
      processedProposals: 'processedProposals',
      loadVoteUp: false,
      loadVoteDown: false,
      categories: [],
      voteFee: 0,
      text: '',
      isLoading: false,
      minHoldBuniToPropose: 5000,
      minHoldBuniToVote: 3000,
      isOwnerTrainerOrBunicorn: false,
      status: [4, 5]
    };
  },
  computed: {
    account() {
      const { connector, address } = this.$store.state.account;
      if (!connector || !connector.id || !address) {
        return '';
      }
      return address;
    },
    checkSubmit() {
      if (this.isLoading || !this.isEnoughBuniToPropose) {
        return false;
      }
      return true;
    },
    isEnoughBuniToPropose() {
      const buniBalance = this.$store.state.account.balances[
        config.addresses.buni
      ];
      return (
        new BigNumber(buniBalance).comparedTo(this.minHoldBuniToPropose) >= 0
      );
    },
    isEnoughBuniToVote() {
      const buniBalance = this.$store.state.account.balances[
        config.addresses.buni
      ];
      return (
        new BigNumber(buniBalance)
          .dividedBy(1e18)
          .comparedTo(this.minHoldBuniToVote) >= 0
      );
    },
    isEnoughBuniFeeToPropose() {
      const balance = this.$store.state.account.balances[config.addresses.buni];
      const amount = normalizeBalance(balance, 18).toNumber();
      return new BigNumber(amount).comparedTo(this.voteFee) >= 0;
    },
    isProcessedPropose() {
      if (this.status.includes(this.proposalInfo.status)) {
        return true;
      }
      return false;
    }
  },
  props: ['proposalInfo', 'typeProposalCard'],
  methods: {
    timeSince,
    scrollToTop,
    goToDetail() {
      if (this.$route.name === 'vote-detail') {
        return null;
      }
      this.$router.push({
        name: 'vote-detail',
        params: { id: this.proposalInfo.proposalId }
      });
      this.scrollToTop();
    },
    async createPropose() {
      this.isLoading = true;
      if (!this.account) {
        this.$store.dispatch('openAccountModal');
        this.isLoading = false;
        return;
      }
      const userProvider = await this.$store.getters['account/provider'];
      try {
        await submitProposeToOnChain(
          userProvider,
          this.proposalInfo.proposalId,
          this.proposalInfo.categoryId
        );
        this.$emit('REFRESH_DATA');
      } catch (error) {
        this.$store.dispatch('transactions/handleTransactionError', error);
      } finally {
        this.isLoading = false;
      }
    },

    getTotalVotes() {
      return (
        this.proposalInfo.upvotedNumber + this.proposalInfo.downvotedNumber
      );
    },
    isVoteDisabled() {
      return (
        this.loadVoteDown ||
        this.loadVoteUp ||
        (!this.isEnoughBuniToVote && !this.isOwnerTrainerOrBunicorn) ||
        this.isProcessedPropose
      );
    },
    async vote(action) {
      if (this.isVoteDisabled()) {
        return;
      }
      if (!this.account) {
        return store.dispatch('notify', [
          'error',
          {
            text: 'Connect To A Wallet',
            type: 'error'
          }
        ]);
      }
      if (Number(this.proposalInfo.categoryId) !== 2 && this.checkBalances()) {
        return store.dispatch('notify', [
          'warning',
          {
            text: `To vote, you need to have more than ${this.voteFee} BUNI in your account.`,
            type: 'warning'
          }
        ]);
      }
      try {
        switch (action) {
          case 'up':
            this.loadVoteUp = true;
            await upVote(
              this.proposalInfo.proposalId,
              this.proposalInfo.categoryId
            );
            break;
          case 'down':
            this.loadVoteDown = true;
            await downVote(this.proposalInfo.proposalId, this.proposalInfo.categoryId);
            break;
          default:
            return;
        }
      } catch (e) {
        this.$store.dispatch('transactions/handleTransactionError', e);
        this.loadVoteUp = false;
        this.loadVoteDown = false;
      }
      this.loadVoteUp = false;
      this.loadVoteDown = false;
      this.$emit('REFRESH_DATA');
    },
    async fetchCategories() {
      this.categories = await rf.getRequest('VoteRequest').getCategories();
    },
    getCategoryName(categoryId) {
      const category = this.categories.find(
        item => item.categoryId === categoryId
      );
      if (!category) {
        return '';
      }
      return category.title;
    },
    async getBuniToVote() {
      this.voteFee = await minBuniHoldToVote();
    },
    checkBalances() {
      const balance = this.$store.state.account.balances[config.addresses.buni];
      const amount = normalizeBalance(balance || '0', 18).toNumber();
      if (this.voteFee > amount) {
        return true;
      }
      return false;
    },
    async fetchOwnerTrainerOrBunicornInfo() {
      if (!this.account) {
        return;
      }
      const provider = await this.$store.getters['account/provider'];
      const trainerContract = new Contract(
        config.addresses.trainerContract,
        abi['VBuni'],
        provider
      );
      const bunicornContract = new Contract(
        config.addresses.bunicornContract,
        abi['VBuni'],
        provider
      );
      const numberOfTrainer = await trainerContract.balanceOf(this.account);
      const numberOfBunicorn = await bunicornContract.balanceOf(this.account);
      this.isOwnerTrainerOrBunicorn =
        numberOfTrainer >= 1 || numberOfBunicorn >= 1;
    },
    getTooltipContent() {
      if (Number(this.proposalInfo.categoryId) === 2) {
        return this.$t('voteTooltipGame');
      }
      return this.$t('voteTooltip', { amount: this.minHoldBuniToVote })
    }
  },
  async created() {
    this.fetchCategories();
    this.minHoldBuniToPropose = await minBuniHoldToPropose();
    this.minHoldBuniToVote = await minBuniHoldToVote();
    this.fetchOwnerTrainerOrBunicornInfo();
  }
};
</script>

<style lang="scss" scoped>
.proposal-card-wrapper {
  margin-top: 24px;
  width: 100%;
  max-width: 100%;
  background: var(--panel-background);
  border-radius: 10px;
  padding: 29px 24px;
  cursor: pointer;
  display: flex;
  .up-down-wrapper {
    width: 52px;
    height: 124px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    .vote-wrapper {
      width: 63px;
      height: 52px;
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      cursor: pointer;
      .vote-number {
        font-family: $font-weight-regular;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        &.game {
          font-size: 0.875rem;
        }
      }
      &.down-vote {
        border: 1px solid #f23e32;
        .vote-number {
          color: #f23e32;
        }
      }
      &.up-vote {
        border: 1px solid #4fba6f;
        .vote-number {
          color: #4fba6f;
        }
      }
      &.loading.up-vote {
        background: #4fba6f;
      }
      &.loading.down-vote {
        background: #f23e32;
      }
      &.disabled {
        cursor: not-allowed;
      }
    }
  }
  .content-wrapper {
    flex-grow: 1;
    margin-left: 18px;
    .proposal-title {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .title-text {
        font-weight: 600;
        font-size: 18px;
        color: var(--text-color-liquidity);
        line-height: 22px;
        max-width: 750px;
        overflow-wrap: break-word;
        &.game {
          font-size: 0.875rem;
        }
      }
      .time-posted {
        font-family: $font-weight-regular;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: var(--text-color-liquidity);
        opacity: 0.4;
        line-height: 24px;
        &.game {
          font-size: 0.813rem;
        }
      }
    }
    .proposal-type {
      margin-top: 8px;
      font-family: $font-weight-regular;
      font-weight: normal;
      font-size: 12px;
      color: #f47820;
      background: rgba(244, 120, 32, 0.1);
      border-radius: 4px;
      padding: 5px 8px;
      width: fit-content;
    }
    .proposal-description {
      margin-top: 17px;
      font-family: $font-weight-regular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-color-liquidity);
      opacity: 0.4;
      padding-bottom: 30px;
      border-bottom: 2px dashed rgba(0, 0, 0, 0.1);
      max-width: 750px;
      overflow-wrap: break-word;
      &.game {
        font-size: 0.813rem;
      }
    }
    .vote-static-wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .static-group {
        font-family: $font-weight-regular;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: var(--text-color-liquidity);
        &.game {
          font-size: 0.813rem;
        }
        .static-title {
          font-weight: normal;
          opacity: 0.4;
        }
        .static-number {
          font-weight: 600;
        }
      }
    }
    .vote-note-wrapper {
      padding: 14px 20px 14px 20px;
      background-color: rgba(241, 243, 246, 0.4);
      margin-top: 20px;
      color: #000000;
      border: 1px solid rgba(0, 0, 0, 0.1);

      .title-note-text {
        color: rgba(79, 186, 111, 1);
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
      }
      .time-note-posted {
        font-size: 12px;
        font-weight: normal;
        opacity: 0.4;
      }
      .description-note-posted {
        font-size: 14px;
        font-weight: normal;
        opacity: 0.4;
      }
    }
  }
  .action-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      border-radius: 20px;
      padding: 4px 16px;
      font-family: Lato-Regular;
      background-color: rgba(79, 186, 111, 0.1);
      border: 1px solid rgba(79, 186, 111, 1);
      color: rgba(79, 186, 111, 1);
      margin-top: 8px;
    }
    .btn-submit {
      background: linear-gradient(270deg, #ff8c06 0%, #f47820 100%);
      border-radius: 100px;
      width: fit-content;
      padding: 4px 32px;
      border: none;
      margin-top: 20px;
      min-height: 43px;

      .text-submit {
        font-size: 15px;
        font-weight: 700;
        line-height: 35px;
        color: rgba(255, 255, 255, 1);
      }
    }
    .disable {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
.vote-icon {
  img {
    display: block;
    margin: auto;
  }
}
.vote-number {
  text-align: center;
}
</style>
