var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-farm-wrapper"},[(_vm.loading)?_c('UiTable',[_c('ListLoading',{attrs:{"classes":[
        'column-sm text-left hide-sm hide-md hide-lg',
        'flex-auto text-left',
        'column hide-sm hide-md',
        'column hide-sm hide-md',
        'column hide-sm hide-md',
        'column',
        'column hide-sm hide-md hide-lg',
        'column hide-sm hide-md hide-lg'
      ],"height":29}})],1):_c('div',[(!_vm.loading && _vm.formattedFarms.length === 0)?_c('div',{staticClass:"py-3 text-center no-token"},[_c('div',[(!_vm._isThemeGame)?_c('img',{attrs:{"src":require("@/assets/icon/no-search.svg")}}):_c('img',{attrs:{"src":require("@/assets/icon/game/no-search.png")}})]),_c('div',{class:("mt-4 " + (_vm._isThemeGame ? 'game' : ''))},[(_vm.farmType === 'live')?_c('div',[_vm._v(_vm._s(_vm.$t('noLiveFarm')))]):_c('div',[_vm._v(_vm._s(_vm.$t('noTokenFound')))])])]):_c('UiTable',{staticClass:"table-farm"},_vm._l((_vm.formattedFarms),function(formattedFarm){return _c('UiTableTr',{key:formattedFarm.farm.pid},[_c('FarmTr',{key:formattedFarm.farm.label,attrs:{"poolTokens":formattedFarm.farm.tokens,"poolId":formattedFarm.farm.id,"buniEarned":formattedFarm.earned.earnings,"stake":formattedFarm.myStake,"apy":formattedFarm.apr.value,"details":formattedFarm.details,"reward":formattedFarm.multiplier,"buniPrice":formattedFarm.apr.buniPriceUsd,"originalValue":formattedFarm.apr.originalValue}})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }