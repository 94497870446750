var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row_element row mt-4"},[_vm._l((_vm.pendingVestings),function(vesting){return _c('div',{key:'vesting-' + vesting.tokenId,staticClass:"card-wrapper",class:_vm._isThemeGame ? 'game game-border-table' : ''},[_c('div',{staticClass:"column_element element-vesting"},[_c('div',{staticClass:"pt-3 header d-flex"},[_vm._m(0,true),_c('div',{class:_vm._isThemeGame ? 'game-vesting-amount' : 'vesting-amount'},[_vm._v(" "+_vm._s(_vm._num(vesting.amout))+" BUNI (ID: "+_vm._s(vesting.tokenId)+") ")])]),_c('div',{staticClass:"image-frame",on:{"click":function($event){return _vm.openVestingCardModal(vesting.imageUrl)}}},[_c('img',{staticClass:"tokenImage",attrs:{"src":vesting.smallImageUrl}}),(
            !(
              _vm.canClaim(vesting) ||
              (_vm.$refs[("countdown_" + (vesting.tokenId))] &&
                _vm.$refs[("countdown_" + (vesting.tokenId))][0].isEnded)
            )
          )?_c('div',{staticClass:"countdown-wrapper"},[_c('div',{class:(" " + (_vm._isThemeGame ? 'game-border-table-countdown' : 'count-down'))},[_c('Countdown',{key:("countdown_" + (vesting.tokenId)),ref:("countdown_" + (vesting.tokenId)),refInFor:true,attrs:{"endDate":_vm.getVestingCountdown(vesting)}})],1)]):_vm._e()]),_c('div',{staticClass:"display",class:_vm._isThemeGame ? 'mt-4' : ''},[(!_vm.isApproved)?[_c('button',{staticClass:"button mb-4 button-claim",class:{
              'game-border-btn-add': _vm._isThemeGame,
              disabled: !_vm._isThemeGame && (_vm.isApproving || _vm.wrongNetwork),
              'game-btn-disabled':
                _vm._isThemeGame && (_vm.isApproving || _vm.wrongNetwork)
            },on:{"click":_vm.approveAllMyVbuni}},[(_vm.isApproving)?_c('UiLoading'):_c('span',[_vm._v(" "+_vm._s(_vm.$t('approveAll'))+" ")])],1)]:(
            _vm.canClaim(vesting) ||
              (_vm.$refs[("countdown_" + (vesting.tokenId))] &&
                _vm.$refs[("countdown_" + (vesting.tokenId))][0].isEnded)
          )?[_c('div',{staticClass:"claim-button-group"},[_c('UiButton',{key:("countdown_" + (vesting.tokenId) + "_button"),staticClass:"button-claim",class:{
                'game-border-btn-add': _vm._isThemeGame,
                disabled:
                  !_vm._isThemeGame && (vesting.isClaiming || _vm.wrongNetwork),
                'game-btn-disabled':
                  _vm._isThemeGame && (vesting.isClaiming || _vm.wrongNetwork)
              },on:{"click":function($event){!_vm.isClaiming && _vm.claimBuni(vesting)}}},[_c('div',{staticClass:"text-button"},[_vm._v(_vm._s(_vm.$t('claimBuni')))])])],1)]:[_c('div',{staticClass:"claim-button-group"},[_c('UiButton',{class:("button-claim-now " + (_vm._isThemeGame ? 'game-border-btn-add' : '') + " " + (!_vm._isThemeGame && (vesting.isClaiming || _vm.wrongNetwork)
                    ? 'disabled'
                    : '')),on:{"click":function($event){!_vm.isClaiming && _vm.onClickOpenWarningStakeModal(vesting)}}},[_c('div',{staticClass:"text-button"},[_vm._v(_vm._s(_vm.$t('claimNow')))])])],1)]],2)])])}),_c('portal',{attrs:{"to":"modal"}},[_c('ModalWarningStake',{attrs:{"open":_vm.openModalWarningStake,"vesting":_vm.selectedVesting,"loading":_vm.isClaiming},on:{"closeModalWarningStake":_vm.closeModalWarningStake,"claimNow":function($event){return _vm.claimBuniNow(_vm.selectedVesting)}}}),(_vm.openVestingCard)?_c('div',{staticClass:"vesting-card"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"close-icon",on:{"click":_vm.closeVestingCardModal}},[_c('img',{attrs:{"src":require("../../assets/close-vesting-card.svg")}})]),_c('div',{staticClass:"card-img"},[_c('img',{staticClass:"tokenImage",attrs:{"src":_vm.vestingCardUrl}})])])]):_vm._e()],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-logo mr-2"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo_vesting.png")}})])}]

export { render, staticRenderFns }