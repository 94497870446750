import { Contract } from '@ethersproject/contracts';
import { BaseProvider } from '@ethersproject/providers';
import { Pools, Pool, SubGraphPools, Token } from './types';
import * as bmath from './bmath';

export async function getAllPoolDataOnChain(
  pools: SubGraphPools,
  multiAddress: string,
  provider: BaseProvider
): Promise<Pools> {
  if (pools.pools.length === 0) throw Error('There are no pools.');

  // eslint-disable-next-line @typescript-eslint/no-var-requires,no-undef
  const customMultiAbi = require('./abi/customMulticall.json');
  const contract = new Contract(multiAddress, customMultiAbi, provider);

  const addresses: string[][] = [];
  let total = 0;

  for (let i = 0; i < pools.pools.length; i++) {
    const pool = pools.pools[i];

    addresses.push([pool.id]);
    total++;
    pool.tokens.forEach(token => {
      addresses[i].push(token.address);
      total++;
    });
  }

  const results = await contract.getPoolInfo(addresses, total);

  let j = 0;
  const onChainPools: Pools = { pools: [] };

  for (let i = 0; i < pools.pools.length; i++) {
    const tokens: Token[] = [];

    const p: Pool = {
      id: pools.pools[i].id,
      swapFee: bmath.scale(bmath.bnum(pools.pools[i].swapFee), 18),
      totalWeight: bmath.scale(bmath.bnum(pools.pools[i].totalWeight), 18),
      tokens: tokens,
      tokensList: pools.pools[i].tokensList
    };

    pools.pools[i].tokens.forEach(token => {
      const bal = bmath.bnum(results[j]);
      j++;
      p.tokens.push({
        address: token.address,
        balance: bal,
        decimals: Number(token.decimals),
        denormWeight: bmath.scale(bmath.bnum(token.denormWeight), 18)
      });
    });
    onChainPools.pools.push(p);
  }
  return onChainPools;
}
