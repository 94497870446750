var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column mb-2"},[_c('div',{staticClass:"info",class:_vm._isThemeGame ? 'mb-3 game' : ''},[_c('div',{staticClass:"type-label"},[_vm._v(" "+_vm._s(_vm.type === 'input' ? _vm.$t('from') : _vm.$t('to'))+" ")]),_c('div',{staticClass:"balance"},[_vm._v(" "+_vm._s(_vm.showBalance && _vm.address ? ((_vm.$t('balance')) + ": " + _vm.balance) : ((_vm.$t('balance')) + ": 0"))+" ")])]),_c('div',{staticClass:"asset-input",class:_vm._isThemeGame ? 'game-input-swap' : ''},[_c('div',{staticClass:"amount-wrapper"},[_c('div',{staticClass:"amount"},[_c('div',{staticClass:"input-wrapper"},[(_vm.isCalculating.length > 0 && _vm.isCalculating !== _vm.type)?_c('div',{staticClass:"loading"}):_vm._e(),_c('currency-input',{staticClass:"cards__input-number swap-input",class:[
              _vm._isThemeGame ? 'game-placeholder game-swap-input' : '',
              _vm.isCalculating.length > 0 && _vm.isCalculating !== _vm.type
                ? 'hidden'
                : ''
            ],attrs:{"precision":_vm.getPrecision(),"type":"text","placeholder":"0.0","disabled":_vm.disable || _vm.transactionPending},on:{"keyup":_vm.handleInputChange},model:{value:(_vm.amountData),callback:function ($$v) {_vm.amountData=$$v},expression:"amountData"}})],1),(_vm.enableMax && this.isAuthenticated)?_c('div',{staticClass:"button max-button",class:[
            !_vm._isThemeGame ? '' : 'game-border-button-max',
            _vm.transactionPending && 'disable'
          ],on:{"click":_vm.handleInputMax}},[_vm._v(" "+_vm._s(_vm.$t('max'))+" ")]):_vm._e()])]),_c('div',{staticClass:"asset-wrapper",class:_vm.transactionPending && 'disable',on:{"click":_vm.handleSelectAsset}},[_c('div',{staticClass:"d-flex align-center",class:((!_vm.symbol && 'select-token') + " " + (!_vm.symbol && _vm._isThemeGame ? 'game-btn-select-token' : ''))},[_c('div',{staticClass:"asset-meta"},[_c('div',{staticClass:"icon-wrapper"},[(_vm.address)?_c('AssetIcon',{staticClass:"asset-icon",attrs:{"address":_vm.address}}):_vm._e()],1),(_vm.symbol && _vm.symbol.length <= 10)?_c('span',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticClass:"asset-symbol",class:_vm._isThemeGame ? 'game-asset-symbol-fontsize' : ''},[_vm._v(" "+_vm._s(_vm.symbol)+" ")])]):_c('span',{staticClass:"text-select-token"},[_vm._v(_vm._s(_vm.$t('selectAToken')))])]),(!_vm._isThemeGame)?_c('Icon',{staticClass:"chevron-down",attrs:{"name":'chevron-down'}}):_c('img',{staticStyle:{"width":"30px"},attrs:{"src":require("@/assets/icon/game/arrow-down-icon.png")}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }