<template>
  <div
    class="flex-auto header-swap-pool  mb-4"
    :class="`${_isThemeGame ? 'game' : ''}`"
    v-if="pairTokenMetadata.tokens && pairTokenMetadata.tokens.length"
  >
    <div
      class="swap rate-message"
      :class="`${_isThemeGame ? 'game-border-table' : ''}`"
    >
      <Token
        :address="pairTokenMetadata.tokens[0].address"
        :symbol="pairTokenMetadata.tokens[0].symbol"
        class="mr-1 margin-symbol"
      />
      <span class="rate-label">
        {{ token0RateAsText }}
      </span>
    </div>
    <div
      class="swap rate-message"
      :class="`${_isThemeGame ? 'game-border-table' : ''}`"
    >
      <Token
        :address="pairTokenMetadata.tokens[1].address"
        :symbol="pairTokenMetadata.tokens[1].symbol"
        class="mr-1 margin-symbol"
      />
      <span class="rate-label">
        {{ token1RateAsText }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PoolSwap',
  props: ['pairTokenMetadata'],
  computed: {
    token0RateAsText() {
      return this.getRateMessage(
        this.pairTokenMetadata.price.token0Rate,
        this.pairTokenMetadata.price.token0USD,
        this.pairTokenMetadata.tokens[0].symbol,
        this.pairTokenMetadata.tokens[1].symbol
      );
    },
    token1RateAsText() {
      return this.getRateMessage(
        this.pairTokenMetadata.price.token1Rate,
        this.pairTokenMetadata.price.token1USD,
        this.pairTokenMetadata.tokens[1].symbol,
        this.pairTokenMetadata.tokens[0].symbol
      );
    }
  },
  methods: {
    getRateMessage(rateSwap, rateUSD, symbolA, symbolB) {
      return `1 ${symbolA} = ${rateSwap} ${symbolB} (${rateUSD})`;
    }
  }
};
</script>

<style lang="scss" scoped>
.swap {
  border: 1px solid var(--border-color);
  background-color: var(--panel-background);
  box-sizing: border-box;
  border-radius: 100px;
  padding: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
}
.header-swap-pool {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.rate-label {
  max-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px !important;
}
.rate-message {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondary-text-color);
}
.margin-symbol {
  margin-right: 10px !important;
}

.header-swap-pool {
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 10px;
}

.header-swap-pool.game {
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--border-input);
}
.rate-message.game-border-table {
  font-size: 11px;
  padding: 5px !important;
}

@media only screen and (max-width: 768px) {
  .header-swap-pool {
    justify-content: center;

    .swap.rate-message {
      margin-right: 0 !important;
    }
  }
}
</style>
