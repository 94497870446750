<template>
  <Page :requireLogin="true">
    <Container>
      <Title :title="'Bounty NFT'" class="header" />
      <div>
        <UiLoadingPage v-if="loading" />
        <div v-else>
          <div class="display py-3 text-center" v-if="listNFTCount === 0">
            <img v-if="!_isThemeGame" src="~@/assets/icon/no-search.svg" />
            <img v-else src="~@/assets/icon/game/no-search.png" />
            <div :class="`mt-4 ${_isThemeGame ? 'game' : ''}`">
              Bounty NFTs Not Found
            </div>
          </div>
          <ListNFT
            v-else
            :listNFTs="listNFTs"
          />
        </div>
      </div>
    </Container>
  </Page>
</template>

<script>
import ListNFT from '@/views/BountyNFT/ListNFT.vue';
import { useIntervalFn } from '@vueuse/core';
import config from '@/config';
import { multicall } from '@/_balancer/utils';
import provider from '@/helpers/provider';
import abi from '@/helpers/abi';
import BigNumber from 'bignumber.js';
import axios from 'axios';

export default {
  name: 'BountyNFT',
  metaInfo: {
    title: 'BountyNFT'
  },
  components: { ListNFT },
  data() {
    return {
      listNFTCount: 0,
      listNFTs: [],
      isApproved: false,
      pauseIntervalFn: undefined,
      loading: false,
      isApproving: false
    };
  },
  watch: {
    '$store.state.account.address': async function() {
      this.loading = true;

      await this.init();

      this.loading = false;
    }
  },
  methods: {
    async fetchUserBountyNFTCount(myAddress) {
      const calls = [
        {
          address: config.addresses.bounty_nft,
          name: 'balanceOf',
          params: [myAddress]
        }
      ];

      const rawTokenBalances = await multicall(provider, abi['BountyNFT'], calls);
      const parsedTokenBalances = rawTokenBalances.map(tokenBalance => {
        return new BigNumber(tokenBalance).toJSON();
      });
      return new BigNumber(parsedTokenBalances[0] || 0).toNumber();
    },

    async getNftInfos(tokenIds) {
      try {
        const res = await Promise.all(
          tokenIds.map((tokenId, index) => {
            try {
              return axios.get(
                `${config.nftMetadataDebugUrl}/bounty-nft/${tokenId}`
              );
            } catch (e) {
              return {};
            }
          })
        );
        return res.map(item => item.data);
      } catch {
        return tokenIds.map(tokenId => '');
      }
    },

    async fetchListNFTOf(account, numberOfToken) {
      const calls = [];
      const calls2 = [];
      const calls3 = [];

      for (let index = 0; index < numberOfToken; index++) {
        calls.push({
          address: config.addresses.bounty_nft,
          name: 'getTokenInfoOfOwnerByIndex',
          params: [account, index]
        });
      }

      for (let index = 0; index < numberOfToken; index++) {
        calls2.push({
          address: config.addresses.bounty_nft,
          name: 'tokenOfOwnerByIndex',
          params: [account, index]
        });
      }

      const tokenInfos = await multicall(provider, abi['BountyNFT'], calls);
      let tokenIds = await multicall(provider, abi['BountyNFT'], calls2);
      tokenIds = tokenIds.map(tokenId => {
        return new BigNumber(tokenId).toString();
      });

      tokenIds.forEach((tokenId) => {
        calls3.push({
          address: config.addresses.bounty_nft,
          name: 'getTokenInfo',
          params: [tokenId]
        });
      });

      const nftInfos = await this.getNftInfos(tokenIds);

      const parsedTokenBalances = tokenInfos.map((tokenInfo, index) => {
        const [hashRate, createdAt] = tokenInfo;
        return {
          tokenId: tokenIds[index],
          name: nftInfos[index].name,
          imageUrl: nftInfos[index].medium_image || "https://static.nft.bunicorn.exchange/farms/logo.png",
          smallImageUrl: nftInfos[index].medium_image || "https://static.nft.bunicorn.exchange/farms/logo.png",
          hashRate: new BigNumber(hashRate._hex).div('1e18').toJSON(),
          createdAt,
        };
      });
      return parsedTokenBalances;
    },

    async init() {
      const myAddress = this.$store.state.account.address;
      if (!myAddress) {
        return;
      }
      this.listNFTCount = await this.fetchUserBountyNFTCount(myAddress) || 0;

      console.log('listNFTCount', this.listNFTCount);
      this.listNFTs = await this.fetchListNFTOf(myAddress, this.listNFTCount);
    }
  },
  beforeDestroy() {
    if (!this.pauseIntervalFn) {
      return;
    }
    this.pauseIntervalFn();
  },
  async mounted() {
    this.loading = true;

    await this.init();

    const myAddress = this.$store.state.account.address;
    const { pause, resume, isActive } = useIntervalFn(async () => {
      console.log('useIntervalFn fetchUserBountyNFTCount');
      this.listNFTCount = await this.fetchUserBountyNFTCount(myAddress) || 0;
      this.listNFTs = await this.fetchListNFTOf(myAddress, this.listNFTCount);
    }, 60 * 1000);

    this.pauseIntervalFn = pause;
    this.loading = false;
  }
};
</script>

<style lang="scss" scoped>
.button-claim {
  background: linear-gradient(270deg, #ff8c06 0%, #f47820 100%);
  color: $text-white;
  font-size: 16px;
  margin-top: 20px;
  border: 0 !important;

  &:enabled {
    &:hover {
      background: $color-primary;
    }
  }

  &:disabled {
    opacity: 0.4;
  }
}

.button-claim.game-border-btn-add {
  border-radius: 0 !important;
  background: $color-primary !important;
  border: 0 !important;
  font-size: 12px !important;
  margin-left: 8px;
  height: 35px !important;

  &:enabled {
    &:hover {
      background: $game-btn-hover-color !important;
    }
  }
}
.text-notification {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: var(--text-color);
}
</style>
