var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pool)?_c('div',{class:[_vm._isThemeGame ? 'game-pool-boxes' : 'pool-boxes']},[_c('div',{staticClass:"col-12 float-left column-pool-header game"},[_c('div',{staticClass:"rounded-0 rounded-md-1 mx-0 mx-md-2"},[_c('p',{class:_vm._isThemeGame ? 'game-pool-info' : 'pool-info',domProps:{"textContent":_vm._s(_vm.forKurve ? ("" + (_vm.pool.liquidity)) : _vm._num(_vm.pool.liquidity, 'usd'))}}),_c('p',{class:("mb-0 sub-title-pool " + (_vm._isThemeGame ? 'game-font-size' : '')),domProps:{"textContent":_vm._s(
          _vm.forKurve
            ? ((_vm.$t('liquidity')) + " " + (!_vm.pool.usingTracked ? _vm.$t('untracked') : ''))
            : _vm.$t('liquidity')
        )}})])]),_c('div',{staticClass:"col-12 float-left column-pool-header"},[_c('div',{staticClass:"rounded-0 rounded-md-1 mx-0 mx-md-2"},[_c('p',{class:_vm._isThemeGame ? 'game-pool-info' : 'pool-info',domProps:{"textContent":_vm._s(
          _vm.forKurve ? ("" + (_vm.pool.volume)) : _vm._num(_vm.pool.lastSwapVolume, 'usd')
        )}}),_c('p',{class:("mb-0 sub-title-pool " + (_vm._isThemeGame ? 'game-font-size' : '')),domProps:{"innerHTML":_vm._s(
          _vm.forKurve
            ? ((_vm.$t('volume24')) + " " + (_vm.pool.usingUtVolume ? _vm.$t('untracked') : ''))
            : _vm.$t('volume24')
        )}})])]),_c('div',{staticClass:"col-12 float-left column-pool-header"},[_c('div',{staticClass:"rounded-0 rounded-md-1 mx-0 mx-md-2"},[_c('p',{class:_vm._isThemeGame ? 'game-pool-info' : 'pool-info',domProps:{"textContent":_vm._s(_vm.forKurve ? _vm.pool.fees : _vm._num(_vm.pool.swapFee, 'percent'))}}),_c('p',{class:("mb-0 sub-title-pool " + (_vm._isThemeGame ? 'game-font-size' : '')),domProps:{"innerHTML":_vm._s(_vm.forKurve ? _vm.$t('swapFee24h') : _vm.$t('swapFee'))}})])]),_c('div',{staticClass:"col-12 float-left column-pool-header"},[_c('div',{staticClass:"rounded-0 rounded-md-1 mx-0 mx-md-2"},[_c('p',{class:_vm._isThemeGame ? 'game-pool-info' : 'pool-info',domProps:{"textContent":_vm._s(_vm.poolSharePercentAsText)}}),_c('p',{staticClass:"tooltipped tooltip-balance tooltipped-n",class:_vm._isThemeGame
            ? 'game-pool-info game-pool-share game-tooltip'
            : 'pool-info pool-share',attrs:{"aria-label":_vm.poolShareBalanceAsText(true)},domProps:{"textContent":_vm._s(_vm.poolShareBalanceAsText())}}),_c('p',{class:("mb-0 sub-title-pool " + (_vm._isThemeGame ? 'game-font-size' : '')),domProps:{"textContent":_vm._s(_vm.$t('myPoolShare'))}})])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }