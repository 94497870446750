var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UiModal',{staticClass:"modal-stake",class:_vm._isThemeGame ? 'game' : '',staticStyle:{"max-width":"770px"},attrs:{"open":_vm.open},on:{"close":function($event){return _vm.$emit('closeModalWarningHarvestNFTFarm')}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"header",class:_vm._isThemeGame && 'game-header'},[_vm._v(" "+_vm._s(_vm.$t('harvestNFTTitle'))+" ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"current-level",class:!_vm.imgUrlNextLevel ? 'highest-level' : ''},[_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":_vm.imgUrlCurLevel}})]),(_vm.imgUrlNextLevel)?_c('div',{staticClass:"desc",class:_vm._isThemeGame ? 'game' : '',domProps:{"innerHTML":_vm._s(
            ("" + (_vm.$t('currentLevel', {
              currentLevelName: _vm.currentLevelName
            })))
          )}}):_vm._e()]),(_vm.imgUrlNextLevel)?_c('div',{staticClass:"between-content"},[_c('div',{staticClass:"buni-to-next-level",class:_vm._isThemeGame ? 'game' : ''},[_vm._v(" "+_vm._s(_vm.$t('earn'))+" "+_vm._s(_vm._num(_vm.buniToNextLevel))+" BUNI "),_c('br'),_vm._v(_vm._s(_vm.$t('toReceive'))+" ")]),_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":require("@/assets/game_level/arrow-next-level.svg")}})])]):_vm._e(),(_vm.imgUrlNextLevel)?_c('div',{staticClass:"next-level"},[_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":_vm.imgUrlNextLevel}})]),_c('div',{staticClass:"desc",class:_vm._isThemeGame ? 'game' : '',domProps:{"innerHTML":_vm._s(
            ("\n            " + (_vm.$t('nextLevel', {
              nextLevelName: _vm.nextLevelName
            })))
          )}})]):_vm._e()]),_c('div',{staticClass:"button-flex"},[_c('UiButton',{staticClass:"button-wrapper button",class:("" + (_vm._isThemeGame ? 'game-border-btn-add' : '')),staticStyle:{"width":"150px"},attrs:{"requireLogin":true,"type":"submit"},on:{"click":function($event){return _vm.$emit('harvestNFTFarm')}}},[(_vm.loading)?_c('UiLoading'):_c('span',[_vm._v(_vm._s(_vm.$t('harvestAnyWay')))])],1),_c('UiButton',{staticClass:"button button-cancel",class:("" + (_vm._isThemeGame ? 'game-border-btn-add' : '')),staticStyle:{"width":"150px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('closeModalWarningHarvestNFTFarm')}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }