<template>
  <UiTable class="table-pools mt-4">
    <UiTableTh>
      <div
        v-text="$t('poolAddress')"
        class="column-sm hide-sm hide-md hide-lg text-left"
        style="min-width:110px!important;"
      />
      <div v-text="$t('assets')" class="flex-auto text-left " />
      <div v-html="$t('marketCap')" class="stablecoin-column" />
      <div
        v-html="$t('swapFee24h')"
        class="stablecoin-column hide-sm hide-md"
      />
      <div
        class="stablecoin-sm-column hide-sm hide-md hide-lg"
        v-text="$t('1yfl')"
      ></div>
      <div
        class="stablecoin-column hide-sm hide-md hide-lg"
        v-html="$t('myLiquidity')"
      ></div>
      <div
        v-text="$t('amp')"
        class="stablecoin-sm-column hide-sm hide-md hide-lg"
      />
      <div
        v-html="$t('volume24')"
        class="stablecoin-column hide-sm hide-md hide-lg"
      />
    </UiTableTh>
    <div v-if="pools.length > 0 && !loading">
      <liquidity-item
        v-for="(pool, i) in pools"
        :key="i"
        :pool="pool"
        :liquidityPosition="transformedUserLiquidityPositions[pool.id] || {}"
      />
    </div>
    <UiTableTr v-else-if="!loading">
      <div v-text="$t('emptyState')" />
    </UiTableTr>
    <ListLoading
      v-if="loading"
      :classes="[
        'column-sm text-left hide-sm hide-md hide-lg',
        'flex-auto text-left',
        'stablecoin-column hide-sm hide-md',
        'stablecoin-column hide-sm hide-md',
        'stablecoin-sm-column hide-sm hide-md',
        'stablecoin-column',
        'stablecoin-sm-column hide-sm hide-md hide-lg',
        'stablecoin-column hide-sm hide-md hide-lg'
      ]"
      :height="29"
    />
  </UiTable>
</template>

<script>
import * as _ from 'lodash';
import { mapActions } from 'vuex';
import LiquidityItem from '@/components/Kurve/LiquidityItem.vue';

export default {
  name: 'MyKurveLiquidity',
  components: { LiquidityItem },
  data: () => {
    return {
      provider: null,
      loading: true,
      pools: [],
      transformedUserLiquidityPositions: {}
    };
  },
  computed: {
    account() {
      const { connector, address } = this.$store.state.account;
      if (!connector || !connector.id || !address) {
        return '';
      }
      return address;
    }
  },
  watch: {
    account() {
      this.loadPools();
    }
  },
  mounted() {
    this.loadPools();
  },
  methods: {
    ...mapActions([
      'getEthPriceForKurve',
      'getKurveBulkPoolsData',
      'getKurveUserLiquidityPositions'
    ]),
    async loadPools() {
      this.provider = await this.$store.getters['account/provider'];
      this.loading = true;
      this.pools = [];
      this.transformedUserLiquidityPositions = {};

      try {
        const [ethPrice] = await this.getEthPriceForKurve();
        const [pools, userLiquidityPositions] = await Promise.all([
          this.getKurveBulkPoolsData({
            provider: this.provider,
            ethPrice: ethPrice,
            fetchAll: true
          }),
          this.getKurveUserLiquidityPositions({
            account: this.account
          })
        ]);

        userLiquidityPositions.forEach(position => {
          if (
            !this.transformedUserLiquidityPositions[position.pool.id] ||
            position.timestamp >
              this.transformedUserLiquidityPositions[position.pool.id].timestamp
          ) {
            this.transformedUserLiquidityPositions[position.pool.id] = position;
          }
        });

        const myLiquidities = pools.filter(pool =>
          this.hasMyLiquidity(
            this.transformedUserLiquidityPositions[pool.id] || {}
          )
        );
        this.pools = _.sortBy(myLiquidities, ['amp']);
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    hasMyLiquidity(liquidityPosition) {
      if (
        !liquidityPosition ||
        parseFloat(liquidityPosition.liquidityTokenTotalSupply) === 0
      ) {
        return false;
      }

      const myLiquidity =
        (parseFloat(liquidityPosition.liquidityTokenBalance) *
          parseFloat(liquidityPosition.reserveUSD)) /
        parseFloat(liquidityPosition.liquidityTokenTotalSupply);

      return !(myLiquidity === 0 || Number.isNaN(myLiquidity));
    }
  }
};
</script>
