<template>
  <div class="d-flex searchVote">
    <div class="mr-5 searchSelectVote">
      <VoteSelectTime @sort="sort" />
    </div>

    <div class="mr-5 searchSelectVote">
      <VoteSelectCategory
        @select-items="selectedItem"
        :typeSelect="'selectedSearch'"
      />
    </div>
    <div :class="`filter-vote ${_isThemeGame ? 'game-border-btn-search' : ''}`">
      <input
        v-model.trim="searchVote"
        class="search-stablecoin"
        :class="`${_isThemeGame ? 'game-placeholder query-input' : ''}`"
        type="text"
        :placeholder="$t('Search')"
      />
      <Icon v-if="!_isThemeGame" name="search-1" size="16" />
      <img
        v-else
        src="~@/assets/icon/game/search-icon.png"
        style="width: 25px"
      />
    </div>
  </div>
</template>

<script>
import VoteSelectCategory from '@/components/Vote/VoteSelectCategory.vue';
import VoteSelectTime from '@/components/Vote/VoteSelectTime.vue';
export default {
  data() {
    return {
      searchVote: ''
    };
  },
  components: {
    VoteSelectCategory,
    VoteSelectTime
  },
  watch: {
    searchVote() {
      this.$emit('search', this.searchVote);
    }
  },
  methods: {
    sort(value) {
      this.$emit('sort', value);
    },
    selectedItem(categoryId) {
      this.$emit('select-item', categoryId);
    }
  }
};
</script>

<style scoped lang="scss">
.filter-vote {
  height: 35px;
  box-shadow: inset 1px 2px 3px var(--border-shadow-search);
  border-radius: 25px;
  border: 1px solid var(--border-search);
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin: 0 !important;

  input {
    width: 255px;
    color: var(--text-color);
  }

  i {
    color: var(--button-color-checkbox-create);
    margin-left: 10px;
  }
}

.filter-stablecoins.game-border-btn-search {
  border-radius: 0 !important;
}
.searchVote {
  margin-top: 24px;
}

@media only screen and (max-width: 768px) {
  .searchVote {
    flex-wrap: wrap;
    flex-direction: column !important;
  }
  .searchSelectVote {
    margin-right: 0px !important;
    margin-bottom: 10px;
  }
  .filter-vote {
    input {
      width: 100%;
    }
  }
}
</style>
