var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Title',{attrs:{"title":_vm.$t('vote')}}),_c('div',{staticClass:"tabs"},[_c('b-nav',{staticClass:"tab-nav",class:!_vm._isThemeGame ? '' : 'game',attrs:{"tabs":""}},[_c('b-nav-item',{class:[
          !_vm._isThemeGame
            ? 'tab-item '
            : 'tab-item-game game-border-tabnav-notactive'
        ],attrs:{"to":{ name: 'vote' },"active":_vm.$route.name === 'vote'}},[_vm._v(" "+_vm._s(_vm.$t('newProposal'))+" ")]),_c('b-nav-item',{class:[
          !_vm._isThemeGame
            ? 'tab-item'
            : 'tab-item-game game-border-tabnav-notactive'
        ],attrs:{"to":{ name: 'processedProposals' },"active":_vm.$route.name === 'processedProposals'}},[_vm._v(" "+_vm._s(_vm.$t('processedProposals'))+" ")]),_c('b-nav-item',{class:[
          !_vm._isThemeGame
            ? 'tab-item'
            : 'tab-item-game game-border-tabnav-notactive'
        ],attrs:{"to":{ name: 'relatedToMe' },"active":_vm.$route.name === 'relatedToMe'}},[_vm._v(" "+_vm._s(_vm.$t('relatedToMe'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }