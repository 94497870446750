var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.title)?_c('Container',{staticClass:"d-flex flex-items-center px-4 px-md-0 mb-3"},[_c('h3',{staticClass:"flex-auto",domProps:{"textContent":_vm._s(_vm.title)}})]):_vm._e(),_c('UiTable',{staticClass:"table-pools"},[_c('UiTableTh',[_c('div',{staticClass:"column-sm text-left hide-sm hide-md hide-lg",staticStyle:{"min-width":"110px!important"},domProps:{"textContent":_vm._s(_vm.$t('poolAddress'))}}),_c('div',{staticClass:"flex-auto text-left",domProps:{"textContent":_vm._s(_vm.$t('assets'))}}),_c('div',{staticClass:"token-column hide-sm hide-md",domProps:{"textContent":_vm._s(_vm.$t('swapFee'))}}),_c('div',{staticClass:"token-market-cap",domProps:{"innerHTML":_vm._s(_vm.$t('marketCap'))}}),_c('div',{staticClass:"token-column hide-sm hide-md hide-lg",domProps:{"innerHTML":_vm._s(_vm.$t('myLiquidity'))}}),_c('div',{staticClass:"token-column hide-sm hide-md hide-lg",domProps:{"innerHTML":_vm._s(_vm.$t('volume24'))}})]),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],attrs:{"infinite-scroll-distance":"10"}},[(_vm.pools.length > 0)?_c('div',_vm._l((_vm.pools),function(pool,i){return _c('ListPool',{key:i,attrs:{"pool":pool}})}),1):(!_vm.loading)?_c('UiTableTr',[_c('div',{domProps:{"textContent":_vm._s(_vm.$t('emptyState'))}})]):_vm._e(),(_vm.loading)?_c('ListLoading',{attrs:{"classes":[
          'column-sm text-left hide-sm hide-md hide-lg',
          'flex-auto text-left',
          'column hide-sm hide-md',
          'column',
          'column hide-sm hide-md hide-lg',
          'column hide-sm hide-md hide-lg'
        ],"height":29}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }