<template>
  <div>
    <div class="liquidity-add border px-5 py-6 my-4">
      <div class="head-container flex flex-column py-3">
        <div class="back-icon" @click="goBack">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="NavigationTabs__StyledArrowLeft-sc-1pcf11z-3 dsXEtN"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
        </div>
        <div class="title">Add Liquidity</div>
      </div>
      <div class="my-3">
        <liquidity-input
          :type="'tokenIn'"
          :address="tokenA"
          :symbol="symbolA"
          :amount="tokenAmountA"
          :enable-max="enableMaxIn"
          @handle-input-change="handleInputChange"
          @handle-max="handleMax"
        ></liquidity-input>
        <div v-if="poolInfo.price">
          1 {{ symbolA }} = {{ poolInfo.price.price.decimalPlaces(5) }}
          {{ symbolB }}
        </div>
      </div>
      <div class="text-center">+</div>
      <div class="my-3">
        <liquidity-input
          :type="'tokenOut'"
          :address="tokenB"
          :symbol="symbolB"
          :amount="tokenAmountB"
          :enable-max="!enableMaxIn"
          @handle-input-change="handleInputChange"
          @handle-max="handleMax"
        ></liquidity-input>
        <div v-if="poolInfo.price">
          1 {{ symbolB }} = {{ poolInfo.price.invert.decimalPlaces(5) }}
          {{ symbolA }}
        </div>
      </div>
      <div v-if="poolInfo.pool" class="my-3">
        AMP = {{ poolInfo.pool.amp.decimalPlaces(5) }}
      </div>
      <div v-if="poolInfo.pool" class="border b-dashed rounded my-3 p-3">
        Dynamic Fee: {{ feeRangeCalc(poolInfo.pool.amp) }}
      </div>
      <div class="border b-dashed rounded my-3 p-3">Active Price Range</div>
      <div class="info d-flex">
        <div>
          Ratio: {{ raito.percentToken0 }}% {{ symbolA }} -
          {{ raito.percentToken1 }}% {{ symbolB }}
        </div>
        <div>Pool Share : {{ poolShare }}%</div>
      </div>
      <div
        v-if="tokenAmountA && approvalState[tokenA] !== ApprovalState.APPROVED"
        class="liquidity-button"
      >
        <button class="bg-blue" @click="handleAppove(tokenA)">
          <UiLoading v-if="approvalLoading[tokenA]" />
          <span v-else>Approve {{ symbol(tokenA) }}</span>
        </button>
      </div>
      <div
        v-if="tokenAmountB && approvalState[tokenB] !== ApprovalState.APPROVED"
        class="liquidity-button"
      >
        <button class="bg-blue" @click="handleAppove(tokenB)">
          <UiLoading v-if="approvalLoading[tokenB]" />
          <span v-else>Approve {{ symbol(tokenB) }}</span>
        </button>
      </div>
      <div class="liquidity-button">
        <button
          :class="
            buttonError.disable ||
            approvalState[tokenA] !== ApprovalState.APPROVED ||
            approvalState[tokenB] !== ApprovalState.APPROVED
              ? 'btn-disable'
              : ''
          "
          :disabled="
            buttonError.disable ||
              approvalState[tokenA] !== ApprovalState.APPROVED ||
              approvalState[tokenB] !== ApprovalState.APPROVED
          "
          @click="handleAddLiquidity"
        >
          <UiLoading v-if="buttonError.loading" />
          <span v-else>{{ buttonError.text }}</span>
        </button>
      </div>
      <div v-if="requireLogin" class="liquidity-button">
        <button
          class="btn-connect bg-info"
          :disabled="!requireLogin"
          @click="handleConnectWallet"
        >
          <UiLoading v-if="connectButtonLoading" />
          <span v-else>Connect Wallet</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
import { mapActions } from 'vuex';
import LiquidityInput from '@/components/Kurve/LiquidityInput';
import config from '@/config';
import { BNB_KEY, getEtherscanLink, scale } from '@/utils/helpers';
import { ApprovalState } from '@/utils';
import {
  wrappedToken,
  getKurveAllowances,
  kurveAddLiquidity
} from '@/helpers/kurve';
import Storage from '@/utils/storage';
import dayjs from 'dayjs';
import { ErrorCode } from '@ethersproject/logger';
import _provider from '@/helpers/provider';

export default {
  name: 'AddLiquidityKurve',
  components: { LiquidityInput },
  data: () => {
    return {
      provider: null,
      poolInfo: {},
      poolId: '',
      tokenA: '',
      tokenAmountA: '',
      tokenB: '',
      tokenAmountB: '',
      enableMaxIn: true,
      approvalState: {},
      approvalLoading: {},
      buttonError: {
        loading: true,
        disable: true,
        text: ''
      },
      connectButtonLoading: false,
      ApprovalState
    };
  },
  computed: {
    requireLogin() {
      return !this.isAuthenticated;
    },
    account() {
      const { connector, address } = this.$store.state.account;
      if (!connector || !connector.id || !address) {
        return '';
      }
      return address;
    },
    symbolA() {
      return this.symbol(this.tokenA);
    },
    symbolB() {
      return this.symbol(this.tokenB);
    },
    raito() {
      if (!this.poolInfo.pool) {
        return { percentToken0: '50', percentToken1: '50' };
      }
      const percentToken0 = this.poolInfo.pool
        ? this.poolInfo.pool.tokenA.tokenAmount
            .dividedBy(this.poolInfo.pool.tokenA.vTokenAmount)
            .multipliedBy('100')
            .dividedBy(
              this.poolInfo.pool.tokenA.tokenAmount
                .dividedBy(this.poolInfo.pool.tokenA.vTokenAmount)
                .plus(
                  this.poolInfo.pool.tokenB.tokenAmount.div(
                    this.poolInfo.pool.tokenB.vTokenAmount
                  )
                )
            )
            .decimalPlaces(2)
            .toString()
        : '50';
      const percentToken1 = this.poolInfo.pool
        ? new BigNumber(100)
            .minus(percentToken0)
            .decimalPlaces(2)
            .toString()
        : '50';
      return { percentToken0, percentToken1 };
    },
    poolShare() {
      if (!this.poolInfo || !this.poolInfo.price) {
        return '0';
      }
      if (this.poolInfo.noLiquidity && this.poolInfo.price) {
        return '100';
      }
      let liquidityMinted;
      if (
        this.poolInfo.pool.totalSupply.isGreaterThan(0) &&
        this.tokenAmountA &&
        this.tokenAmountB
      ) {
        const [tokenAmountA, tokenAmountB] =
          wrappedToken(this.tokenA).toLowerCase() <
          wrappedToken(this.tokenB).toLowerCase()
            ? [this.tokenAmountA, this.tokenAmountB]
            : [this.tokenAmountB, this.tokenAmountA];
        const amount0 = new BigNumber(tokenAmountA)
          .multipliedBy(this.poolInfo.pool.totalSupply)
          .dividedBy(this.poolInfo.pool.tokenA.tokenAmount);
        const amount1 = new BigNumber(tokenAmountB)
          .multipliedBy(this.poolInfo.pool.totalSupply)
          .dividedBy(this.poolInfo.pool.tokenB.tokenAmount);
        const liquidity = amount0.isLessThanOrEqualTo(amount1)
          ? amount0
          : amount1;
        if (liquidity.isGreaterThan(0)) {
          liquidityMinted = liquidity;
        }
      }

      let poolTokenPercentage;
      if (liquidityMinted) {
        poolTokenPercentage = liquidityMinted
          .dividedBy(this.poolInfo.pool.totalSupply.plus(liquidityMinted))
          .multipliedBy(100);
      }
      if (!poolTokenPercentage) {
        return '0';
      }
      if (poolTokenPercentage && poolTokenPercentage.isLessThan(0.01)) {
        return '<0.01';
      }
      return poolTokenPercentage.decimalPlaces(2).toString();
    }
  },
  async mounted() {
    this.provider = await this.$store.getters['account/provider'];

    this.poolId = this.$route.params.poolId;
    const tokenA = this.$route.params.tokenA;
    const tokenB = this.$route.params.tokenB;
    this.tokenA =
      wrappedToken(tokenA).toLowerCase() < wrappedToken(tokenB).toLowerCase()
        ? tokenA
        : tokenB;
    this.tokenB =
      wrappedToken(tokenA).toLowerCase() < wrappedToken(tokenB).toLowerCase()
        ? tokenB
        : tokenA;

    this.approvalLoading[this.tokenA] = false;
    this.approvalLoading[this.tokenB] = false;

    await this.getPoolAllowances();
    const assets = this.$store.getters['assets/metadata'];
    const poolInfo = await this.getKurvePoolDetailByAddress({
      provider: this.provider,
      address: this.poolId,
      tokenA: this.tokenA,
      tokenB: this.tokenB,
      assets
    });
    this.poolInfo = poolInfo;
    this.handleButtonError();
    this.buttonError.loading = false;
  },
  methods: {
    ...mapActions(['getKurvePoolDetailByAddress', 'approve']),
    symbol(address) {
      const assets = this.$store.getters['assets/metadata'];
      const asset = assets[address];
      if (!asset) {
        return '';
      }
      return asset.symbol;
    },
    feeRangeCalc(amp) {
      let baseFee = 0;
      if (amp > 20) baseFee = 4;
      if (amp <= 20 && amp > 5) baseFee = 10;
      if (amp <= 5 && amp > 2) baseFee = 20;
      if (amp <= 2) baseFee = 30;

      return `${(baseFee / 2 / 100).toPrecision()}% - ${(
        (baseFee * 2) /
        100
      ).toPrecision()}%`;
    },
    handleInputChange({ value, type }) {
      const assets = this.$store.getters['assets/metadata'];
      if (new BigNumber(value).isNaN()) {
        this.tokenAmountA = '';
        this.tokenAmountB = '';

        return this.handleButtonError();
      }
      if (type === 'tokenIn') {
        this.tokenAmountA = new BigNumber(value).toString();
        this.tokenAmountB = new BigNumber(value)
          .multipliedBy(this.poolInfo.price.price)
          .decimalPlaces(assets[this.tokenB].decimals)
          .toString();
      } else if (type === 'tokenOut') {
        this.tokenAmountB = new BigNumber(value).toString();
        this.tokenAmountA = new BigNumber(value)
          .multipliedBy(this.poolInfo.price.invert)
          .decimalPlaces(assets[this.tokenA].decimals)
          .toString();
      }
      this.handleButtonError();
    },
    handleConnectWallet() {
      this.connectButtonLoading = true;
      this.$store.dispatch('openAccountModal');
    },
    handleButtonError() {
      const assets = this.$store.getters['assets/metadata'];
      if (!this.account) {
        this.buttonError.disable = true;
        this.buttonError.text = 'Connect Wallet';
      }
      if (
        (this.tokenAmountA === '' && this.tokenAmountB === '') ||
        new BigNumber(this.tokenAmountA).isEqualTo(0) ||
        new BigNumber(this.tokenAmountB).isEqualTo(0)
      ) {
        this.buttonError.disable = true;
        this.buttonError.text = 'Enter an amount';
        return;
      }
      if (
        new BigNumber(this.tokenAmountA).isGreaterThan(
          this.balanceOf(this.tokenA)
        )
      ) {
        this.buttonError.disable = true;
        this.buttonError.text = `Insufficient ${
          assets[this.tokenA].symbol
        } balance`;
        return;
      }
      if (
        new BigNumber(this.tokenAmountB).isGreaterThan(
          this.balanceOf(this.tokenB)
        )
      ) {
        this.buttonError.disable = true;
        this.buttonError.text = `Insufficient ${
          assets[this.tokenB].symbol
        } balance`;
        return;
      }
      this.buttonError.disable = false;
      this.buttonError.text = 'Supply';
    },
    balanceOf(address) {
      const { balances } = this.$store.state.account;
      const metadata = this.$store.getters['assets/metadata'];
      if (!balances || !metadata) {
        return '0.0';
      }
      const assetMetadata = metadata[address];
      const balance = balances[address];
      if (!assetMetadata || !balance) {
        return '0.0';
      }

      const balanceNumber = new BigNumber(balance);
      const assetDecimals = assetMetadata.decimals;
      const balanceShortNumber = scale(balanceNumber, -assetDecimals);
      const balanceInNumberLb = parseFloat(
        balanceShortNumber.toFixed(config.precision)
      );
      return balanceInNumberLb;
    },
    handleMax() {
      this.enableMaxIn = !this.enableMaxIn;
    },
    async getPoolAllowances() {
      const allowanceAddresses = [];
      if (this.tokenA !== BNB_KEY && this.tokenA !== config.addresses.weth) {
        allowanceAddresses.push(this.tokenA);
      } else {
        this.approvalState[this.tokenA] = ApprovalState.APPROVED;
      }
      if (this.tokenB !== BNB_KEY && this.tokenB !== config.addresses.weth) {
        allowanceAddresses.push(this.tokenB);
      } else {
        this.approvalState[this.tokenB] = ApprovalState.APPROVED;
      }

      const allowances = await getKurveAllowances(
        this.provider,
        this.account,
        config.kurve.addresses.routerV2,
        allowanceAddresses
      );
      allowances.map(allowance => {
        this.approvalState[allowance.address] = allowance.state;
      });
    },
    async handleAppove(address) {
      this.approvalLoading[address] = true;
      try {
        await this.approve({
          token: address,
          spender: config.kurve.addresses.routerV2
        });
        await this.getPoolAllowances();
      } catch (error) {
        console.error(error);
      }
      this.approvalLoading[address] = false;
    },
    async handleAddLiquidity() {
      const assets = this.$store.getters['assets/metadata'];
      const finalTokenAmountA = new BigNumber(this.tokenAmountA)
        .multipliedBy(new BigNumber(10).pow(assets[this.tokenA].decimals))
        .decimalPlaces(0);
      const finalTokenAmountB = new BigNumber(this.tokenAmountB)
        .multipliedBy(new BigNumber(10).pow(assets[this.tokenB].decimals))
        .decimalPlaces(0);

      const slippage = this.poolInfo.noLiquidity ? 0 : Storage.getSlippage();
      const finalMinTokenAmountA = finalTokenAmountA
        .multipliedBy(1 - slippage)
        .decimalPlaces(0);
      const finalMinTokenAmountB = finalTokenAmountB
        .multipliedBy(1 - slippage)
        .decimalPlaces(0);
      const deadline = Storage.getDeadline();

      this.buttonError.loading = true;
      this.buttonError.disable = true;
      const tx = await kurveAddLiquidity(
        this.poolId,
        this.tokenA,
        this.tokenB,
        finalTokenAmountA,
        finalTokenAmountB,
        finalMinTokenAmountA,
        finalMinTokenAmountB,
        this.account,
        new BigNumber(dayjs().valueOf())
          .dividedBy(1000)
          .plus(deadline * 60)
          .decimalPlaces(0),
        new BigNumber(slippage),
        assets,
        this.provider
      );
      await this.handleTransaction(
        tx,
        'transactionTitles.addStablecoinLiquidity'
      );
      this.$store.dispatch('account/fetchAssets', [this.tokenA, this.tokenB]);
      this.$store.dispatch('farm/getFarmUserData');
      this.buttonError.loading = false;
      this.buttonError.disable = false;
    },
    async handleTransaction(transaction, text) {
      await this.$store.dispatch('transactions/handleTransaction', {
        transaction,
        titleKey: text
      });

      this.transactionPending = false;
    },
    goBack() {
      this.$router.push(
        `/liquidity/kurve?tokenA=${this.tokenA}&tokenB=${this.tokenB}`
      );
    }
  }
};
</script>

<style scoped lang="scss">
.liquidity-add {
  background-color: white;
  border-radius: 20px;
  width: 500px;
  margin: 0px auto;
}
.head-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .title {
    font-size: 25px;
  }
}
.b-dashed {
  border-style: dashed !important;
}
.info {
  justify-content: space-between;
  padding: 16px 0px;
}
.back-icon {
  cursor: pointer;
  position: absolute;
  left: 0;
}
.liquidity-button {
  margin: 10px 0px;
  button {
    border: none;
    border-radius: 10px;
    background-color: #ff6871;
    color: white;
    font-size: 18px;
    padding: 16px 0px;
    width: 100%;
  }
  button.bg-blue {
    background-color: #08a1e7;
  }
}
.btn-disable {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>
