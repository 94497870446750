<template>
  <Page>
    <Container>
      <Title :title="$t('proposalDetail', { id: voteDetailId })" />
      <div class="d-flex">
        <div class="proposal-card">
          <ProposalCard
            :typeProposalCard="'related'"
            :proposalInfo="proposalInfo"
          />
          <div class="proposal-user">
            <div
              :class="_isThemeGame ? 'game' : ''"
              class="d-flex justify-content-between title-table-user"
            >
              <div>User</div>
              <div>Vote</div>
            </div>
            <template v-if="votersList && votersList.length > 0">
              <div
                v-for="(voter, index) in votersList"
                :key="index"
                class="d-flex justify-content-between content-voter"
              >
                <div :class="_isThemeGame ? 'game' : ''">
                  <div class="content-tx">
                    {{ _shortenAddress(voter.voter) }}
                  </div>
                  <div
                    class="proposal-type"
                    :class="_isThemeGame ? 'game' : ''"
                    v-if="voter.voter === proposalInfo.userAddress"
                  >
                    Creator of this proposal
                  </div>
                </div>
                <div
                  class="d-flex align-items-center"
                  :class="_isThemeGame ? 'game' : ''"
                >
                  <div class="content-vote">
                    <span class="down-vote-txt" v-if="!voter.isUpvote">
                      Down Voted
                      <template v-if="voter.totalWeight">
                        with weight {{ voter.totalWeight }}
                      </template>
                    </span>
                    <span class="up-vote-txt" v-if="voter.isUpvote">
                      Up Voted
                      <template v-if="voter.totalWeight">
                        with weight {{ voter.totalWeight }}
                      </template>
                    </span>
                    <span class="vote-time">
                      ({{ timeSince(voter.blockTimestamp * 1000) }})
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </Container>
  </Page>
</template>

<script>
import ProposalCard from '@/components/Vote/ProposalCard.vue';
import Page from '@/components/Page.vue';
import Container from '@/components/Container.vue';
import RequestFactory from '@/requests/RequestFactory';
import { timeSince } from '@/helpers/utils';

export default {
  data() {
    return {
      voteDetailId: null,
      proposalInfo: {},
      votersList: []
    };
  },
  components: {
    ProposalCard,
    Page,
    Container
  },
  computed: {
    myAddress() {
      return this.$store.state.account.address;
    }
  },
  methods: {
    timeSince,
    async fetchProposalById(proposalId) {
      const res = await RequestFactory.getRequest(
        'VoteRequest'
      ).getProposalById(proposalId);
      this.proposalInfo = res || {};
    },
    async fetchVoterList(proposalId) {
      const res = await RequestFactory.getRequest(
        'VoteRequest'
      ).getProposalVotersById(proposalId);
      this.votersList = res.docs || [];
    }
  },
  created() {
    this.voteDetailId = this.$route.params.id;
  },
  async mounted() {
    await this.fetchProposalById(this.voteDetailId);
    await this.fetchVoterList(this.voteDetailId);
  }
};
</script>

<style lang="scss" scoped>
.proposal-card {
  width: 70%;

  .proposal-user {
    background: var(--panel-background);
    border-radius: 10px;
    margin-top: 30px;
    font-family: Lato-Regular;

    .title-table-user {
      padding: 0 24px;
      font-size: 13px;
      font-weight: 600;
      line-height: 40px;
      color: var(--text-color-liquidity);
      opacity: 0.4;
      border-bottom: 1px solid rgba(204, 204, 204, 0.3);
    }

    .content-voter {
      color: var(--text-color-liquidity);
      border-bottom: 1px solid rgba(204, 204, 204, 0.3);
      padding: 16px 24px;
      .content-tx {
        font-size: 15px;
        font-weight: 400;
      }

      .content-vote {
        font-size: 15px;
        font-weight: 500;
        .down-vote-txt {
          color: #f23e32;
        }
        .up-vote-txt {
          color: #4fba6f;
        }
      }
    }

    .proposal-type {
      margin-top: 8px;
      font-family: $font-weight-regular;
      font-weight: normal;
      font-size: 12px;
      color: #f47820;
      background: rgba(244, 120, 32, 0.1);
      border-radius: 4px;
      padding: 5px 8px;
      width: fit-content;
    }
  }
}

.submit-vote {
  width: 30%;
}

@media only screen and (max-width: 768px) {
  .proposal-card {
    width: 100%;
  }
}
</style>
