<template>
  <div class="d-flex flex-column mb-2">
    <div class="balance mb-1">Balance: {{ balance }}</div>
    <div class="liquidity-input-wrapper d-flex border pl-3 pr-2 py-3">
      <div class="liquidity-input d-flex">
        <input
          class="w-50 mr-3"
          type="number"
          placeholder="0.0"
          :value="amount"
          @input="handleChange($event.target.value)"
        />
        <div
          v-if="showMax"
          class="max-button hand border rounded mr-2 px-3 py-2"
          @click="handleInputMax"
        >
          Max
        </div>
      </div>
      <div class="asset d-flex border rounded mr-2 p-2">
        <div class="icon-wrapper line-20">
          <AssetIcon v-if="tokenA" class="asset-icon" :address="tokenA" />
          <AssetIcon v-if="tokenB" class="asset-icon" :address="tokenB" />
        </div>
        {{ symbolA }} : {{ symbolB }}
      </div>
    </div>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
import AssetIcon from '@/components/swap/AssetIcon.vue';
import { getKurvePoolBalanceByAddress } from '@/helpers/kurve';
export default {
  name: 'LiquidityPoolInput',
  components: { AssetIcon },
  data: () => {
    return {
      balance: '0.0',
      showMax: true
    };
  },
  props: {
    pool: {
      type: String,
      required: true
    },
    tokenA: {
      type: String,
      required: true
    },
    tokenB: {
      type: String,
      required: true
    },
    enableMax: {
      type: Boolean,
      default: false
    },
    amount: {
      type: String,
      default: ''
    }
  },
  computed: {
    account() {
      const { connector, address } = this.$store.state.account;
      if (!connector || !connector.id || !address) {
        return '';
      }
      return address;
    },
    symbolA() {
      return this.symbol(this.tokenA);
    },
    symbolB() {
      return this.symbol(this.tokenB);
    }
  },
  mounted() {
    this.showMax = this.enableMax;
    setTimeout(() => {
      this.fetchPoolBalance();
    }, 500);
    setInterval(() => {
      this.fetchPoolBalance();
    }, 10000);
  },
  methods: {
    async fetchPoolBalance() {
      if (!this.pool || !this.account) {
        return;
      }
      const provider = await this.$store.getters['account/provider'];
      const balance = await getKurvePoolBalanceByAddress(
        provider,
        this.pool,
        this.account
      );
      this.balance = new BigNumber(balance.toString())
        .dividedBy(new BigNumber(10).pow(18))
        .toString();
    },
    handleChange(value) {
      if (new BigNumber(value).isLessThan(this.balance)) {
        this.showMax = false;
        this.$emit('handle-input-change', { value });
      } else {
        if (this.enableMax) {
          this.showMax = true;
        }
        this.$emit('handle-input-change', { value: '' });
      }
    },
    handleInputMax() {
      this.showMax = false;
      this.$emit('handle-input-change', {
        value: this.balance
      });
      this.$emit('handle-max');
    },
    symbol(address) {
      const assets = this.$store.getters['assets/metadata'];
      const asset = assets[address];
      if (!asset) {
        return '';
      }
      return asset.symbol;
    }
  }
};
</script>

<style lang="scss" scoped>
.liquidity-input-wrapper {
  border-radius: 10px;
  justify-content: space-between;
}
.liquidity-input {
  flex: 1;
  justify-content: space-between;
  input {
    flex: 1;
    font-size: 18px;
  }
}
.hand {
  cursor: pointer;
  text-align: center;
}
.balance {
  text-align: right;
}
.max-button {
  width: 65px;
}
.asset {
  justify-content: center;
  align-items: center;
}
.icon-wrapper {
  margin-right: 5px;
}
.asset-icon {
  width: 18px;
  height: 18px;
}
</style>
