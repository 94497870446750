<template>
  <div>
    <div class="display py-3 text-center">
      <img v-if="!_isThemeGame" src="~@/assets/icon/no-search.svg" />
      <img v-else src="~@/assets/icon/game/no-search.png" />
      <div :class="`mt-4 ${_isThemeGame ? 'game' : ''}`">
        {{ $t('noVestingFound') }}
      </div>
    </div>
    <!--    <div class="position">-->
    <!--      <ConnectButton class="connect-button" />-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'SwapKalancer'
};
</script>

<style lang="scss" scoped>
.position {
  display: flex;
  justify-content: center;
}
.connect-button {
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.game-button {
  height: 30px !important;
  line-height: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    height: 20px !important;
    padding: 0 30px !important;
    line-height: 20px;
  }
}

.display {
  justify-content: center;
  flex-direction: column;
}
</style>
