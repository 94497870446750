<template>
  <UiTable class="p-4 table-details class-format">
    <div v-if="bPool.config.about" class="mb-3">
      <div
        v-text="$t('description')"
        class="mb-2 about-title"
        style="max-width: 380px !important;"
      />
      <div
        v-text="bPool.config.about"
        class="text mb-2"
        :class="_isThemeGame ? 'game-about-text' : 'about-text'"
        style="max-width: 580px;"
      />
      <div
        v-if="bPool.config.learn_more"
        class="link"
        style="margin-left: 0px !important;"
      >
        <a :href="bPool.config.learn_more" target="_blank">
          {{ $t('learnMore') }}
          <Icon
            v-if="!_isThemeGame"
            name="external-link"
            size="16"
            class="ml-1 mr-2"
          />
          <img
            v-else
            src="~/@/assets/icon/game/link.png"
            class="icon-link-game"
          />
        </a>
      </div>
    </div>
    <div class="mb-3">
      <div v-text="$t('poolType')" class="mb-2 about-title" />
      <div
        v-text="$t(`${bPool.getTypeStr()}`)"
        class="text "
        :class="_isThemeGame ? 'game-about-text' : 'about-text'"
      />
    </div>
    <div v-if="bPool.metadata.tokens.length == 0">
      <div class="d-flex flex-items-center p-2 warning-box">
        <Icon name="warning" size="22" class="mr-4" style="margin-left: 20px" />
        <div v-text="$t('deadPoolWarning')" />
      </div>
      <br />
    </div>
    <div v-if="bPool.isCrp()" class="mb-3">
      <div v-text="$t('rights')" class="mb-2 about-title" />
      <template v-if="Object.keys(rights).length > 0">
        <div v-for="(right, key) in rights" :key="key">
          <div
            v-text="$t(`${poolRights[key]}`)"
            class="text mb-1"
            :class="_isThemeGame ? 'game-about-text' : 'about-text'"
          />
        </div>
      </template>
      <div
        v-else
        v-text="$t('none')"
        class="text "
        :class="_isThemeGame ? 'game-about-text' : 'about-text'"
      />
    </div>
    <div v-if="ongoingUpdate" class="mb-3">
      <div
        v-if="this.networkdata.blockNumber > 0"
        class="d-flex flex-items-center p-2 warning-box"
        style="max-width: 380px !important;"
      >
        <Icon name="warning" size="22" class="mr-4" style="margin-left: 20px" />
        <div
          v-if="updateFinished"
          v-text="$t('updateFinishedWarning', { endTime })"
        />
        <div
          v-else
          v-html="
            $t('ongoingUpdate', {
              startTime,
              startBlock: bPool.metadata.startBlock,
              endTime,
              endBlock: bPool.metadata.endBlock
            })
          "
        />
      </div>
      <div v-else class="d-flex flex-items-center p-2 warning-box">
        <Icon name="warning" size="22" class="mr-4" style="margin-left: 20px" />
        <div v-text="$t('ongoingUpdateLoading')" />
      </div>
    </div>
    <div v-if="bPool.isCrp() && lbpData.isLbpPool">
      <div
        v-text="
          `${$t('currentPrice', { token: lbpData.projectToken })}: ${_num(
            lbpData.lbpPrice,
            'usd'
          )}`
        "
        format="currency"
        class="text"
        :class="_isThemeGame ? 'game-about-text' : 'about-text'"
      />
      <br />
    </div>
    <div v-if="rights.canChangeWeights" class="mb-3">
      <div v-text="$t('minimumUpdatePeriod')" class="mb-2 about-title" />
      <div
        v-text="_num(bPool.metadata.minimumWeightChangeBlockPeriod)"
        class="text"
        :class="_isThemeGame ? 'game-about-text' : 'about-text'"
      />
    </div>
    <div v-if="rights.canAddRemoveTokens" class="mb-3">
      <div v-text="$t('addTokenTimelock')" class="mb-2 about-title" />
      <div
        v-text="_num(bPool.metadata.addTokenTimeLockInBlocks)"
        class="text"
        :class="_isThemeGame ? 'game-about-text' : 'about-text'"
      />
    </div>
    <div v-if="rights.canChangeCap" class="mb-3">
      <div v-html="$t('cap')" class="mb-2 about-title" />
      <div
        class="text"
        :class="_isThemeGame ? 'game-about-text' : 'about-text'"
      >
        <div
          v-if="bPool.metadata.bspCap.toString() === MAX"
          v-text="$t('unlimited')"
        />
        <div v-else v-text="_num(bPool.metadata.bspCap)" />
      </div>
    </div>
    <div class="mb-3">
      <div
        v-text="bPool.metadata.finalized ? $t('creator') : $t('controller')"
        class="mb-2 about-title"
      />
      <div>
        <a
          :href="_etherscanLink(bPool.metadata.controller, 'token')"
          target="_blank"
          class="text link"
          :class="_isThemeGame ? 'game-about-text' : 'about-text'"
        >
          <Avatar :address="bPool.metadata.controller" class="mr-1" />
          {{ _shortenAddress(bPool.metadata.controller) }}
          <Icon
            v-if="!_isThemeGame"
            name="external-link"
            size="16"
            class="ml-1 link"
          />
          <img
            v-else
            src="~/@/assets/icon/game/link.png"
            class="icon-link-game"
          />
        </a>
      </div>
    </div>
    <div v-if="bPool.isCrp() && bPool.metadata.crpController" class="mb-3">
      <div v-text="$t('smartPoolController')" class="mb-2 about-title" />
      <div>
        <a
          :href="_etherscanLink(bPool.metadata.crpController)"
          target="_blank"
          class="text link"
          :class="_isThemeGame ? 'game-about-text' : 'about-text'"
        >
          <Avatar :address="bPool.metadata.crpController" class="mr-1" />
          {{ _shortenAddress(bPool.metadata.crpController) }}
          <Icon
            v-if="!_isThemeGame"
            name="external-link"
            size="16"
            class="ml-1 link"
          />
          <img
            v-else
            src="~/@/assets/icon/game/link.png"
            class="icon-link-game"
          />
        </a>
      </div>
    </div>
    <div class="mb-3">
      <div v-text="$t('creationDate')" class="mb-2 about-title" />
      <div>
        <a
          :href="_etherscanLink(bPool.metadata.tx, 'tx')"
          target="_blank"
          class="text link"
          :class="_isThemeGame ? 'game-about-text' : 'about-text'"
        >
          {{ $d(bPool.metadata.createTime * 1e3, 'long') }}
          <Icon
            v-if="!_isThemeGame"
            name="external-link"
            size="16"
            class="ml-1 link"
          />
          <img
            v-else
            src="~/@/assets/icon/game/link.png"
            class="icon-link-game"
          />
        </a>
      </div>
    </div>
    <template v-if="bPool.metadata.finalized">
      <div class="mb-3">
        <div v-text="$t('bptAsset')" class="mb-2 about-title" />
        <div>
          <a
            :href="_etherscanLink(bPool.address, 'token')"
            target="_blank"
            class="text link"
            :class="_isThemeGame ? 'game-about-text' : 'about-text'"
          >
            <Token :address="bPool.address" class="v-align-middle mr-1" />
            {{ _shortenAddress(bPool.address) }}
            <Icon
              v-if="!_isThemeGame"
              name="external-link"
              size="16"
              class="ml-1 link"
            />
            <img
              v-else
              src="~/@/assets/icon/game/link.png"
              class="icon-link-game"
            />
          </a>
        </div>
      </div>
      <div class="mb-3">
        <div v-text="$t('bptTotalSupply')" class="mb-2 about-title" />
        <div
          v-text="_num(bPool.metadata.totalShares)"
          class="about-text"
          :class="_isThemeGame ? 'game-about-text' : ''"
        />
      </div>
    </template>

    <div class="mb-3">
      <div v-text="$t('publicSwap')" class="mb-2 about-title" />
      <div
        v-text="bPool.metadata.publicSwap ? $t('enabled') : $t('disabled')"
        class="text"
        :class="_isThemeGame ? 'game-about-text' : 'about-text'"
      />
    </div>
    <div class="mb-3">
      <div v-text="$t('swapFee')" class="mb-2 about-title" />
      <div
        v-text="_num(bPool.metadata.swapFee, 'percent')"
        class="text"
        :class="_isThemeGame ? 'game-about-text' : 'about-text'"
      />
    </div>
    <div class="mb-3">
      <div v-text="$t('totalSwapVolume')" class="mb-2 about-title" />
      <div
        v-text="_num(bPool.metadata.totalSwapVolume, 'usd')"
        class="text"
        :class="_isThemeGame ? 'game-about-text' : 'about-text'"
      />
    </div>
    <div class="mb-3">
      <div v-text="$t('totalSwapFee')" class="mb-2 about-title" />
      <div
        v-text="_num(bPool.metadata.totalSwapFee, 'usd')"
        class="text"
        :class="_isThemeGame ? 'game-about-text' : 'about-text'"
      />
    </div>
  </UiTable>
</template>

<script>
import {
  filterObj,
  poolRights,
  MAX,
  blockNumberToTimestamp
} from '@/helpers/utils';
import { mapActions } from 'vuex';
import { getLbpData } from '@/helpers/lbpData';

export default {
  props: ['bPool', 'pool'],
  data() {
    return {
      poolRights,
      MAX,
      page: 0,
      swaps: []
    };
  },
  computed: {
    rights() {
      return filterObj(this.bPool.metadata.rights, right => right[1]);
    },
    ongoingUpdate() {
      return this.bPool.isCrp() && this.bPool.metadata.startBlock !== '0';
    },
    lbpData() {
      return getLbpData(this.pool, this.config.chainId);
    },
    updateFinished() {
      return (
        this.ongoingUpdate &&
        this.networkdata.blockNumber >= this.bPool.metadata.endBlock
      );
    },
    startTime() {
      return this.blockDate(this.bPool.metadata.startBlock);
    },
    endTime() {
      return this.blockDate(this.bPool.metadata.endBlock);
    }
  },
  methods: {
    ...mapActions(['getLbpSwaps']),
    blockDate(block) {
      const blockTimestamp = blockNumberToTimestamp(
        Date.now(),
        this.networkdata.blockNumber,
        block
      );
      return new Date(blockTimestamp).toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../vars.scss';
.link {
  color: $primary-button-text;
}
.about-title {
  color: var(--button-color-checkbox);
}

.about-text {
  font-size: 14px;
  line-height: 22px;
  color: var(--text-color);
  font-family: $font-weight-regular;
}

.game-about-text {
  font-family: $font-forward;
  font-size: 12px;
  line-height: 22px;
  color: var(--text-color);
}
.class-format.table {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 500px !important;
  background: var(--panel-background);
}

.game-border-table {
  .about-title {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 768px) {
  .class-format.table {
    display: inherit !important;
    max-height: 100% !important;
  }
}
</style>
