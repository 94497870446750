var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UiModal',{staticClass:"modal-activity",staticStyle:{"max-width":"440px"},attrs:{"open":_vm.open},on:{"close":function($event){return _vm.$emit('close')}}},[_c('h3',{staticClass:"m-4 mb-0 text-center text-header",class:_vm._isThemeGame && 'game-text-header',domProps:{"textContent":_vm._s(_vm.$t('pendingTransactions'))}}),_c('Block',{class:("m-4 " + (_vm._isThemeGame ? 'game-border-table' : 'border-block-activity'))},[(_vm.myPendingTransactions.length)?_c('div',_vm._l((_vm.myPendingTransactions),function(tx,i){return _c('div',{key:i,staticClass:"border-top px-4 py-3 d-flex",class:_vm._isThemeGame && 'game',style:(i === 0 && 'border: 0 !important;')},[_c('div',{staticClass:"flex-auto"},[_c('a',{staticClass:"color-link",staticStyle:{"font-size":"16px"},attrs:{"href":_vm._etherscanLink(tx.hash, 'tx'),"target":"_blank"}},[_c('h5',{staticClass:"d-inline-block mb-3",class:_vm._isThemeGame && 'game-link',domProps:{"textContent":_vm._s(
                tx.title
                  ? _vm.$t(
                      tx.title + '.title',
                      _vm.getTitleParams(tx).amount
                        ? {
                            amount: _vm._num(_vm.getTitleParams(tx).amount)
                          }
                        : _vm.getTitleParams(tx)
                    )
                  : _vm._shortenAddress(tx.hash)
              )}}),(!_vm._isThemeGame)?_c('Icon',{staticClass:"ml-1 mr-2",attrs:{"name":"external-link","size":"16"}}):_c('img',{staticClass:"icon-link-game",attrs:{"src":require("@/assets/icon/game/link.png")}})],1),_c('div',{class:_vm._isThemeGame ? 'game' : 'text-activity',domProps:{"textContent":_vm._s(_vm.$d(new Date(tx.timestamp), 'long'))}})]),_c('div',{class:[
            tx.status === 1 && 'text-green',
            _vm._isThemeGame ? 'game' : 'text-activity'
          ],domProps:{"textContent":_vm._s(
            !tx.status ? 'Pending' : tx.status === 1 ? 'Success' : 'Failed'
          )}})])}),0):_c('h5',{staticClass:"text-center p-4",class:_vm._isThemeGame ? 'game' : 'text-activity'},[_vm._v(" "+_vm._s(_vm.$t('noPendingTransactions'))+" ")])]),(_vm.myPendingTransactions.length)?_c('div',{staticClass:"text-center mb-4"},[_c('UiButton',{class:[_vm._isThemeGame ? 'game-border-btn-add' : '', 'button-activity'],domProps:{"textContent":_vm._s(_vm.$t('clearAll'))},on:{"click":_vm.clearTransactions}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }