<template>
  <div class="px-0 px-md-5 py-4 flex-auto">
    <div
      class="title-stablecoins d-flex justify-content-between align-items-center"
    >
      <Title :title="$t('stableCoins')" />
      <div>
        <div
          :class="
            `filter-stablecoins ${_isThemeGame ? 'game-border-btn-search' : ''}`
          "
        >
          <input
            v-model="searchPoolAddress"
            class="search-stablecoin"
            :class="`${_isThemeGame ? 'game-placeholder query-input' : ''}`"
            type="text"
            :placeholder="$t('searchToken')"
          />
          <Icon v-if="!_isThemeGame" name="search-1" size="16" />
          <img
            v-else
            src="~@/assets/icon/game/search-icon.png"
            style="width: 25px"
          />
        </div>
      </div>
    </div>
    <UiTable class="table-pools mt-4">
      <UiTableTh>
        <div
          v-text="$t('poolAddress')"
          class="column-sm hide-sm hide-md hide-lg text-left"
          style="min-width:110px!important;"
        />
        <div v-text="$t('assets')" class="flex-auto text-left " />
        <div v-html="$t('marketCap')" class="stablecoin-column" />
        <div
          v-html="$t('swapFee24h')"
          class="stablecoin-column hide-sm hide-md"
        />
        <div
          class="stablecoin-sm-column hide-sm hide-md hide-lg"
          v-text="$t('1yfl')"
        ></div>
        <div
          class="stablecoin-column hide-sm hide-md hide-lg"
          v-html="$t('myLiquidity')"
        ></div>
        <div
          v-text="$t('amp')"
          class="stablecoin-sm-column hide-sm hide-md hide-lg"
        />
        <div
          v-html="$t('volume24')"
          class="stablecoin-column hide-sm hide-md hide-lg"
        />
      </UiTableTh>
      <div v-if="poolsToDisplay.length > 0 && !loading">
        <liquidity-item
          v-for="(pool, i) in poolsToDisplay"
          :key="i"
          :pool="pool"
          :liquidityPosition="transformedUserLiquidityPositions[pool.id] || {}"
        />
      </div>
      <UiTableTr v-else-if="!loading">
        <div v-text="$t('emptyState')" />
      </UiTableTr>
      <ListLoading
        v-if="loading"
        :classes="[
          'column-sm text-left hide-sm hide-md hide-lg',
          'flex-auto text-left',
          'stablecoin-column hide-sm hide-md',
          'stablecoin-column hide-sm hide-md',
          'stablecoin-sm-column hide-sm hide-md',
          'stablecoin-column',
          'stablecoin-sm-column hide-sm hide-md hide-lg',
          'stablecoin-column hide-sm hide-md hide-lg'
        ]"
        :height="29"
      />
    </UiTable>
    <modal-asset-selector
      :open="isModalOpen"
      :hidden="[tokenA, tokenB]"
      :forKurve="true"
      @select="handleTokenSelect"
    />
  </div>
</template>

<script>
import * as _ from 'lodash';
import { mapActions } from 'vuex';
import ModalAssetSelector from '@/components/swap/modals/ModalAssetSelector.vue';
import LiquidityItem from '@/components/Kurve/LiquidityItem.vue';
export default {
  metaInfo: {
    title: 'Liquidity - Stablecoins'
  },
  name: 'LiquidityKurve',
  components: { ModalAssetSelector, LiquidityItem },
  data: () => {
    return {
      provider: null,
      loading: true,
      searchPoolAddress: '',
      tokenA: '',
      tokenB: '',
      pools: [],
      transformedUserLiquidityPositions: {}
    };
  },
  computed: {
    poolsToDisplay() {
      return this.pools.filter(pool =>
        `${pool.token0.symbol}-${pool.token1.symbol}`
          .toLowerCase()
          .includes(this.searchPoolAddress.trim().toLowerCase())
      );
    },
    isModalOpen() {
      return this.$store.state.ui.modal.asset.isOpen;
    },
    account() {
      const { connector, address } = this.$store.state.account;
      if (!connector || !connector.id || !address) {
        return '';
      }
      return address;
    }
  },
  async mounted() {
    this.tokenA = this.$route.query.tokenA || '';
    this.tokenB = this.$route.query.tokenB || '';

    const fetchAll = !this.tokenA && !this.tokenB;
    this.provider = await this.$store.getters['account/provider'];
    await this.loadPools(fetchAll);
  },
  methods: {
    ...mapActions([
      'getEthPriceForKurve',
      'getKurveBulkPoolsData',
      'getKurveUserLiquidityPositions'
    ]),
    symbol(address) {
      const assets = this.$store.getters['assets/metadata'];
      const asset = assets[address];
      if (!asset) {
        return '';
      }
      return asset.symbol;
    },
    openAssetModal(modalKey) {
      this.$store.dispatch('openAssetModal', modalKey);
    },
    async loadPools(fetchAll, poolTarget) {
      this.loading = true;
      this.pools = [];
      this.transformedUserLiquidityPositions = {};

      try {
        const [ethPrice] = await this.getEthPriceForKurve();
        const [pools, userLiquidityPositions] = await Promise.all([
          this.getKurveBulkPoolsData({
            provider: this.provider,
            tokenA: this.tokenA,
            tokenB: this.tokenB,
            ethPrice: ethPrice,
            poolAddress: poolTarget,
            fetchAll
          }),
          this.getKurveUserLiquidityPositions({
            account: this.account
          })
        ]);

        this.pools = _.sortBy(pools, ['amp']);
        userLiquidityPositions.forEach(position => {
          if (
            !this.transformedUserLiquidityPositions[position.pool.id] ||
            position.timestamp >
              this.transformedUserLiquidityPositions[position.pool.id].timestamp
          ) {
            this.transformedUserLiquidityPositions[position.pool.id] = position;
          }
        });
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    handleTokenSelect(assetAddress) {
      const assetModalKey = this.$store.state.ui.modal.asset.key;
      if (assetModalKey === 'input') {
        this.tokenA = assetAddress;
        this.$router.push({
          path: `/liquidity/kurve?tokenA=${assetAddress}&tokenB=${this.tokenB}`
        });
      }
      if (assetModalKey === 'output') {
        this.tokenB = assetAddress;
        this.$router.push({
          path: `/liquidity/kurve?tokenA=${this.tokenA}&tokenB=${assetAddress}`
        });
      }

      if (this.tokenA && this.tokenB) {
        this.loadPools(false, null);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.filter-stablecoins {
  height: 35px;
  box-shadow: inset 1px 2px 3px var(--border-shadow-search);
  border-radius: 25px;
  border: 1px solid var(--border-search);
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin: 0 !important;

  input {
    width: 120px;
    color: var(--text-color);
  }

  i {
    color: var(--button-color-checkbox-create);
    margin-left: 10px;
  }
}

.filter-stablecoins.game-border-btn-search {
  border-radius: 0 !important;
}

@media only screen and (max-width: 768px) {
  .title-stablecoins {
    padding: 0 25px;
    flex-direction: column;
    align-items: flex-start !important;

    div {
      width: 100%;
    }
  }

  .filter-stablecoins {
    width: 100% !important;
    margin-top: 10px;

    input {
      width: 100% !important;
    }
  }
}
</style>
