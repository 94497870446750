<template>
  <div
    :class="
      `table-setting panel-background p-4 ${
        _isThemeGame ? 'game-border-table' : ''
      }`
    "
  >
    <div
      v-if="bPool.metadata.rights.canPauseSwapping"
      class="border-bottom mb-4 pb-3 d-flex justify-content-between align-items-center"
    >
      <div>
        <div v-text="$t('publicSwap')" class="mb-2 text-label" />
        <h5
          class="text-content"
          v-text="bPool.metadata.publicSwap ? $t('enabled') : $t('disabled')"
        />
      </div>

      <div>
        <UiButton
          v-text="$t('toggle')"
          @click="modalOpen.publicSwap = true"
          :class="_isThemeGame ? 'game-border-btn-add' : ''"
        />
      </div>
    </div>
    <div
      v-if="bPool.metadata.rights.canChangeSwapFee"
      class="border-bottom mb-4 pb-3  d-flex justify-content-between align-items-center"
    >
      <div>
        <div v-text="$t('swapFee')" class="mb-2 text-label" />
        <h5 v-text="_num(pool.swapFee, 'percent')" class="text-content" />
      </div>
      <div>
        <UiButton
          v-text="$t('change')"
          @click="modalOpen.swapFee = true"
          :class="_isThemeGame ? 'game-border-btn-add' : ''"
        />
      </div>
    </div>
    <div
      v-if="bPool.metadata.rights.canChangeWeights"
      class="border-bottom mb-4 pb-3 controller d-flex "
    >
      <div v-text="$t('manageWeights')" class="mb-2 text-label" />
      <div class="mr-2">
        <UiButton
          class="mr-2"
          :class="_isThemeGame ? 'game-border-btn-add' : ''"
          v-text="$t('updateGradually')"
          @click="modalOpen.gradualWeights = true"
        />
        <UiButton
          class="mr-2"
          v-text="$t('update')"
          :disabled="ongoingUpdate || this.pool.startBlock !== '0'"
          @click="modalOpen.weights = true"
          :class="_isThemeGame ? 'game-border-btn-add mx-4' : ''"
        />
        <UiButton
          :disabled="!ongoingUpdate"
          v-text="$t('poke')"
          @click="handlePokeWeights()"
          :class="_isThemeGame ? 'game-border-btn-add' : ''"
        />
      </div>
    </div>
    <!-- <div
      v-if="bPool.metadata.rights.canAddRemoveTokens"
      class="border-bottom mb-4 pb-3 d-flex justify-content-between align-items-center"
    >
      <div>
        <label v-text="$t('tokens')" class="d-block mb-2 text-label" />
        <div class="overflow-hidden">
          <span
            v-for="(token, i) in bPool.metadata.tokens"
            :key="i"
            class="float-left d-flex flex-items-center mr-3"
          >
            <Token :address="token.checksum" class="mr-2" />
            <span v-text="_ticker(token.checksum)" class="text-content" />
          </span>
        </div>
      </div>
      <div>
        <UiButton
          v-text="$t('change')"
          :disabled="ongoingUpdate"
          @click="modalOpen.tokens = true"
          :class="_isThemeGame ? 'game-border-btn-add' : ''"
        />
      </div>
    </div> -->
    <div
      v-if="bPool.metadata.rights.canChangeCap"
      class="border-bottom mb-4 pb-3 d-flex justify-content-between align-items-center"
    >
      <div>
        <label v-html="$t('cap')" class="d-block mb-2 text-label" />
        <div class="text-content">
          <div v-if="pool.bspCap === MAX" v-text="$t('unlimited')" />
          <div v-else v-text="_num(pool.bspCap)" />
        </div>
      </div>
      <div>
        <UiButton
          v-text="$t('change')"
          @click="modalOpen.cap = true"
          :class="_isThemeGame ? 'game-border-btn-add' : ''"
        />
      </div>
    </div>
    <div
      v-if="bPool.metadata.rights.canWhitelistLPs"
      class="border-bottom mb-4 pb-3"
    >
      <div class="float-right">
        <UiButton
          v-text="$t('manage')"
          @click="modalOpen.manageWhitelist = true"
          :class="_isThemeGame ? 'game-border-btn-add' : ''"
        />
      </div>
      <label v-text="$t('manageWhitelist')" class="d-block mb-2 text-label" />
      <br />
    </div>
    <div class="controller d-flex">
      <div>
        <label v-text="$t('controller')" class="d-block mb-2 text-label" />
        <h5 v-text="pool.crpController" class="text-content" />
      </div>
      <div>
        <UiButton
          v-text="$t('change')"
          @click="modalOpen.controller = true"
          :class="_isThemeGame ? 'game-border-btn-add' : ''"
        />
      </div>
    </div>
    <MessageError
      v-if="this.transactionReverted"
      :text="$t('txReverted')"
      class="mt-4"
    />
    <portal to="modal">
      <!-- <ModalEditTokens
        :pool="bPool"
        :open="modalOpen.tokens"
        @close="closeModal('tokens')"
      /> -->
      <ModalEditPublicSwap
        :pool="pool"
        :value="pool.publicSwap"
        :open="modalOpen.publicSwap"
        @close="closeModal('publicSwap')"
      />
      <ModalEditSwapFee
        :pool="pool"
        :value="convertToPercentValue(pool.swapFee)"
        :open="modalOpen.swapFee"
        @close="closeModal('swapFee')"
      />
      <ModalEditWeights
        :pool="pool"
        :open="modalOpen.weights"
        @close="closeModal('weights')"
      />
      <ModalEditWeightsGradually
        :bPool="bPool"
        :pool="pool"
        :open="modalOpen.gradualWeights"
        @close="closeModal('gradualWeights')"
      />
      <ModalEditController
        :value="pool.crpController"
        :pool="pool"
        :open="modalOpen.controller"
        @close="closeModal('controller')"
      />
      <ModalEditCap
        :value="pool.bspCap"
        :pool="pool"
        :open="modalOpen.cap"
        @close="closeModal('cap')"
      />
      <ModalManageWhitelist
        :pool="pool"
        :open="modalOpen.manageWhitelist"
        @close="modalOpen.manageWhitelist = false"
      />
    </portal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { isTxReverted, MAX } from '@/helpers/utils';
import BigNumber from 'bignumber.js';

export default {
  props: ['pool', 'bPool'],
  data() {
    return {
      modalOpen: {
        // tokens: false,
        swapFee: false,
        publicSwap: false,
        weights: false,
        gradualWeights: false,
        controller: false,
        cap: false,
        manageWhitelist: false
      },
      transactionReverted: false,
      MAX
    };
  },
  watch: {
    open() {
      this.transactionReverted = false;
    }
  },
  computed: {
    ongoingUpdate() {
      return (
        this.pool.startBlock !== '0' &&
        parseInt(this.networkdata.blockNumber) >= parseInt(this.pool.startBlock)
      );
    },

    isController() {
      return (
        this.$store.state.account.address &&
        this.pool.crpController &&
        this.$store.state.account.proxy &&
        this.pool.crpController &&
        this.$store.state.account.proxy.toLowerCase() ===
          this.pool.crpController.toLowerCase() &&
        this.pool.tokens.length > 0
      );
    }
  },
  beforeMount() {
    if (!this.$store.state.account.address || !this.isController) {
      this.$router.push({ name: 'tradeTokens' });
    }
  },
  methods: {
    ...mapActions(['pokeWeights']),
    convertToPercentValue(value) {
      return new BigNumber(value).times(100).toString();
    },
    async handlePokeWeights() {
      const txResult = await this.pokeWeights({
        poolAddress: this.bPool.metadata.controller
      });
      if (isTxReverted(txResult)) {
        this.transactionReverted = true;
      }
    },
    closeModal(key) {
      this.modalOpen[key] = false;
      this.$root.$emit('RELOAD_POOL');
    }
  }
};
</script>
<style lang="scss" scoped>
.game-border-table {
  .border-bottom {
    border-bottom: 3px solid #878787 !important;
  }

  .game-border-btn-add {
    border-radius: 0 !important;
    height: 30px;
    border: 0 !important;
    background-color: $color-primary;
    color: $text-white;
    z-index: 1 !important;

    &:hover {
      background-color: $game-btn-hover-color !important;
      border: 0 !important;
    }

    &:disabled {
      &:hover {
        background-color: $color-primary !important;
      }
    }
  }
}

.table-setting {
  border: 1px solid var(--color-border);
  border-radius: 15px;
}

.text-label {
  color: var(--text-color);
  opacity: 0.3;
}

.text-content {
  color: var(--text-color);
  word-break: break-word;
  line-height: 2;
}

.border-bottom {
  border-bottom: 1px solid var(--color-border) !important;
}

.button {
  border: 2px solid $color-primary !important;
  color: $color-primary;
  background-color: inherit;
  &:hover {
    background-color: $btn-bg-hover-color !important;
    border: 2px solid $btn-bg-hover-color !important;
    color: $text-white;
  }
  &:disabled {
    background-color: $color-primary;
    opacity: 0.3;
  }
}

.controller {
  justify-content: space-between;
  align-items: center;
}

.game-border-table {
  .text-label {
    font-size: 10px;
  }
  .text-content {
    font-size: 12px;
  }

  .button.game-border-btn-add {
    font-size: 10px !important;
  }
}

@media (max-width: 768px) {
  .table-setting {
    border: 1px solid var(--color-border);
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }

  .controller {
    flex-direction: column;
    align-items: flex-start;

    h5 {
      font-size: 13px;
      margin-bottom: 20px;
    }

    .button {
      margin: 10px 0;
    }
  }
}
</style>
