export const EN = { code: 'en-US', language: 'English' };
export const AR = { code: 'ar', language: 'Arabic' };
export const CA = { code: 'ca', language: 'Catalan' };
export const ZHCN = { code: 'zh-CN', language: 'Simplified Chinese' };
export const ZHTW = { code: 'zh-TW', language: 'Traditional Chinese' };
export const CS = { code: 'cs', language: 'Čeština' };
export const DA = { code: 'da', language: 'Dansk' };
export const DE = { code: 'de', language: 'Deutsch' };
export const NL = { code: 'nl', language: 'Nederlands' };
export const FIL = { code: 'fil', language: 'Filipino' };
export const FI = { code: 'fi', language: 'Suomalainen' };
export const FR = { code: 'fr', language: 'Français' };
export const EL = { code: 'el', language: 'Greek' };
export const HI = { code: 'hi', language: 'Hindi' };
export const HU = { code: 'hu', language: 'Magyar' };
export const ID = { code: 'id', language: 'Bahasa Indonesia' };
export const IT = { code: 'it', language: 'Italiano' };
export const JA = { code: 'ja', language: 'Japanese' };
export const KO = { code: 'ko', language: 'Korean' };
export const PTBR = { code: 'pt-BR', language: 'Português' };
export const RO = { code: 'ro', language: 'Romania' };
export const RU = { code: 'ru', language: 'Russian' };
export const SR = { code: 'sr', language: 'Serbian' };
export const ESES = { code: 'es-ES', language: 'Español' };
export const SVSE = { code: 'sv-SE', language: 'Svenska' };
export const TA = { code: 'ta', language: 'Tamil' };
export const TR = { code: 'tr', language: 'Turkish' };
export const UK = { code: 'uk', language: 'Ukrainian' };
export const VI = { code: 'vi', language: 'Vietnamese' };

export const allLanguages = [
  EN,
  // AR,
  // CA,
  ZHCN,
  // ZHTW,
  // CS,
  // DA,
  // DE,
  // NL,
  // FIL,
  // FI,
  // FR,
  // EL,
  // HI,
  // HU,
  // ID,
  // IT,
  JA,
  // KO,
  // PTBR,
  // RO,
  // RU,
  // SR,
  // ESES,
  // SVSE,
  // TA,
  // TR,
  // UK,
  VI
];
