import { render, staticRenderFns } from "./ModalTransaction.vue?vue&type=template&id=03ff4ef1&"
import script from "./ModalTransaction.vue?vue&type=script&lang=js&"
export * from "./ModalTransaction.vue?vue&type=script&lang=js&"
import style0 from "./ModalTransaction.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports